define('jason-frontend/components/animal-icon', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

  'use strict';

  exports['default'] = TooltipsterComponent['default'].extend({
    classNames: ['animal-icon-wrapper'],
    size: 32,
    side: 'bottom',
    timer: 0,
    eltigaActive: false,
    content: 'Spezies',
    theme: 'tooltipster-punk'

  });
  // tagName: '',

});