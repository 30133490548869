define('jason-frontend/templates/cash/add-article', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("style");
          var el2 = dom.createTextNode("\n    #breadcrumbs-bar {\n      visibility: hidden;\n      display: none !important;\n    }\n    #breadcrumbs-bar-inventory {\n      visibility: visible !important;\n      display: block !important;\n    }\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 12
                },
                "end": {
                  "line": 44,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-field-rework");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","select-placeholder");
              var el3 = dom.createTextNode("Firma");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[35,26],[35,35]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[36,24],[36,38]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",true],["loc",[null,[34,16],[42,18]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 12
                },
                "end": {
                  "line": 81,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-field-rework");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","select-placeholder");
              var el3 = dom.createTextNode("Kategorie");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[71,28],[71,38]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[72,26],[72,49]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",false,"placeholder","Alle Kategorien"],["loc",[null,[70,18],[79,20]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 6
              },
              "end": {
                "line": 84,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","div-horizontal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-lane grid gutter-fix");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Hersteller");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element19, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element19, [3, 1]),3,3);
            morphs[2] = dom.createMorphAt(dom.childAt(element19, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","suppliers",["loc",[null,[31,18],[31,27]]]]],[],0,null,["loc",[null,[31,12],[44,19]]]],
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterManufacturer",["loc",[null,[51,22],[51,40]]]]],[],[]],"optionLabelPath","name","placeholder","Alle","allowClear",true,"typeaheadSearchingText","Suche Hersteller","typeaheadNoMatchesText","Keine Hersteller gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"query","queryManufacturer","cssClass","custom-select2 newStyle tuned"],["loc",[null,[50,14],[62,16]]]],
            ["block","if",[["get","partnerCategories",["loc",[null,[67,18],[67,35]]]]],[],1,null,["loc",[null,[67,12],[81,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 4
            },
            "end": {
              "line": 85,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[26,12],[26,23]]]]],[],0,null,["loc",[null,[26,6],[84,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 6
              },
              "end": {
                "line": 119,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-box grid");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Kategorie");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-6/24@desk gutter");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-field-rework");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","select-placeholder");
            var el5 = dom.createTextNode("Quelle");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element18, [1, 1]),3,3);
            morphs[1] = dom.createMorphAt(dom.childAt(element18, [3, 1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[93,24],[93,41]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[94,22],[94,45]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",false,"placeholder","Alle Kategorien"],["loc",[null,[92,14],[101,16]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","distributors",["loc",[null,[108,24],[108,36]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDistributor",["loc",[null,[109,22],[109,39]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",false,"searchEnabled",false,"placeholder","Alle"],["loc",[null,[107,14],[115,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 86,
              "column": 4
            },
            "end": {
              "line": 120,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[87,12],[87,23]]]]],[],0,null,["loc",[null,[87,6],[119,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 135,
                "column": 14
              },
              "end": {
                "line": 136,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 136,
                "column": 16
              },
              "end": {
                "line": 138,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#all");
            var el2 = dom.createTextNode("Paket");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element14, 'class');
            morphs[1] = dom.createElementMorph(element14);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[137,91],[137,103]]]],"composite"],[],["loc",[null,[137,87],[137,116]]]],"is-active",""],[],["loc",[null,[137,66],[137,133]]]]]]],
            ["element","action",["setType","composite"],[],["loc",[null,[137,147],[137,179]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 140,
                "column": 14
              },
              "end": {
                "line": 140,
                "column": 86
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 141,
                  "column": 16
                },
                "end": {
                  "line": 142,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 142,
                  "column": 16
                },
                "end": {
                  "line": 146,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","#all");
              var el3 = dom.createTextNode("Vorlage");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element13, 'class');
              morphs[1] = dom.createElementMorph(element13);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[144,91],[144,103]]]],"text"],[],["loc",[null,[144,87],[144,111]]]],"is-active",""],[],["loc",[null,[144,66],[144,128]]]]]]],
              ["element","action",["setType","text"],[],["loc",[null,[144,142],[144,169]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 140,
                "column": 86
              },
              "end": {
                "line": 147,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["subexpr","eq",[["get","referer",["loc",[null,[141,31],[141,38]]]],"invoices"],[],["loc",[null,[141,27],[141,50]]]],["subexpr","eq",[["get","selectedPatient",["loc",[null,[141,55],[141,70]]]],"0"],[],["loc",[null,[141,51],[141,75]]]]],[],["loc",[null,[141,22],[141,76]]]]],[],0,1,["loc",[null,[141,16],[146,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 124,
              "column": 8
            },
            "end": {
              "line": 151,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","js-tabs tabs tabs--mobile-block mobile-horizontal tabs-4");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","tabs__head");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3,"class","u-mb0 clearfix");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"href","#all");
          var el6 = dom.createTextNode("Produkt");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"href","#all");
          var el6 = dom.createTextNode("Leistung");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1, 1, 1]);
          var element16 = dom.childAt(element15, [1, 1]);
          var element17 = dom.childAt(element15, [3, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element16, 'class');
          morphs[1] = dom.createElementMorph(element16);
          morphs[2] = dom.createAttrMorph(element17, 'class');
          morphs[3] = dom.createElementMorph(element17);
          morphs[4] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
          morphs[5] = dom.createMorphAt(element15,7,7);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[129,89],[129,101]]]],"product"],[],["loc",[null,[129,85],[129,112]]]],"is-active",""],[],["loc",[null,[129,64],[129,129]]]]]]],
          ["element","action",["setType","product"],[],["loc",[null,[129,143],[129,173]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title--big ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[132,89],[132,101]]]],"service"],[],["loc",[null,[132,85],[132,112]]]],"is-active",""],[],["loc",[null,[132,64],[132,129]]]]]]],
          ["element","action",["setType","service"],[],["loc",[null,[132,143],[132,173]]]],
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[135,28],[135,32]]]],"compositeProduct"],[],["loc",[null,[135,24],[135,52]]]],["subexpr","eq",[["get","type",["loc",[null,[135,57],[135,61]]]],"compositeService"],[],["loc",[null,[135,53],[135,81]]]],["subexpr","eq",[["get","type",["loc",[null,[135,86],[135,90]]]],"compositeTemplate"],[],["loc",[null,[135,82],[135,111]]]]],[],["loc",[null,[135,20],[135,112]]]]],[],0,1,["loc",[null,[135,14],[138,23]]]],
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[140,28],[140,32]]]],"futureAction"],[],["loc",[null,[140,24],[140,48]]]],["subexpr","eq",[["get","type",["loc",[null,[140,53],[140,57]]]],"futureActionBlueprints"],[],["loc",[null,[140,49],[140,83]]]]],[],["loc",[null,[140,20],[140,84]]]]],[],2,3,["loc",[null,[140,14],[147,21]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 178,
                  "column": 12
                },
                "end": {
                  "line": 182,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","selectable-item has-icon");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createAttrMorph(element12, 'for');
              morphs[2] = dom.createElementMorph(element12);
              morphs[3] = dom.createMorphAt(element12,0,0);
              return morphs;
            },
            statements: [
              ["inline","input",[],["type","checkbox","id",["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[179,56],[179,70]]]]],[],["loc",[null,[179,41],[179,71]]]],"checked",["subexpr","@mut",[["get","tag.selected",["loc",[null,[179,80],[179,92]]]]],[],[]]],["loc",[null,[179,14],[179,94]]]],
              ["attribute","for",["concat",[["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[180,75],[180,89]]]]],[],["loc",[null,[180,59],[180,91]]]]]]],
              ["element","action",["selectTag",["get","tag",["loc",[null,[181,102],[181,105]]]]],[],["loc",[null,[180,93],[181,107]]]],
              ["content","tag.name",["loc",[null,[181,108],[181,120]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 177,
                "column": 10
              },
              "end": {
                "line": 183,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","articleTags",["loc",[null,[178,20],[178,31]]]]],[],0,null,["loc",[null,[178,12],[182,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 173,
              "column": 6
            },
            "end": {
              "line": 185,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 l-14/24@desk");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["get","articleTags",["loc",[null,[177,16],[177,27]]]]],[],0,null,["loc",[null,[177,10],[183,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 190,
                "column": 8
              },
              "end": {
                "line": 198,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Tags");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-4/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Lager");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 199,
                "column": 8
              },
              "end": {
                "line": 201,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[200,10],[200,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 203,
                  "column": 10
                },
                "end": {
                  "line": 205,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-product",[],["newStyle",true,"inclusiveVpes",true,"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[204,85],[204,102]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[204,113],[204,128]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavs",["loc",[null,[204,145],[204,155]]]]],[],[]],"filterFavs",["subexpr","@mut",[["get","filterFavs",["loc",[null,[204,167],[204,177]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[204,186],[204,193]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[204,201],[204,207]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[204,214],[204,218]]]]],[],[]],"addProduct","addProduct","typeaddProduct","addProduct","toggleProductFavorite","toggleProductFavorite","togglePriceFavorite","togglePriceFavorite"],["loc",[null,[204,12],[204,361]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 201,
                "column": 8
              },
              "end": {
                "line": 207,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            dom.setAttribute(el1,"id","product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[203,18],[203,23]]]]],[],0,null,["loc",[null,[203,10],[205,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 188,
              "column": 2
            },
            "end": {
              "line": 209,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large table--with-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element11,1,1);
          morphs[1] = dom.createMorphAt(element11,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[190,36],[190,60]]]]],[],[]]],0,null,["loc",[null,[190,8],[198,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[199,14],[199,25]]]]],[],1,2,["loc",[null,[199,8],[207,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 218,
                  "column": 70
                },
                "end": {
                  "line": 219,
                  "column": 63
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","table__title title");
              var el2 = dom.createTextNode("VK brutto");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 212,
                "column": 8
              },
              "end": {
                "line": 223,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-7/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Tags");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Einheit");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right@desk l-3/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Menge");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 9]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[218,76],[218,89]]]]],[],0,null,["loc",[null,[218,70],[219,70]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 224,
                "column": 8
              },
              "end": {
                "line": 226,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[225,10],[225,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 228,
                  "column": 12
                },
                "end": {
                  "line": 230,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-service",[],["classNames",["subexpr","concat",["table__row"," ",["get","service.type",["loc",[null,[229,72],[229,84]]]]],[],["loc",[null,[229,47],[229,85]]]],"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[229,104],[229,121]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[229,132],[229,147]]]]],[],[]],"service",["subexpr","@mut",[["get","service",["loc",[null,[229,156],[229,163]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[229,171],[229,177]]]]],[],[]],"addService","addService","toggleServiceFavorite","toggleServiceFavorite"],["loc",[null,[229,14],[229,249]]]]
            ],
            locals: ["service"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 226,
                "column": 8
              },
              "end": {
                "line": 232,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[228,20],[228,25]]]]],[],0,null,["loc",[null,[228,12],[230,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 210,
              "column": 2
            },
            "end": {
              "line": 234,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element10,1,1);
          morphs[1] = dom.createMorphAt(element10,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[212,36],[212,60]]]]],[],[]]],0,null,["loc",[null,[212,8],[223,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[224,14],[224,25]]]]],[],1,2,["loc",[null,[224,8],[232,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 241,
                  "column": 63
                },
                "end": {
                  "line": 241,
                  "column": 138
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","table__title title");
              var el2 = dom.createTextNode("VK brutto");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 6
              },
              "end": {
                "line": 245,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-11/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Menge");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[241,69],[241,82]]]]],[],0,null,["loc",[null,[241,63],[241,145]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 246,
                "column": 8
              },
              "end": {
                "line": 248,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[247,10],[247,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 250,
                  "column": 12
                },
                "end": {
                  "line": 252,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-composite",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[251,56],[251,73]]]]],[],[]],"editable",["subexpr","@mut",[["get","editable",["loc",[null,[251,83],[251,91]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[251,102],[251,117]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavorites",["loc",[null,[251,134],[251,149]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[251,158],[251,165]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[251,173],[251,179]]]]],[],[]],"addProduct","addComposite","toggleCompositeFavorite","toggleCompositeFavorite"],["loc",[null,[251,14],[251,257]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 248,
                "column": 8
              },
              "end": {
                "line": 254,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[250,20],[250,25]]]]],[],0,null,["loc",[null,[250,12],[252,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 235,
              "column": 2
            },
            "end": {
              "line": 256,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large table--with-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element9,1,1);
          morphs[1] = dom.createMorphAt(element9,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[237,34],[237,58]]]]],[],[]]],0,null,["loc",[null,[237,6],[245,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[246,14],[246,25]]]]],[],1,2,["loc",[null,[246,8],[254,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 260,
                "column": 8
              },
              "end": {
                "line": 267,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-10/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Name");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-9/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Unterschrift");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 268,
                "column": 8
              },
              "end": {
                "line": 270,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[269,10],[269,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 272,
                  "column": 12
                },
                "end": {
                  "line": 274,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-text",[],["classNames",["subexpr","concat",["table__row"],[],["loc",[null,[273,44],[273,65]]]],"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[273,84],[273,101]]]]],[],[]],"editable",["subexpr","@mut",[["get","editable",["loc",[null,[273,111],[273,119]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[273,130],[273,145]]]]],[],[]],"text",["subexpr","@mut",[["get","text",["loc",[null,[273,151],[273,155]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[273,163],[273,169]]]]],[],[]],"addText","addText"],["loc",[null,[273,14],[273,189]]]]
            ],
            locals: ["text"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 270,
                "column": 8
              },
              "end": {
                "line": 276,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[272,20],[272,25]]]]],[],0,null,["loc",[null,[272,12],[274,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 257,
              "column": 2
            },
            "end": {
              "line": 278,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element8,1,1);
          morphs[1] = dom.createMorphAt(element8,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[260,36],[260,60]]]]],[],[]]],0,null,["loc",[null,[260,8],[267,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[268,14],[268,25]]]]],[],1,2,["loc",[null,[268,8],[276,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 287,
                "column": 6
              },
              "end": {
                "line": 289,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Paket"],["loc",[null,[288,8],[288,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 286,
              "column": 4
            },
            "end": {
              "line": 290,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["compositeproducts.edit",["get","typeId",["loc",[null,[287,42],[287,48]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[287,6],[289,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 292,
                "column": 6
              },
              "end": {
                "line": 294,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Lieferschein"],["loc",[null,[293,8],[293,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 291,
              "column": 4
            },
            "end": {
              "line": 295,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["inventory.delivery-note-details",["get","typeId",["loc",[null,[292,51],[292,57]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[292,6],[294,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 297,
                "column": 6
              },
              "end": {
                "line": 299,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zur Ausgangsrechnung"],["loc",[null,[298,8],[298,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 296,
              "column": 4
            },
            "end": {
              "line": 300,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["invoices.create",["get","typeId",["loc",[null,[297,35],[297,41]]]],["subexpr","query-params",[],["patientId",["get","selectedPatient",["loc",[null,[297,66],[297,81]]]]],["loc",[null,[297,42],[297,82]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[297,6],[299,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 302,
                "column": 6
              },
              "end": {
                "line": 304,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zur Behandlung"],["loc",[null,[303,8],[303,55]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 301,
              "column": 4
            },
            "end": {
              "line": 305,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["practicemgmt.treatment-details",["get","typeId",["loc",[null,[302,50],[302,56]]]],["subexpr","query-params",[],["scrollTo","positions"],["loc",[null,[302,57],[302,92]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[302,6],[304,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 308,
                  "column": 8
                },
                "end": {
                  "line": 310,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-prev",[],["content","Zurück"],["loc",[null,[309,10],[309,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 311,
                  "column": 8
                },
                "end": {
                  "line": 313,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-inventory2",[],["size",40,"color","#fff","content","Lagerbestand buchen"],["loc",[null,[312,10],[312,82]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 307,
                "column": 6
              },
              "end": {
                "line": 314,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["inventory.stocktaking-detail",["get","stocktakingId",["loc",[null,[308,50],[308,63]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[308,8],[310,20]]]],
            ["block","link-to",["inventory.bookin-request",["get","typeId",["loc",[null,[311,46],[311,52]]]],["subexpr","query-params",[],["stocktakingId",["get","stocktakingId",["loc",[null,[311,81],[311,94]]]]],["loc",[null,[311,53],[311,95]]]]],["class","icon-button icon-button--success"],1,null,["loc",[null,[311,8],[313,20]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 316,
                    "column": 10
                  },
                  "end": {
                    "line": 318,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-prev",[],["content","Zurück"],["loc",[null,[317,12],[317,44]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 315,
                  "column": 8
                },
                "end": {
                  "line": 319,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["inventory.corrections",["get","typeId",["loc",[null,[316,45],[316,51]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[316,10],[318,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 320,
                    "column": 10
                  },
                  "end": {
                    "line": 322,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","button-prev",["loc",[null,[321,12],[321,27]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 319,
                  "column": 8
                },
                "end": {
                  "line": 323,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["inventory.select-order"],["class","icon-button icon-button--small"],0,null,["loc",[null,[320,10],[322,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 314,
                "column": 6
              },
              "end": {
                "line": 324,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","subType",["loc",[null,[315,18],[315,25]]]],"inventoryCorrections"],[],["loc",[null,[315,14],[315,49]]]]],[],0,1,["loc",[null,[315,8],[323,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 4
            },
            "end": {
              "line": 325,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","stocktakingId",["loc",[null,[307,12],[307,25]]]]],[],0,1,["loc",[null,[307,6],[324,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 326,
              "column": 4
            },
            "end": {
              "line": 330,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[328,8],[328,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 331,
                  "column": 6
                },
                "end": {
                  "line": 333,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[332,8],[332,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 330,
                "column": 4
              },
              "end": {
                "line": 334,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["practicemgmt.treatment-future-action","treatment",["get","selectedPatient",["loc",[null,[331,68],[331,83]]]],["get","typeId",["loc",[null,[331,84],[331,90]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[331,6],[333,18]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 335,
                    "column": 6
                  },
                  "end": {
                    "line": 337,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-prev",[],["content","Zurück"],["loc",[null,[336,8],[336,40]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 334,
                  "column": 4
                },
                "end": {
                  "line": 338,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["practicemgmt.treatment-future-action","compositeproducts",["get","selectedPatient",["loc",[null,[335,76],[335,91]]]],["get","typeId",["loc",[null,[335,92],[335,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[335,6],[337,18]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 339,
                      "column": 6
                    },
                    "end": {
                      "line": 341,
                      "column": 6
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-prev",[],["content","Zurück"],["loc",[null,[340,8],[340,40]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 338,
                    "column": 4
                  },
                  "end": {
                    "line": 342,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-future-action","services",["get","selectedPatient",["loc",[null,[339,67],[339,82]]]],["get","typeId",["loc",[null,[339,83],[339,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[339,6],[341,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 343,
                        "column": 6
                      },
                      "end": {
                        "line": 345,
                        "column": 6
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-prev",[],["content","Zurück"],["loc",[null,[344,8],[344,40]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 342,
                      "column": 4
                    },
                    "end": {
                      "line": 346,
                      "column": 4
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["practicemgmt.treatment-future-action","products",["get","selectedPatient",["loc",[null,[343,67],[343,82]]]],["get","typeId",["loc",[null,[343,83],[343,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[343,6],[345,18]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 347,
                          "column": 6
                        },
                        "end": {
                          "line": 349,
                          "column": 6
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[348,8],[348,53]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 346,
                        "column": 4
                      },
                      "end": {
                        "line": 350,
                        "column": 4
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["practicemgmt.treatment-future-action","treatment",["get","selectedPatient",["loc",[null,[347,68],[347,83]]]],["get","typeId",["loc",[null,[347,84],[347,90]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[347,6],[349,18]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 351,
                            "column": 6
                          },
                          "end": {
                            "line": 353,
                            "column": 6
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[352,8],[352,53]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 350,
                          "column": 4
                        },
                        "end": {
                          "line": 354,
                          "column": 4
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["practicemgmt.treatment-future-action","compositeproducts",["get","selectedPatient",["loc",[null,[351,76],[351,91]]]],["get","typeId",["loc",[null,[351,92],[351,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[351,6],[353,18]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 355,
                              "column": 6
                            },
                            "end": {
                              "line": 357,
                              "column": 6
                            }
                          },
                          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("        ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[356,8],[356,53]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 354,
                            "column": 4
                          },
                          "end": {
                            "line": 358,
                            "column": 4
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","link-to",["practicemgmt.treatment-future-action","products",["get","selectedPatient",["loc",[null,[355,67],[355,82]]]],["get","typeId",["loc",[null,[355,83],[355,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[355,6],[357,18]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 359,
                                "column": 6
                              },
                              "end": {
                                "line": 361,
                                "column": 6
                              }
                            },
                            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("        ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                            return morphs;
                          },
                          statements: [
                            ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[360,8],[360,53]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 358,
                              "column": 4
                            },
                            "end": {
                              "line": 362,
                              "column": 4
                            }
                          },
                          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("    ");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          return morphs;
                        },
                        statements: [
                          ["block","link-to",["practicemgmt.treatment-future-action","services",["get","selectedPatient",["loc",[null,[359,67],[359,82]]]],["get","typeId",["loc",[null,[359,83],[359,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[359,6],[361,18]]]]
                        ],
                        locals: [],
                        templates: [child0]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 358,
                            "column": 4
                          },
                          "end": {
                            "line": 362,
                            "column": 4
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[358,18],[358,29]]]],"services"],[],["loc",[null,[358,14],[358,41]]]]],[],0,null,["loc",[null,[358,4],[362,4]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 354,
                          "column": 4
                        },
                        "end": {
                          "line": 362,
                          "column": 4
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[354,18],[354,29]]]],"products"],[],["loc",[null,[354,14],[354,41]]]]],[],0,1,["loc",[null,[354,4],[362,4]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 350,
                        "column": 4
                      },
                      "end": {
                        "line": 362,
                        "column": 4
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[350,18],[350,29]]]],"compositeproducts"],[],["loc",[null,[350,14],[350,50]]]]],[],0,1,["loc",[null,[350,4],[362,4]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 346,
                      "column": 4
                    },
                    "end": {
                      "line": 362,
                      "column": 4
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[346,18],[346,29]]]],"treatment"],[],["loc",[null,[346,14],[346,42]]]]],[],0,1,["loc",[null,[346,4],[362,4]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 342,
                    "column": 4
                  },
                  "end": {
                    "line": 362,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[342,18],[342,29]]]],"templateProduct"],[],["loc",[null,[342,14],[342,48]]]]],[],0,1,["loc",[null,[342,4],[362,4]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 338,
                  "column": 4
                },
                "end": {
                  "line": 362,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[338,18],[338,29]]]],"templateService"],[],["loc",[null,[338,14],[338,48]]]]],[],0,1,["loc",[null,[338,4],[362,4]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 334,
                "column": 4
              },
              "end": {
                "line": 362,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[334,18],[334,29]]]],"templateComposite"],[],["loc",[null,[334,14],[334,50]]]]],[],0,1,["loc",[null,[334,4],[362,4]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 330,
              "column": 4
            },
            "end": {
              "line": 362,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[330,18],[330,29]]]],"templateTreatment"],[],["loc",[null,[330,14],[330,50]]]]],[],0,1,["loc",[null,[330,4],[362,4]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 381,
                      "column": 18
                    },
                    "end": {
                      "line": 382,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","cursor is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element0);
                  morphs[1] = dom.createMorphAt(element0,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["toItem",["get","entry.article.id",["loc",[null,[381,90],[381,106]]]],["get","entry.articleType",["loc",[null,[381,107],[381,124]]]]],[],["loc",[null,[381,72],[381,126]]]],
                  ["content","entry.article.name",["loc",[null,[381,127],[381,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 382,
                      "column": 18
                    },
                    "end": {
                      "line": 384,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","entry.articleText",["loc",[null,[383,20],[383,41]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 386,
                      "column": 104
                    },
                    "end": {
                      "line": 386,
                      "column": 203
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" (");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(") ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.price",["loc",[null,[386,168],[386,179]]]]],[],[]],"classNames","inline"],["loc",[null,[386,144],[386,201]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 389,
                      "column": 22
                    },
                    "end": {
                      "line": 391,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.total",["loc",[null,[390,46],[390,57]]]]],[],[]]],["loc",[null,[390,22],[390,59]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 377,
                    "column": 12
                  },
                  "end": {
                    "line": 400,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","entry grid");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","name col l-24/24");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","name mb10");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createElement("b");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" x ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("b");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","price");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","delete");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","input input--action icon-button icon-button--delete list-action-square");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [1]);
                var element3 = dom.childAt(element2, [3]);
                var element4 = dom.childAt(element1, [5, 1]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
                morphs[3] = dom.createMorphAt(element3,3,3);
                morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
                morphs[5] = dom.createElementMorph(element4);
                morphs[6] = dom.createMorphAt(element4,1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.article",["loc",[null,[381,24],[381,37]]]]],[],0,1,["loc",[null,[381,18],[384,25]]]],
                ["content","entry.quantity",["loc",[null,[386,43],[386,61]]]],
                ["content","entry.unit.name",["loc",[null,[386,81],[386,100]]]],
                ["block","unless",[["subexpr","eq",[["get","entry.type",["loc",[null,[386,118],[386,128]]]],"template"],[],["loc",[null,[386,114],[386,140]]]]],[],2,null,["loc",[null,[386,104],[386,214]]]],
                ["block","unless",[["subexpr","eq",[["get","entry.type",["loc",[null,[389,36],[389,46]]]],"template"],[],["loc",[null,[389,32],[389,58]]]]],[],3,null,["loc",[null,[389,22],[391,33]]]],
                ["element","action",["deleteMinicartEntry",["get","entry.id",["loc",[null,[394,56],[394,64]]]],["get","treatment.id",["loc",[null,[394,65],[394,77]]]],["get","typeId",["loc",[null,[394,78],[394,84]]]]],[],["loc",[null,[394,25],[394,86]]]],
                ["inline","button-delete-list",[],["showStroke",false,"color","#ffffff","content",""],["loc",[null,[396,24],[396,90]]]]
              ],
              locals: ["entry"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 375,
                  "column": 10
                },
                "end": {
                  "line": 402,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","supplier-entries");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","treatment.entries",["loc",[null,[377,20],[377,37]]]]],[],0,null,["loc",[null,[377,12],[400,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 402,
                  "column": 10
                },
                "end": {
                  "line": 404,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","u-text--center mt20");
              var el2 = dom.createTextNode("Ihre Behandlung hat derzeit noch keine Positionen.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 374,
                "column": 8
              },
              "end": {
                "line": 405,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","list-not-empty",[["get","treatment.entries",["loc",[null,[375,32],[375,49]]]]],[],["loc",[null,[375,16],[375,50]]]]],[],0,1,["loc",[null,[375,10],[404,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 409,
                "column": 4
              },
              "end": {
                "line": 410,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 365,
              "column": 3
            },
            "end": {
              "line": 412,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          var el3 = dom.createTextNode("X");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Behandlung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","miniBasket");
          dom.setAttribute(el3,"class","treatment");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element5, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [7, 1]),1,1);
          morphs[3] = dom.createMorphAt(element5,9,9);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[367,44],[367,75]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[368,41],[368,49]]]]],[],["loc",[null,[368,23],[368,51]]]],
          ["block","if",[["get","treatment",["loc",[null,[374,14],[374,23]]]]],[],0,null,["loc",[null,[374,8],[405,15]]]],
          ["block","if",[["get","treatment",["loc",[null,[409,10],[409,19]]]]],[],1,null,["loc",[null,[409,4],[410,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 413,
                "column": 2
              },
              "end": {
                "line": 417,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[415,61],[415,196]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 412,
              "column": 0
            },
            "end": {
              "line": 418,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sidebarVisible",["loc",[null,[413,8],[413,22]]]]],[],0,null,["loc",[null,[413,2],[417,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 454,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Neue Eingangsbuchung");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","rework-content card site-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter box@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 l-8/24@desk");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterAll");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon favs");
        dom.setAttribute(el5,"for","filterFavs");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          Favoriten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" -\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterTiny");
        var el6 = dom.createTextNode("Kleintiere");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterHorse");
        var el6 = dom.createTextNode("Pferde");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterUse");
        var el6 = dom.createTextNode("Nutztiere");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $(document).off('click','.spinner__minus');\n        $(document).on('click','.spinner__minus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n\n            if(oldValue > 1) {\n                target.val(oldValue-1);\n            }\n        });\n        $(document).off('click','.spinner__plus');\n        $(document).on('click','.spinner__plus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n            target.val(oldValue+1);\n        });\n\n      $( window ).resize(function() {\n        $('aside.side-bar').css('min-height', $(window).height()-80);\n        $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n      });\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n    })\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [2]);
        var element21 = dom.childAt(element20, [3]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(fragment, [6, 1]);
        var element24 = dom.childAt(element23, [1]);
        var element25 = dom.childAt(element24, [1]);
        var element26 = dom.childAt(fragment, [8]);
        var morphs = new Array(30);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element20, 'class');
        morphs[2] = dom.createMorphAt(element22,1,1);
        morphs[3] = dom.createMorphAt(element22,3,3);
        morphs[4] = dom.createMorphAt(element21,3,3);
        morphs[5] = dom.createMorphAt(element21,4,4);
        morphs[6] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[7] = dom.createMorphAt(element25,3,3);
        morphs[8] = dom.createMorphAt(element25,7,7);
        morphs[9] = dom.createMorphAt(dom.childAt(element25, [9]),0,0);
        morphs[10] = dom.createMorphAt(element25,11,11);
        morphs[11] = dom.createMorphAt(element25,15,15);
        morphs[12] = dom.createMorphAt(element25,19,19);
        morphs[13] = dom.createMorphAt(element24,3,3);
        morphs[14] = dom.createMorphAt(element23,3,3);
        morphs[15] = dom.createMorphAt(element23,4,4);
        morphs[16] = dom.createMorphAt(element23,5,5);
        morphs[17] = dom.createMorphAt(element23,6,6);
        morphs[18] = dom.createMorphAt(dom.childAt(element23, [8]),1,1);
        morphs[19] = dom.createMorphAt(element26,1,1);
        morphs[20] = dom.createMorphAt(element26,2,2);
        morphs[21] = dom.createMorphAt(element26,3,3);
        morphs[22] = dom.createMorphAt(element26,4,4);
        morphs[23] = dom.createMorphAt(element26,5,5);
        morphs[24] = dom.createMorphAt(element26,6,6);
        morphs[25] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[26] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[27] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[28] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[29] = dom.createMorphAt(fragment,19,19,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[1,10],[1,14]]]],"inventoryRequests"],[],["loc",[null,[1,6],[1,35]]]]],[],0,null,["loc",[null,[1,0],[12,7]]]],
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[14,69],[14,80]]]],"with-filters",""],[],["loc",[null,[14,48],[14,100]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[22,30],[22,40]]]]],[],[]],"placeHolder",["subexpr","@mut",[["get","placeholder",["loc",[null,[22,53],[22,64]]]]],[],[]],"search","applyFilter"],["loc",[null,[22,6],[22,87]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[23,31],[23,42]]]]],[],[]]],["loc",[null,[23,6],[23,44]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[25,14],[25,26]]]],"product"],[],["loc",[null,[25,10],[25,37]]]]],[],1,null,["loc",[null,[25,4],[85,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[86,14],[86,26]]]],"service"],[],["loc",[null,[86,10],[86,37]]]]],[],2,null,["loc",[null,[86,4],[120,11]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[124,28],[124,32]]]],"templates"],[],["loc",[null,[124,24],[124,45]]]]],[],["loc",[null,[124,19],[124,46]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[124,56],[124,60]]]],"inventoryRequests"],[],["loc",[null,[124,52],[124,81]]]]],[],["loc",[null,[124,47],[124,82]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[124,92],[124,96]]]],"deliveryNote"],[],["loc",[null,[124,88],[124,112]]]]],[],["loc",[null,[124,83],[124,113]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[124,123],[124,127]]]],"compositeTemplate"],[],["loc",[null,[124,119],[124,148]]]]],[],["loc",[null,[124,114],[124,149]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[124,159],[124,163]]]],"compositeProduct"],[],["loc",[null,[124,155],[124,183]]]]],[],["loc",[null,[124,150],[124,184]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[124,194],[124,198]]]],"compositeService"],[],["loc",[null,[124,190],[124,218]]]]],[],["loc",[null,[124,185],[124,219]]]]],[],["loc",[null,[124,14],[124,220]]]]],[],3,null,["loc",[null,[124,8],[151,13]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[158,72],[158,81]]]]],[],[]]],["loc",[null,[158,8],[158,83]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[160,74],[160,84]]]]],[],[]]],["loc",[null,[160,8],[160,86]]]],
        ["inline","button-favorite",[],["content","","size",18,"showStroke",false,"color","#999999","classNames",""],["loc",[null,[161,70],[163,103]]]],
        ["inline","input",[],["type","checkbox","id","filterTiny","name","filterTiny","checked",["subexpr","@mut",[["get","filterTiny",["loc",[null,[165,74],[165,84]]]]],[],[]]],["loc",[null,[165,8],[165,86]]]],
        ["inline","input",[],["type","checkbox","id","filterHorse","name","filterHorse","checked",["subexpr","@mut",[["get","filterHorse",["loc",[null,[167,76],[167,87]]]]],[],[]]],["loc",[null,[167,8],[167,89]]]],
        ["inline","input",[],["type","checkbox","id","filterUse","name","filterUse","checked",["subexpr","@mut",[["get","filterUse",["loc",[null,[169,72],[169,81]]]]],[],[]]],["loc",[null,[169,8],[169,83]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectedType",["loc",[null,[173,20],[173,32]]]],"product"],[],["loc",[null,[173,16],[173,43]]]],["subexpr","eq",[["get","selectedType",["loc",[null,[173,48],[173,60]]]],"service"],[],["loc",[null,[173,44],[173,71]]]]],[],["loc",[null,[173,12],[173,72]]]]],[],4,null,["loc",[null,[173,6],[185,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[188,12],[188,24]]]],"product"],[],["loc",[null,[188,8],[188,35]]]]],[],5,null,["loc",[null,[188,2],[209,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[210,12],[210,24]]]],"service"],[],["loc",[null,[210,8],[210,35]]]]],[],6,null,["loc",[null,[210,2],[234,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[235,12],[235,24]]]],"composite"],[],["loc",[null,[235,8],[235,37]]]]],[],7,null,["loc",[null,[235,2],[256,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[257,12],[257,24]]]],"text"],[],["loc",[null,[257,8],[257,32]]]]],[],8,null,["loc",[null,[257,2],[278,9]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[280,29],[280,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[280,6],[280,55]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[286,18],[286,22]]]],"compositeProduct"],[],["loc",[null,[286,14],[286,42]]]],["subexpr","eq",[["get","type",["loc",[null,[286,47],[286,51]]]],"compositeService"],[],["loc",[null,[286,43],[286,71]]]],["subexpr","eq",[["get","type",["loc",[null,[286,76],[286,80]]]],"compositeTemplate"],[],["loc",[null,[286,72],[286,101]]]]],[],["loc",[null,[286,10],[286,102]]]]],[],9,null,["loc",[null,[286,4],[290,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[291,14],[291,18]]]],"deliveryNote"],[],["loc",[null,[291,10],[291,34]]]]],[],10,null,["loc",[null,[291,4],[295,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[296,14],[296,18]]]],"invoices"],[],["loc",[null,[296,10],[296,30]]]]],[],11,null,["loc",[null,[296,4],[300,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[301,14],[301,18]]]],"treatmentOffers"],[],["loc",[null,[301,10],[301,37]]]]],[],12,null,["loc",[null,[301,4],[305,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[306,14],[306,18]]]],"inventoryRequests"],[],["loc",[null,[306,10],[306,39]]]]],[],13,null,["loc",[null,[306,4],[325,11]]]],
        ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[326,14],[326,25]]]],"futureActionBlueprints"],[],["loc",[null,[326,10],[326,51]]]]],[],14,15,["loc",[null,[326,4],[362,11]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[365,9],[365,22]]]]],[],16,17,["loc",[null,[365,3],[418,7]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[420,23],[420,28]]]]],[],[]],"create","connectTask","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[420,97],[420,109]]]]],[],[]]],["loc",[null,[420,0],[420,111]]]],
        ["inline","add-reminder-panel",[],["create","createReminder","actionReceiver",["subexpr","@mut",[["get","addReminderPanel",["loc",[null,[421,60],[421,76]]]]],[],[]]],["loc",[null,[421,0],[421,78]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[422,31],[422,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[422,46],[422,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[422,63],[422,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[422,136],[422,156]]]]],[],[]]],["loc",[null,[422,0],[422,158]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[423,30],[423,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[423,44],[423,51]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[423,62],[423,71]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[423,83],[423,93]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[423,100],[423,105]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[423,115],[423,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[423,166],[423,185]]]]],[],[]]],["loc",[null,[423,0],[423,187]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
    };
  }()));

});