define('jason-frontend/templates/resourcemgmt/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 2
            },
            "end": {
              "line": 76,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head sticker");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createTextNode("Mitarbeiter:in");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element19, [3]),0,0);
          morphs[1] = dom.createUnsafeMorphAt(dom.childAt(element19, [5]),0,0);
          morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element19, [7]),0,0);
          morphs[3] = dom.createUnsafeMorphAt(dom.childAt(element19, [9]),0,0);
          morphs[4] = dom.createUnsafeMorphAt(dom.childAt(element19, [11]),0,0);
          morphs[5] = dom.createUnsafeMorphAt(dom.childAt(element19, [13]),0,0);
          morphs[6] = dom.createUnsafeMorphAt(dom.childAt(element19, [15]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.dates.0",["loc",[null,[68,62],[68,83]]]],
          ["content","model.dates.1",["loc",[null,[69,62],[69,83]]]],
          ["content","model.dates.2",["loc",[null,[70,62],[70,83]]]],
          ["content","model.dates.3",["loc",[null,[71,62],[71,83]]]],
          ["content","model.dates.4",["loc",[null,[72,62],[72,83]]]],
          ["content","model.dates.5",["loc",[null,[73,62],[73,83]]]],
          ["content","model.dates.6",["loc",[null,[74,63],[74,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 8
            },
            "end": {
              "line": 84,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 text-center mb10 mt10");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[82,12],[82,33]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 14
                },
                "end": {
                  "line": 94,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","tooltipstered symbol symbol-circle symbol-40px");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'style');
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","userRow.user.shortName",["loc",[null,[90,30],[90,52]]]]]]],
              ["attribute","style",["concat",["background-color: ",["get","userRow.user.color",["loc",[null,[90,138],[90,156]]]]," !important;"]]],
              ["content","userRow.user.capitals",["loc",[null,[91,44],[91,69]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 14
                },
                "end": {
                  "line": 99,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'title');
              morphs[1] = dom.createAttrMorph(element0, 'class');
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","userRow.user.shortName",["loc",[null,[95,30],[95,52]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","userRow.user.selected",["loc",[null,[95,98],[95,119]]]],"selected",""],[],["loc",[null,[95,77],[95,135]]]]," symbol symbol-circle symbol-40px ",["get","userRow.user.color",["loc",[null,[95,171],[95,189]]]]]]],
              ["content","userRow.user.capitals",["loc",[null,[96,44],[96,69]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 102,
                  "column": 12
                },
                "end": {
                  "line": 102,
                  "column": 98
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","block");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","userRow.user.userRole.name",["loc",[null,[102,61],[102,91]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 45
                  },
                  "end": {
                    "line": 108,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[108,68],[108,80]]]],
                ["content","day.to",["loc",[null,[108,83],[108,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 12
                },
                "end": {
                  "line": 108,
                  "column": 113
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[108,55],[108,66]]]]],[],0,null,["loc",[null,[108,45],[108,113]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 46
                  },
                  "end": {
                    "line": 114,
                    "column": 103
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[114,69],[114,81]]]],
                ["content","day.to",["loc",[null,[114,84],[114,94]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 12
                },
                "end": {
                  "line": 114,
                  "column": 114
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[114,56],[114,67]]]]],[],0,null,["loc",[null,[114,46],[114,114]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 48
                  },
                  "end": {
                    "line": 120,
                    "column": 105
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[120,71],[120,83]]]],
                ["content","day.to",["loc",[null,[120,86],[120,96]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 120,
                  "column": 12
                },
                "end": {
                  "line": 120,
                  "column": 116
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[120,58],[120,69]]]]],[],0,null,["loc",[null,[120,48],[120,116]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 126,
                    "column": 47
                  },
                  "end": {
                    "line": 126,
                    "column": 104
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[126,70],[126,82]]]],
                ["content","day.to",["loc",[null,[126,85],[126,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 126,
                  "column": 12
                },
                "end": {
                  "line": 126,
                  "column": 115
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[126,57],[126,68]]]]],[],0,null,["loc",[null,[126,47],[126,115]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 132,
                    "column": 45
                  },
                  "end": {
                    "line": 132,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[132,68],[132,80]]]],
                ["content","day.to",["loc",[null,[132,83],[132,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 132,
                  "column": 12
                },
                "end": {
                  "line": 132,
                  "column": 113
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[132,55],[132,66]]]]],[],0,null,["loc",[null,[132,45],[132,113]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 138,
                    "column": 47
                  },
                  "end": {
                    "line": 138,
                    "column": 104
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[138,70],[138,82]]]],
                ["content","day.to",["loc",[null,[138,85],[138,95]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 12
                },
                "end": {
                  "line": 138,
                  "column": 115
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[138,57],[138,68]]]]],[],0,null,["loc",[null,[138,47],[138,115]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        var child9 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 144,
                    "column": 45
                  },
                  "end": {
                    "line": 144,
                    "column": 102
                  }
                },
                "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" - ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Uhr");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","day.from",["loc",[null,[144,68],[144,80]]]],
                ["content","day.to",["loc",[null,[144,83],[144,93]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 144,
                  "column": 12
                },
                "end": {
                  "line": 144,
                  "column": 113
                }
              },
              "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","unless",[["get","day.deleted",["loc",[null,[144,55],[144,66]]]]],[],0,null,["loc",[null,[144,45],[144,113]]]]
            ],
            locals: ["day"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 8
              },
              "end": {
                "line": 152,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            dom.setAttribute(el2,"data-label","Mitarbeiter:in");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","symbol-group symbol-hover block mb0");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Montag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Dienstag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Mittwoch");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Donnerstag");
            var el3 = dom.createTextNode("\n             ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class"," block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Freitag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Samstag");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24 table__cell--center table__cell--action");
            dom.setAttribute(el2,"data-label","Sonntag");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell--right table__cell l-2/24 table__cell--action");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"class","block icon-button icon-button--very-small icon-button--default icon-button--success list-action-square exp");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/copy-white.svg");
            dom.setAttribute(el4,"title","Wochenplanung kopieren");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var element5 = dom.childAt(element4, [1]);
            var element6 = dom.childAt(element2, [5]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element2, [7]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element2, [9]);
            var element11 = dom.childAt(element10, [1]);
            var element12 = dom.childAt(element2, [11]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element2, [13]);
            var element15 = dom.childAt(element14, [1]);
            var element16 = dom.childAt(element2, [15]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element2, [17, 1]);
            var morphs = new Array(27);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
            morphs[2] = dom.createMorphAt(element3,3,3);
            morphs[3] = dom.createMorphAt(element3,5,5);
            morphs[4] = dom.createMorphAt(element3,7,7);
            morphs[5] = dom.createElementMorph(element5);
            morphs[6] = dom.createMorphAt(element5,1,1);
            morphs[7] = dom.createMorphAt(element4,3,3);
            morphs[8] = dom.createElementMorph(element7);
            morphs[9] = dom.createMorphAt(element7,1,1);
            morphs[10] = dom.createMorphAt(element6,3,3);
            morphs[11] = dom.createElementMorph(element9);
            morphs[12] = dom.createMorphAt(element9,1,1);
            morphs[13] = dom.createMorphAt(element8,3,3);
            morphs[14] = dom.createElementMorph(element11);
            morphs[15] = dom.createMorphAt(element11,1,1);
            morphs[16] = dom.createMorphAt(element10,3,3);
            morphs[17] = dom.createElementMorph(element13);
            morphs[18] = dom.createMorphAt(element13,1,1);
            morphs[19] = dom.createMorphAt(element12,3,3);
            morphs[20] = dom.createElementMorph(element15);
            morphs[21] = dom.createMorphAt(element15,1,1);
            morphs[22] = dom.createMorphAt(element14,3,3);
            morphs[23] = dom.createElementMorph(element17);
            morphs[24] = dom.createMorphAt(element17,1,1);
            morphs[25] = dom.createMorphAt(element16,3,3);
            morphs[26] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","userRow.user.userRole",["loc",[null,[86,52],[86,73]]]],["get","userRow.user.userRole.id",["loc",[null,[86,74],[86,98]]]],""],[],["loc",[null,[86,31],[86,103]]]]]]],
            ["block","if",[["get","userRow.user.colorHex",["loc",[null,[89,20],[89,41]]]]],[],0,1,["loc",[null,[89,14],[99,21]]]],
            ["content","userRow.user.firstname",["loc",[null,[101,12],[101,38]]]],
            ["content","userRow.user.lastname",["loc",[null,[101,39],[101,64]]]],
            ["block","if",[["get","userRow.user.userRole",["loc",[null,[102,18],[102,39]]]]],[],2,null,["loc",[null,[102,12],[102,105]]]],
            ["element","action",["openEdit",["get","userRow.monday",["loc",[null,[105,134],[105,148]]]]],[],["loc",[null,[105,114],[105,150]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[106,14],[106,80]]]],
            ["block","each",[["get","userRow.monday",["loc",[null,[108,20],[108,34]]]]],[],3,null,["loc",[null,[108,12],[108,122]]]],
            ["element","action",["openEdit",["get","userRow.tuesday",["loc",[null,[111,134],[111,149]]]]],[],["loc",[null,[111,114],[111,151]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[112,14],[112,80]]]],
            ["block","each",[["get","userRow.tuesday",["loc",[null,[114,20],[114,35]]]]],[],4,null,["loc",[null,[114,12],[114,123]]]],
            ["element","action",["openEdit",["get","userRow.wednesday",["loc",[null,[117,134],[117,151]]]]],[],["loc",[null,[117,114],[117,153]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[118,14],[118,80]]]],
            ["block","each",[["get","userRow.wednesday",["loc",[null,[120,20],[120,37]]]]],[],5,null,["loc",[null,[120,12],[120,125]]]],
            ["element","action",["openEdit",["get","userRow.thursday",["loc",[null,[123,136],[123,152]]]]],[],["loc",[null,[123,116],[123,154]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[124,14],[124,80]]]],
            ["block","each",[["get","userRow.thursday",["loc",[null,[126,20],[126,36]]]]],[],6,null,["loc",[null,[126,12],[126,124]]]],
            ["element","action",["openEdit",["get","userRow.friday",["loc",[null,[129,134],[129,148]]]]],[],["loc",[null,[129,114],[129,150]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[130,14],[130,80]]]],
            ["block","each",[["get","userRow.friday",["loc",[null,[132,20],[132,34]]]]],[],7,null,["loc",[null,[132,12],[132,122]]]],
            ["element","action",["openEdit",["get","userRow.saturday",["loc",[null,[135,136],[135,152]]]]],[],["loc",[null,[135,116],[135,154]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[136,14],[136,80]]]],
            ["block","each",[["get","userRow.saturday",["loc",[null,[138,20],[138,36]]]]],[],8,null,["loc",[null,[138,12],[138,124]]]],
            ["element","action",["openEdit",["get","userRow.sunday",["loc",[null,[141,134],[141,148]]]]],[],["loc",[null,[141,114],[141,150]]]],
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","30","content",""],["loc",[null,[142,14],[142,80]]]],
            ["block","each",[["get","userRow.sunday",["loc",[null,[144,20],[144,34]]]]],[],9,null,["loc",[null,[144,12],[144,122]]]],
            ["element","action",["openCopyPanel",["get","userRow.user",["loc",[null,[147,45],[147,57]]]]],[],["loc",[null,[147,20],[147,60]]]]
          ],
          locals: ["userRow"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 8
            },
            "end": {
              "line": 153,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.users",["loc",[null,[85,16],[85,27]]]]],[],0,null,["loc",[null,[85,8],[152,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 206,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/resourcemgmt/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","d-flex align-items-center ");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","mr20");
        var el5 = dom.createElement("b");
        var el6 = dom.createTextNode("Dienstplan für KW");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","u-mb0 field select mr10 hidden");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","fake-dropdown mr15 cursor");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","u-mb0 field select mr10");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","u-mb0 field select mr10");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/copy-white.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Wochenplanung auf andere KW übertragen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","rework-content site-content card with-context-bar");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box u-show@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","u-mb0");
        var el5 = dom.createTextNode("Ressourcenplanung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table box no-box@phone grid table--large");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $('#miniCalendarTrigger').daterangepicker({\n      \"singleDatePicker\": true,\n      \"showISOWeekNumbers\": true,\n      \"locale\": {\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      // \"autoApply\": true,\n    });\n    $('#miniCalendarTrigger').on('apply.daterangepicker', function(ev, picker) {\n      picker.show();\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [0]);
        var element21 = dom.childAt(element20, [1]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element22, [4]);
        var element24 = dom.childAt(element21, [3, 1]);
        var element25 = dom.childAt(fragment, [2, 3, 1]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element20, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element22, [2]),1,1);
        morphs[2] = dom.createElementMorph(element23);
        morphs[3] = dom.createMorphAt(element23,1,1);
        morphs[4] = dom.createMorphAt(element22,6,6);
        morphs[5] = dom.createMorphAt(dom.childAt(element22, [8]),1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element22, [10]),1,1);
        morphs[7] = dom.createElementMorph(element24);
        morphs[8] = dom.createMorphAt(dom.childAt(element25, [1]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element25, [3]),1,1);
        morphs[10] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","weeks",["loc",[null,[6,18],[6,23]]]]],[],[]],"value",["subexpr","@mut",[["get","weekNumber",["loc",[null,[7,16],[7,26]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown"],["loc",[null,[5,8],[13,10]]]],
        ["element","action",["openMiniCalendar"],[],["loc",[null,[16,13],[16,42]]]],
        ["content","weekNumber",["loc",[null,[17,8],[17,22]]]],
        ["inline","input",[],["type","text","value",["subexpr","@mut",[["get","switchToDate",["loc",[null,[20,32],[20,44]]]]],[],[]],"id","miniCalendarTrigger"],["loc",[null,[20,6],[20,71]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","years",["loc",[null,[24,18],[24,23]]]]],[],[]],"value",["subexpr","@mut",[["get","year",["loc",[null,[25,16],[25,20]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",false,"cssClass","input--dropdown"],["loc",[null,[23,8],[31,10]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","userRoles",["loc",[null,[35,18],[35,27]]]]],[],[]],"value",["subexpr","@mut",[["get","role",["loc",[null,[36,16],[36,20]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","searchEnabled",false,"allowClear",true,"placeholder","Alle Rollen","cssClass","input--dropdown"],["loc",[null,[34,8],[43,10]]]],
        ["element","action",["openCopyPanel"],[],["loc",[null,[47,9],[47,36]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[65,30],[65,54]]]]],[],[]]],0,null,["loc",[null,[65,2],[76,23]]]],
        ["block","if",[["get","loading",["loc",[null,[80,14],[80,21]]]]],[],1,2,["loc",[null,[80,8],[153,15]]]],
        ["inline","edit-resource-day-panel",[],["actionReceiver",["subexpr","@mut",[["get","editResourceDayPanel",["loc",[null,[159,41],[159,61]]]]],[],[]],"save","save"],["loc",[null,[159,0],[159,75]]]],
        ["inline","copy-resource-panel",[],["actionReceiver",["subexpr","@mut",[["get","copyResourcePanel",["loc",[null,[160,37],[160,54]]]]],[],[]]],["loc",[null,[160,0],[160,56]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});