define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 28,
                      "column": 12
                    },
                    "end": {
                      "line": 33,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element53 = dom.childAt(fragment, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element53, 'title');
                  morphs[1] = dom.createAttrMorph(element53, 'class');
                  morphs[2] = dom.createAttrMorph(element53, 'style');
                  morphs[3] = dom.createElementMorph(element53);
                  morphs[4] = dom.createMorphAt(dom.childAt(element53, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[29,28],[29,42]]]]]]],
                  ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[29,121],[29,134]]]],"selected",""],[],["loc",[null,[29,100],[29,150]]]]," symbol symbol-circle symbol-40px"]]],
                  ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[29,212],[29,222]]]]," !important;"]]],
                  ["element","action",["toggleResource",["get","user",["loc",[null,[29,72],[29,76]]]]],[],["loc",[null,[29,46],[29,78]]]],
                  ["content","user.capitals",["loc",[null,[30,42],[30,59]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 35,
                        "column": 16
                      },
                      "end": {
                        "line": 39,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","symbol-label");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 39,
                        "column": 16
                      },
                      "end": {
                        "line": 42,
                        "column": 16
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","symbol-label");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","user.capitals",["loc",[null,[40,44],[40,61]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 12
                    },
                    "end": {
                      "line": 44,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element52 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element52, 'title');
                  morphs[1] = dom.createAttrMorph(element52, 'class');
                  morphs[2] = dom.createElementMorph(element52);
                  morphs[3] = dom.createMorphAt(element52,1,1);
                  return morphs;
                },
                statements: [
                  ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[34,28],[34,42]]]]]]],
                  ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[34,121],[34,134]]]],"selected",""],[],["loc",[null,[34,100],[34,150]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[34,186],[34,196]]]]]]],
                  ["element","action",["toggleResource",["get","user",["loc",[null,[34,72],[34,76]]]]],[],["loc",[null,[34,46],[34,78]]]],
                  ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[35,26],[35,33]]]],"9998"],[],["loc",[null,[35,22],[35,41]]]]],[],0,1,["loc",[null,[35,16],[42,23]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 12
                  },
                  "end": {
                    "line": 45,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","user.colorHex",["loc",[null,[28,18],[28,31]]]]],[],0,1,["loc",[null,[28,12],[44,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 10
                },
                "end": {
                  "line": 46,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","user.active",["loc",[null,[27,18],[27,29]]]]],[],0,null,["loc",[null,[27,12],[45,19]]]]
            ],
            locals: ["user"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 4
              },
              "end": {
                "line": 49,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","quick-filter d-flex align-items-center flex-grow-1 justify-content-end");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","me-20");
            var el3 = dom.createTextNode("Mitarbeiter:innen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","symbol-group symbol-hover flex-shrink-0 me-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","users",["loc",[null,[26,18],[26,23]]]]],[],0,null,["loc",[null,[26,10],[46,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 24
                  },
                  "end": {
                    "line": 72,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[72,64],[72,91]]]],
                ["content","item.customer.lastname",["loc",[null,[72,92],[72,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 22
                    },
                    "end": {
                      "line": 80,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[76,56],[76,87]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[77,51],[77,82]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[78,46],[78,82]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[78,88],[78,112]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[79,46],[79,79]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[76,24],[79,110]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 22
                    },
                    "end": {
                      "line": 81,
                      "column": 149
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[81,92],[81,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[81,125],[81,147]]]]],[],[]]],["loc",[null,[81,73],[81,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 86,
                          "column": 28
                        },
                        "end": {
                          "line": 91,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element40 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element40, 'title');
                      morphs[1] = dom.createAttrMorph(element40, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[87,44],[87,72]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[87,162],[87,186]]]]," !important;"]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[88,60],[88,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 91,
                          "column": 28
                        },
                        "end": {
                          "line": 96,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element39 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element39, 'title');
                      morphs[1] = dom.createAttrMorph(element39, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[92,44],[92,72]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[92,122],[92,149]]]],"selected",""],[],["loc",[null,[92,101],[92,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[92,201],[92,225]]]]]]],
                      ["content","patientInfo.doctor.capitals",["loc",[null,[93,60],[93,91]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 85,
                        "column": 26
                      },
                      "end": {
                        "line": 97,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[86,34],[86,61]]]]],[],0,1,["loc",[null,[86,28],[96,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 99,
                          "column": 28
                        },
                        "end": {
                          "line": 104,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element38 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element38, 'title');
                      morphs[1] = dom.createAttrMorph(element38, 'style');
                      morphs[2] = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[100,44],[100,75]]]]]]],
                      ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[100,165],[100,192]]]]," !important;"]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[101,62],[101,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 104,
                          "column": 28
                        },
                        "end": {
                          "line": 109,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      var el2 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-label");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element37 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element37, 'title');
                      morphs[1] = dom.createAttrMorph(element37, 'class');
                      morphs[2] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[105,44],[105,75]]]]]]],
                      ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[105,125],[105,155]]]],"selected",""],[],["loc",[null,[105,104],[105,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[105,207],[105,234]]]]]]],
                      ["content","patientInfo.assistant.capitals",["loc",[null,[106,62],[106,96]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 98,
                        "column": 26
                      },
                      "end": {
                        "line": 110,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[99,34],[99,64]]]]],[],0,1,["loc",[null,[99,28],[109,35]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 83,
                      "column": 24
                    },
                    "end": {
                      "line": 111,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.doctor",["loc",[null,[85,32],[85,50]]]]],[],0,null,["loc",[null,[85,26],[97,33]]]],
                  ["block","if",[["get","patientInfo.assistant",["loc",[null,[98,32],[98,53]]]]],[],1,null,["loc",[null,[98,26],[110,33]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 18
                  },
                  "end": {
                    "line": 118,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","flex-center mb5 mt5");
                dom.setAttribute(el1,"style","flex-direction: row");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-group");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3,"src","assets/images/icons/rework/admission.svg");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element41 = dom.childAt(fragment, [1]);
                var element42 = dom.childAt(element41, [7]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element41,1,1);
                morphs[1] = dom.createMorphAt(element41,3,3);
                morphs[2] = dom.createMorphAt(dom.childAt(element41, [5]),1,1);
                morphs[3] = dom.createElementMorph(element42);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[75,28],[75,56]]]]],[],0,null,["loc",[null,[75,22],[80,29]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[81,49],[81,71]]]]],[],1,null,["loc",[null,[81,22],[81,161]]]],
                ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[83,34],[83,55]]]],["get","patientInfo.doctor",["loc",[null,[83,56],[83,74]]]]],[],["loc",[null,[83,30],[83,75]]]]],[],2,null,["loc",[null,[83,24],[111,31]]]],
                ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[114,133],[114,149]]]],["get","patientInfo.patient.id",["loc",[null,[114,150],[114,172]]]],["get","item.appointmentId",["loc",[null,[114,173],[114,191]]]]],[],["loc",[null,[114,105],[114,193]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 12
                },
                "end": {
                  "line": 121,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24");
              dom.setAttribute(el2,"data-label","Termin");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element43 = dom.childAt(fragment, [1]);
              var element44 = dom.childAt(element43, [1]);
              var element45 = dom.childAt(element43, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element44, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element44, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element45, [1]),0,0);
              morphs[3] = dom.createMorphAt(element45,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[67,97],[67,109]]]],"DD. MM. YYYY"],[],["loc",[null,[67,83],[67,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[68,50],[68,62]]]],"HH:mm"],[],["loc",[null,[68,36],[68,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[71,52],[71,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[71,24],[72,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[73,26],[73,48]]]]],[],1,null,["loc",[null,[73,18],[118,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 144,
                      "column": 22
                    },
                    "end": {
                      "line": 151,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element33 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element33, 'id');
                  morphs[1] = dom.createAttrMorph(element33, 'value');
                  morphs[2] = dom.createAttrMorph(element33, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[148,108],[148,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[148,143],[148,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[148,233],[148,237]]]]],[],["loc",[null,[148,213],[148,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 154,
                      "column": 28
                    },
                    "end": {
                      "line": 155,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[155,64],[155,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[155,92],[155,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 160,
                            "column": 28
                          },
                          "end": {
                            "line": 165,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                              ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[161,62],[161,93]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[162,57],[162,88]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[163,52],[163,88]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[163,94],[163,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[164,52],[164,85]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[161,30],[164,116]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 166,
                            "column": 28
                          },
                          "end": {
                            "line": 167,
                            "column": 92
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[167,35],[167,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[167,68],[167,90]]]]],[],[]]],["loc",[null,[166,79],[167,92]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 172,
                                "column": 28
                              },
                              "end": {
                                "line": 177,
                                "column": 28
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                              ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element30 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element30, 'title');
                            morphs[1] = dom.createAttrMorph(element30, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[173,44],[173,72]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[173,162],[173,186]]]]," !important;"]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[174,60],[174,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 177,
                                "column": 28
                              },
                              "end": {
                                "line": 182,
                                "column": 28
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                              ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element29 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element29, 'title');
                            morphs[1] = dom.createAttrMorph(element29, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[178,44],[178,72]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[178,122],[178,149]]]],"selected",""],[],["loc",[null,[178,101],[178,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[178,201],[178,225]]]]]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[179,60],[179,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 171,
                              "column": 26
                            },
                            "end": {
                              "line": 183,
                              "column": 26
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[172,34],[172,61]]]]],[],0,1,["loc",[null,[172,28],[182,35]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 185,
                                "column": 34
                              },
                              "end": {
                                "line": 190,
                                "column": 34
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                    ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element28 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element28, 'title');
                            morphs[1] = dom.createAttrMorph(element28, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[186,50],[186,81]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[186,171],[186,198]]]]," !important;"]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[187,62],[187,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 190,
                                "column": 34
                              },
                              "end": {
                                "line": 195,
                                "column": 34
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                    ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element27 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element27, 'title');
                            morphs[1] = dom.createAttrMorph(element27, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[191,50],[191,81]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[191,131],[191,161]]]],"selected",""],[],["loc",[null,[191,110],[191,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[191,213],[191,240]]]]]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[192,62],[192,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 184,
                              "column": 32
                            },
                            "end": {
                              "line": 196,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[185,40],[185,70]]]]],[],0,1,["loc",[null,[185,34],[195,41]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 169,
                            "column": 28
                          },
                          "end": {
                            "line": 197,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor",["loc",[null,[171,32],[171,50]]]]],[],0,null,["loc",[null,[171,26],[183,33]]]],
                        ["block","if",[["get","patientInfo.assistant",["loc",[null,[184,38],[184,59]]]]],[],1,null,["loc",[null,[184,32],[196,39]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 158,
                          "column": 24
                        },
                        "end": {
                          "line": 204,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-group");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                          ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n                            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                      var el3 = dom.createTextNode("\n                              ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("img");
                      dom.setAttribute(el3,"class","tooltipstered");
                      dom.setAttribute(el3,"title","Behandeln");
                      dom.setAttribute(el3,"src","assets/images/icons/rework/treatment.svg");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                            ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element31 = dom.childAt(fragment, [1]);
                      var element32 = dom.childAt(element31, [7]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createMorphAt(element31,1,1);
                      morphs[1] = dom.createMorphAt(element31,3,3);
                      morphs[2] = dom.createMorphAt(dom.childAt(element31, [5]),1,1);
                      morphs[3] = dom.createElementMorph(element32);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.patient.category",["loc",[null,[160,34],[160,62]]]]],[],0,null,["loc",[null,[160,28],[165,35]]]],
                      ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[166,55],[166,77]]]]],[],1,null,["loc",[null,[166,28],[167,104]]]],
                      ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[169,38],[169,59]]]],["get","patientInfo.doctor",["loc",[null,[169,60],[169,78]]]]],[],["loc",[null,[169,34],[169,79]]]]],[],2,null,["loc",[null,[169,28],[197,35]]]],
                      ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[200,149],[200,163]]]]],[],["loc",[null,[200,111],[200,165]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 157,
                        "column": 24
                      },
                      "end": {
                        "line": 205,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[158,59],[158,70]]]],["get","selectedUsers",["loc",[null,[158,71],[158,84]]]],["get","changeTrigger",["loc",[null,[158,85],[158,98]]]]],[],["loc",[null,[158,30],[158,99]]]]],[],0,null,["loc",[null,[158,24],[204,31]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 156,
                      "column": 22
                    },
                    "end": {
                      "line": 206,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[157,34],[157,54]]]],"in_waitingroom"],[],["loc",[null,[157,30],[157,72]]]]],[],0,null,["loc",[null,[157,24],[205,31]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 139,
                    "column": 14
                  },
                  "end": {
                    "line": 210,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell  l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element34 = dom.childAt(fragment, [1]);
                var element35 = dom.childAt(element34, [1]);
                var element36 = dom.childAt(element34, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element35, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element35, [3]),0,0);
                morphs[2] = dom.createMorphAt(element35,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                morphs[4] = dom.createMorphAt(element36,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[142,101],[142,113]]]],"DD. MM. YYYY"],[],["loc",[null,[142,87],[142,130]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[143,54],[143,66]]]],"HH:mm"],[],["loc",[null,[143,40],[143,76]]]],
                ["block","if",[["get","item.time",["loc",[null,[144,28],[144,37]]]]],[],0,null,["loc",[null,[144,22],[151,29]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[154,56],[154,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[154,28],[155,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[156,30],[156,52]]]]],[],2,null,["loc",[null,[156,22],[206,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 12
                },
                "end": {
                  "line": 211,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[139,41],[139,45]]]],["get","selectedUsers",["loc",[null,[139,46],[139,59]]]],["get","changeTrigger",["loc",[null,[139,60],[139,73]]]]],[],["loc",[null,[139,20],[139,74]]]]],[],0,null,["loc",[null,[139,14],[210,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 234,
                      "column": 18
                    },
                    "end": {
                      "line": 241,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","sub block mt5");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","sub");
                  dom.setAttribute(el2,"style","display: inline");
                  var el3 = dom.createTextNode("Termin: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("label");
                  dom.setAttribute(el2,"for","time");
                  dom.setAttribute(el2,"class","input-element");
                  var el3 = dom.createTextNode("\n                             ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("input");
                  dom.setAttribute(el3,"style","font-size: 14px");
                  dom.setAttribute(el3,"autocomplete","false");
                  dom.setAttribute(el3,"type","text");
                  dom.setAttribute(el3,"placeholder","");
                  dom.setAttribute(el3,"class","input date-picker__input time time-small");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element23 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element23, 'id');
                  morphs[1] = dom.createAttrMorph(element23, 'value');
                  morphs[2] = dom.createAttrMorph(element23, 'onclick');
                  return morphs;
                },
                statements: [
                  ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[238,108],[238,115]]]]]]],
                  ["attribute","value",["concat",[["get","item.time",["loc",[null,[238,143],[238,152]]]]]]],
                  ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[238,233],[238,237]]]]],[],["loc",[null,[238,213],[238,239]]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 244,
                      "column": 24
                    },
                    "end": {
                      "line": 245,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[245,64],[245,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[245,92],[245,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 250,
                            "column": 28
                          },
                          "end": {
                            "line": 255,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                              ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[251,62],[251,93]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[252,57],[252,88]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[253,52],[253,88]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[253,94],[253,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[254,52],[254,85]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[251,30],[254,116]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 256,
                            "column": 24
                          },
                          "end": {
                            "line": 256,
                            "column": 151
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[256,94],[256,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[256,127],[256,149]]]]],[],[]]],["loc",[null,[256,75],[256,151]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 261,
                                "column": 30
                              },
                              "end": {
                                "line": 266,
                                "column": 44
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element21 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element21, 'title');
                            morphs[1] = dom.createAttrMorph(element21, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[262,46],[262,74]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[262,164],[262,188]]]]," !important;"]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[263,60],[263,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 266,
                                "column": 44
                              },
                              "end": {
                                "line": 271,
                                "column": 30
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                              ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element20 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element20, 'title');
                            morphs[1] = dom.createAttrMorph(element20, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[267,60],[267,88]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[267,138],[267,165]]]],"selected",""],[],["loc",[null,[267,117],[267,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[267,217],[267,241]]]]]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[268,60],[268,91]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 260,
                              "column": 26
                            },
                            "end": {
                              "line": 272,
                              "column": 26
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[261,36],[261,63]]]]],[],0,1,["loc",[null,[261,30],[271,37]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 274,
                                "column": 30
                              },
                              "end": {
                                "line": 279,
                                "column": 30
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element19 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element19, 'title');
                            morphs[1] = dom.createAttrMorph(element19, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[275,46],[275,77]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[275,167],[275,194]]]]," !important;"]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[276,62],[276,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 279,
                                "column": 30
                              },
                              "end": {
                                "line": 284,
                                "column": 30
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                  ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element18 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element18, 'title');
                            morphs[1] = dom.createAttrMorph(element18, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[280,46],[280,77]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[280,127],[280,157]]]],"selected",""],[],["loc",[null,[280,106],[280,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[280,209],[280,236]]]]]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[281,62],[281,96]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 273,
                              "column": 28
                            },
                            "end": {
                              "line": 285,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[274,36],[274,66]]]]],[],0,1,["loc",[null,[274,30],[284,37]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 259,
                            "column": 24
                          },
                          "end": {
                            "line": 286,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor",["loc",[null,[260,32],[260,50]]]]],[],0,null,["loc",[null,[260,26],[272,33]]]],
                        ["block","if",[["get","patientInfo.assistant",["loc",[null,[273,34],[273,55]]]]],[],1,null,["loc",[null,[273,28],[285,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child3 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 289,
                            "column": 24
                          },
                          "end": {
                            "line": 291,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("img");
                        dom.setAttribute(el1,"class","tooltipstered");
                        dom.setAttribute(el1,"title","Behandlungsdetails");
                        dom.setAttribute(el1,"src","assets/images/icons/rework/treatment.svg");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 248,
                          "column": 22
                        },
                        "end": {
                          "line": 293,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                        ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-group");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                          ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element22 = dom.childAt(fragment, [1]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createMorphAt(element22,1,1);
                      morphs[1] = dom.createMorphAt(element22,3,3);
                      morphs[2] = dom.createMorphAt(dom.childAt(element22, [5]),1,1);
                      morphs[3] = dom.createMorphAt(element22,7,7);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.patient.category",["loc",[null,[250,34],[250,62]]]]],[],0,null,["loc",[null,[250,28],[255,35]]]],
                      ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[256,51],[256,73]]]]],[],1,null,["loc",[null,[256,24],[256,163]]]],
                      ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[259,34],[259,55]]]],["get","patientInfo.doctor",["loc",[null,[259,56],[259,74]]]]],[],["loc",[null,[259,30],[259,75]]]]],[],2,null,["loc",[null,[259,24],[286,31]]]],
                      ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[289,68],[289,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[289,83],[289,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],3,null,["loc",[null,[289,24],[291,36]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 247,
                        "column": 22
                      },
                      "end": {
                        "line": 294,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[248,57],[248,68]]]],["get","selectedUsers",["loc",[null,[248,69],[248,82]]]],["get","changeTrigger",["loc",[null,[248,83],[248,96]]]]],[],["loc",[null,[248,28],[248,97]]]]],[],0,null,["loc",[null,[248,22],[293,29]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 246,
                      "column": 20
                    },
                    "end": {
                      "line": 295,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[247,32],[247,52]]]],"in_treatment"],[],["loc",[null,[247,28],[247,68]]]]],[],0,null,["loc",[null,[247,22],[294,29]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 229,
                    "column": 14
                  },
                  "end": {
                    "line": 298,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element24 = dom.childAt(fragment, [1]);
                var element25 = dom.childAt(element24, [1]);
                var element26 = dom.childAt(element24, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
                morphs[2] = dom.createMorphAt(element25,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
                morphs[4] = dom.createMorphAt(element26,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[232,97],[232,109]]]],"DD. MM. YYYY"],[],["loc",[null,[232,83],[232,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[233,50],[233,62]]]],"HH:mm"],[],["loc",[null,[233,36],[233,72]]]],
                ["block","if",[["get","item.time",["loc",[null,[234,24],[234,33]]]]],[],0,null,["loc",[null,[234,18],[241,25]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[244,52],[244,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[244,24],[245,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[246,28],[246,50]]]]],[],2,null,["loc",[null,[246,20],[295,29]]]]
              ],
              locals: [],
              templates: [child0, child1, child2]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 228,
                  "column": 12
                },
                "end": {
                  "line": 299,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[229,41],[229,45]]]],["get","selectedUsers",["loc",[null,[229,46],[229,59]]]],["get","changeTrigger",["loc",[null,[229,60],[229,73]]]]],[],["loc",[null,[229,20],[229,74]]]]],[],0,null,["loc",[null,[229,14],[298,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 322,
                      "column": 24
                    },
                    "end": {
                      "line": 323,
                      "column": 118
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.customer.firstname",["loc",[null,[323,64],[323,91]]]],
                  ["content","item.customer.lastname",["loc",[null,[323,92],[323,118]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 329,
                            "column": 22
                          },
                          "end": {
                            "line": 334,
                            "column": 22
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[330,56],[330,87]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[331,51],[331,82]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[332,46],[332,82]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[332,88],[332,112]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[333,46],[333,79]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[330,24],[333,110]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 336,
                            "column": 0
                          },
                          "end": {
                            "line": 336,
                            "column": 127
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[336,70],[336,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[336,103],[336,125]]]]],[],[]]],["loc",[null,[336,51],[336,127]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child2 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 337,
                            "column": 24
                          },
                          "end": {
                            "line": 339,
                            "column": 24
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1,"class","block small");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                        return morphs;
                      },
                      statements: [
                        ["content","patientInfo.room.name",["loc",[null,[338,52],[338,77]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child3 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 341,
                            "column": 22
                          },
                          "end": {
                            "line": 341,
                            "column": 190
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[341,61],[341,190]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child4 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 346,
                                "column": 32
                              },
                              "end": {
                                "line": 351,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element11 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element11, 'title');
                            morphs[1] = dom.createAttrMorph(element11, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[347,48],[347,76]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[347,166],[347,190]]]]," !important;"]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[348,64],[348,95]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 351,
                                "column": 32
                              },
                              "end": {
                                "line": 356,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                    ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element10 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element10, 'title');
                            morphs[1] = dom.createAttrMorph(element10, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[352,48],[352,76]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[352,126],[352,153]]]],"selected",""],[],["loc",[null,[352,105],[352,169]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[352,205],[352,229]]]]]]],
                            ["content","patientInfo.doctor.capitals",["loc",[null,[353,64],[353,95]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 345,
                              "column": 30
                            },
                            "end": {
                              "line": 357,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[346,38],[346,65]]]]],[],0,1,["loc",[null,[346,32],[356,39]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    var child1 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 359,
                                "column": 32
                              },
                              "end": {
                                "line": 364,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                            var el2 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element9 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element9, 'title');
                            morphs[1] = dom.createAttrMorph(element9, 'style');
                            morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[360,48],[360,79]]]]]]],
                            ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[360,169],[360,196]]]]," !important;"]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[361,66],[361,100]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      var child1 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 364,
                                "column": 32
                              },
                              "end": {
                                "line": 369,
                                "column": 32
                              }
                            },
                            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                  ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("div");
                            var el2 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2,"class","symbol-label");
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                                        ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                                      ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element8 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element8, 'title');
                            morphs[1] = dom.createAttrMorph(element8, 'class');
                            morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
                            return morphs;
                          },
                          statements: [
                            ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[365,48],[365,79]]]]]]],
                            ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[365,129],[365,159]]]],"selected",""],[],["loc",[null,[365,108],[365,175]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[365,211],[365,238]]]]]]],
                            ["content","patientInfo.assistant.capitals",["loc",[null,[366,66],[366,100]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 358,
                              "column": 30
                            },
                            "end": {
                              "line": 370,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [
                          ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[359,38],[359,68]]]]],[],0,1,["loc",[null,[359,32],[369,39]]]]
                        ],
                        locals: [],
                        templates: [child0, child1]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 343,
                            "column": 22
                          },
                          "end": {
                            "line": 371,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor",["loc",[null,[345,36],[345,54]]]]],[],0,null,["loc",[null,[345,30],[357,37]]]],
                        ["block","if",[["get","patientInfo.assistant",["loc",[null,[358,36],[358,57]]]]],[],1,null,["loc",[null,[358,30],[370,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 326,
                          "column": 20
                        },
                        "end": {
                          "line": 377,
                          "column": 20
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","flex-center mb5 mt5");
                      dom.setAttribute(el1,"style","flex-direction: row");
                      var el2 = dom.createTextNode("\n\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                      ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2,"class","symbol-group");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                        ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                      var el3 = dom.createTextNode("\n                        ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("img");
                      dom.setAttribute(el3,"class","tooltipstered");
                      dom.setAttribute(el3,"title","Behandeln");
                      dom.setAttribute(el3,"src","assets/images/icons/rework/treatment.svg");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                        ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element12 = dom.childAt(fragment, [1]);
                      var element13 = dom.childAt(element12, [3]);
                      var element14 = dom.childAt(element12, [9]);
                      var morphs = new Array(6);
                      morphs[0] = dom.createMorphAt(element12,1,1);
                      morphs[1] = dom.createMorphAt(element13,1,1);
                      morphs[2] = dom.createMorphAt(element13,3,3);
                      morphs[3] = dom.createMorphAt(element12,5,5);
                      morphs[4] = dom.createMorphAt(dom.childAt(element12, [7]),1,1);
                      morphs[5] = dom.createElementMorph(element14);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.patient.category",["loc",[null,[329,28],[329,56]]]]],[],0,null,["loc",[null,[329,22],[334,29]]]],
                      ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[336,27],[336,49]]]]],[],1,null,["loc",[null,[336,0],[336,139]]]],
                      ["block","if",[["get","patientInfo.room",["loc",[null,[337,30],[337,46]]]]],[],2,null,["loc",[null,[337,24],[339,31]]]],
                      ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[341,28],[341,59]]]]],[],3,null,["loc",[null,[341,22],[341,197]]]],
                      ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[343,32],[343,53]]]],["get","patientInfo.doctor",["loc",[null,[343,54],[343,72]]]]],[],["loc",[null,[343,28],[343,73]]]]],[],4,null,["loc",[null,[343,22],[371,33]]]],
                      ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[373,131],[373,145]]]]],[],["loc",[null,[373,93],[373,147]]]]
                    ],
                    locals: [],
                    templates: [child0, child1, child2, child3, child4]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 325,
                        "column": 20
                      },
                      "end": {
                        "line": 378,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[326,55],[326,66]]]],["get","selectedUsers",["loc",[null,[326,67],[326,80]]]],["get","changeTrigger",["loc",[null,[326,81],[326,94]]]]],[],["loc",[null,[326,26],[326,95]]]]],[],0,null,["loc",[null,[326,20],[377,27]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 324,
                      "column": 18
                    },
                    "end": {
                      "line": 379,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[325,30],[325,50]]]],"in_station"],[],["loc",[null,[325,26],[325,64]]]]],[],0,null,["loc",[null,[325,20],[378,27]]]]
                ],
                locals: ["patientInfo"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 315,
                    "column": 14
                  },
                  "end": {
                    "line": 382,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Check-In");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" Uhr");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-16/24");
                dom.setAttribute(el2,"data-label","Kund:in");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var element16 = dom.childAt(element15, [1]);
                var element17 = dom.childAt(element15, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
                morphs[1] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
                morphs[3] = dom.createMorphAt(element17,3,3);
                return morphs;
              },
              statements: [
                ["inline","time-format",[["get","item.created",["loc",[null,[318,97],[318,109]]]],"DD. MM. YYYY"],[],["loc",[null,[318,83],[318,126]]]],
                ["inline","time-format",[["get","item.created",["loc",[null,[319,50],[319,62]]]],"HH:mm"],[],["loc",[null,[319,36],[319,72]]]],
                ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[322,52],[322,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[322,24],[323,130]]]],
                ["block","each",[["get","item.treatmentPatients",["loc",[null,[324,26],[324,48]]]]],[],1,null,["loc",[null,[324,18],[379,27]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 314,
                  "column": 12
                },
                "end": {
                  "line": 383,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[315,41],[315,45]]]],["get","selectedUsers",["loc",[null,[315,46],[315,59]]]],["get","changeTrigger",["loc",[null,[315,60],[315,73]]]]],[],["loc",[null,[315,20],[315,74]]]]],[],0,null,["loc",[null,[315,14],[382,21]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 408,
                    "column": 24
                  },
                  "end": {
                    "line": 409,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[409,64],[409,91]]]],
                ["content","item.customer.lastname",["loc",[null,[409,92],[409,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 414,
                        "column": 28
                      },
                      "end": {
                        "line": 418,
                        "column": 89
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1,"class","animal-icon");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[415,85],[415,116]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[416,75],[416,111]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[417,75],[417,108]]]]],[],[]],"classNames","mr5"],["loc",[null,[415,53],[418,85]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 419,
                        "column": 26
                      },
                      "end": {
                        "line": 420,
                        "column": 123
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[419,96],[419,120]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[420,99],[420,121]]]]],[],[]]],["loc",[null,[419,77],[420,123]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 421,
                        "column": 26
                      },
                      "end": {
                        "line": 424,
                        "column": 61
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[421,65],[424,61]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 411,
                      "column": 20
                    },
                    "end": {
                      "line": 427,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","flex-center mb5 mt5");
                  dom.setAttribute(el1,"style","flex-direction: row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","flex-center");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element4,1,1);
                  morphs[1] = dom.createMorphAt(element4,3,3);
                  morphs[2] = dom.createMorphAt(element4,5,5);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.patient.category",["loc",[null,[414,34],[414,62]]]]],[],0,null,["loc",[null,[414,28],[418,96]]]],
                  ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[419,53],[419,75]]]]],[],1,null,["loc",[null,[419,26],[420,135]]]],
                  ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[421,32],[421,63]]]]],[],2,null,["loc",[null,[421,26],[424,68]]]]
                ],
                locals: [],
                templates: [child0, child1, child2]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 410,
                    "column": 18
                  },
                  "end": {
                    "line": 428,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[411,30],[411,50]]]],"ready_for_payment"],[],["loc",[null,[411,26],[411,71]]]]],[],0,null,["loc",[null,[411,20],[427,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 435,
                        "column": 24
                      },
                      "end": {
                        "line": 440,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 mb5 ml0 ");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[437,28],[438,91]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 449,
                          "column": 26
                        },
                        "end": {
                          "line": 452,
                          "column": 26
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-in-treatment",[],["size","32","color","#fff","showStroke",false,"content","Behandlung öffnen"],["loc",[null,[451,28],[451,119]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 440,
                        "column": 24
                      },
                      "end": {
                        "line": 453,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 mb5 ml5 ");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element3);
                    morphs[1] = dom.createMorphAt(element3,1,1);
                    morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[444,28],[444,42]]]],["get","patientInfo.info.invoiceId",["loc",[null,[445,28],[445,54]]]]],[],["loc",[null,[442,112],[445,56]]]],
                    ["inline","button-ready4payment",[],["size","32","color","#fff","showStroke",false,"content","Übergabe an Ausgangsrechnung"],["loc",[null,[446,28],[447,91]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[449,70],[449,84]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[449,85],[449,112]]]]],["class","mr0 icon-button icon-button--default list-action-square"],0,null,["loc",[null,[449,26],[452,38]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 432,
                      "column": 20
                    },
                    "end": {
                      "line": 456,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","flex-center mb5 mt5");
                  dom.setAttribute(el1,"style","flex-direction: row");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","flex-center");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                      ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","inProgress",["loc",[null,[435,30],[435,40]]]]],[],0,1,["loc",[null,[435,24],[453,31]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 431,
                    "column": 18
                  },
                  "end": {
                    "line": 457,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[432,30],[432,50]]]],"ready_for_payment"],[],["loc",[null,[432,26],[432,71]]]]],[],0,null,["loc",[null,[432,20],[456,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 400,
                  "column": 12
                },
                "end": {
                  "line": 460,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
              morphs[3] = dom.createMorphAt(element7,3,3);
              morphs[4] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[403,97],[403,109]]]],"DD. MM. YYYY"],[],["loc",[null,[403,83],[404,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[405,50],[405,62]]]],"HH:mm"],[],["loc",[null,[405,36],[405,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[408,52],[408,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[408,24],[409,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[410,26],[410,48]]]]],[],1,null,["loc",[null,[410,18],[428,27]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[431,26],[431,48]]]]],[],2,null,["loc",[null,[431,18],[457,27]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 0
              },
              "end": {
                "line": 465,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","header d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
            dom.setAttribute(el4,"class","ml5");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Demnächst");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Termin");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","header d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
            dom.setAttribute(el4,"class","ml5");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Wartezimmer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table grid table--small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","header d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
            dom.setAttribute(el4,"class","ml5");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("In Behandlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            dom.setAttribute(el2,"id","stationsCard");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","header d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
            dom.setAttribute(el4,"class","ml5");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Auf Station");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","card treatment-card");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","header d-flex align-items-center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
            dom.setAttribute(el4,"class","ml5");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h3");
            dom.setAttribute(el4,"class","mb0 ml10");
            var el5 = dom.createTextNode("Bezahlung");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table table--small grid");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__head");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-8/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Check-In");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","table__cell l-16/24");
            var el6 = dom.createElement("span");
            dom.setAttribute(el6,"class","table__title title very-small");
            var el7 = dom.createTextNode("Kund:in");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__body no-scrollbar");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element46 = dom.childAt(fragment, [1, 1]);
            var element47 = dom.childAt(fragment, [3, 1]);
            var element48 = dom.childAt(fragment, [5]);
            var element49 = dom.childAt(element48, [1]);
            var element50 = dom.childAt(element48, [3]);
            var element51 = dom.childAt(fragment, [7, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element46, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element46, [3, 3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element47, [1, 1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element47, [3, 3]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element49, [1, 1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element49, [3, 3]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element50, [1, 1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element50, [3, 3]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element51, [1, 1]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element51, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","get-size",[["get","upcomingItems",["loc",[null,[55,91],[55,104]]]]],[],["loc",[null,[55,80],[55,106]]]],
            ["block","each",[["get","upcomingItems",["loc",[null,[64,20],[64,33]]]]],[],0,null,["loc",[null,[64,12],[121,21]]]],
            ["inline","get-size",[["get","waitingroomItems",["loc",[null,[129,91],[129,107]]]]],[],["loc",[null,[129,80],[129,109]]]],
            ["block","each",[["get","waitingroomItems",["loc",[null,[138,20],[138,36]]]]],[],1,null,["loc",[null,[138,12],[211,21]]]],
            ["inline","get-size",[["get","treatmentItems",["loc",[null,[219,91],[219,105]]]]],[],["loc",[null,[219,80],[219,107]]]],
            ["block","each",[["get","treatmentItems",["loc",[null,[228,20],[228,34]]]]],[],2,null,["loc",[null,[228,12],[299,21]]]],
            ["inline","get-size",[["get","stationItems",["loc",[null,[305,91],[305,103]]]]],[],["loc",[null,[305,80],[305,105]]]],
            ["block","each",[["get","stationItems",["loc",[null,[314,20],[314,32]]]]],[],3,null,["loc",[null,[314,12],[383,21]]]],
            ["inline","get-size",[["get","paymentItems",["loc",[null,[391,91],[391,103]]]]],[],["loc",[null,[391,80],[391,105]]]],
            ["block","each",[["get","paymentItems",["loc",[null,[400,20],[400,32]]]]],[],4,null,["loc",[null,[400,12],[460,21]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 467,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","tiles grid");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element54,1,1);
          morphs[1] = dom.createMorphAt(element54,3,3);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","mainView",["loc",[null,[21,14],[21,22]]]],"tiles"],[],["loc",[null,[21,10],[21,31]]]]],[],0,null,["loc",[null,[21,4],[49,11]]]],
          ["block","if",[["get","users",["loc",[null,[51,6],[51,11]]]]],[],1,null,["loc",[null,[51,0],[465,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 485,
                  "column": 12
                },
                "end": {
                  "line": 493,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element2, 'title');
              morphs[1] = dom.createAttrMorph(element2, 'class');
              morphs[2] = dom.createAttrMorph(element2, 'style');
              morphs[3] = dom.createElementMorph(element2);
              morphs[4] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[486,28],[486,42]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[487,61],[487,74]]]],"selected",""],[],["loc",[null,[487,40],[488,65]]]]," symbol symbol-circle symbol-40px"]]],
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[489,46],[489,56]]]]," !important;"]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[486,72],[486,76]]]]],[],["loc",[null,[486,46],[486,78]]]],
              ["content","user.capitals",["loc",[null,[490,42],[490,59]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 497,
                    "column": 16
                  },
                  "end": {
                    "line": 501,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 501,
                    "column": 16
                  },
                  "end": {
                    "line": 504,
                    "column": 16
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","user.capitals",["loc",[null,[502,44],[502,61]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 493,
                  "column": 12
                },
                "end": {
                  "line": 506,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createElementMorph(element1);
              morphs[3] = dom.createMorphAt(element1,1,1);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[494,28],[494,42]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[495,61],[495,74]]]],"selected",""],[],["loc",[null,[495,40],[496,65]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[496,101],[496,111]]]]]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[494,72],[494,76]]]]],[],["loc",[null,[494,46],[494,78]]]],
              ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[497,26],[497,33]]]],"9998"],[],["loc",[null,[497,22],[497,41]]]]],[],0,1,["loc",[null,[497,16],[504,23]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 484,
                "column": 10
              },
              "end": {
                "line": 507,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[485,18],[485,31]]]]],[],0,1,["loc",[null,[485,12],[506,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 483,
              "column": 8
            },
            "end": {
              "line": 508,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.active",["loc",[null,[484,16],[484,27]]]]],[],0,null,["loc",[null,[484,10],[507,17]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 516,
                "column": 8
              },
              "end": {
                "line": 518,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[517,63],[517,79]]]],"selected",""],[],["loc",[null,[517,42],[517,95]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[517,122],[517,129]]]]],[],["loc",[null,[517,97],[517,131]]]],
            ["content","station.name",["loc",[null,[517,132],[517,148]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 513,
              "column": 4
            },
            "end": {
              "line": 520,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[516,16],[516,24]]]]],[],0,null,["loc",[null,[516,8],[518,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 562,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar reduced");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Praxisübersicht");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","tab-container btn-group");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"type","button");
        var el4 = dom.createTextNode("Kombinierte Ansicht");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"type","button");
        var el4 = dom.createTextNode(" Nur Kacheln");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"type","button");
        var el4 = dom.createTextNode("Nur Liste");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Filter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Behandlung");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Station");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter d-flex align-items-center flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","me-20");
        var el5 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element55 = dom.childAt(fragment, [0]);
        var element56 = dom.childAt(element55, [1, 3, 1]);
        var element57 = dom.childAt(element55, [3]);
        var element58 = dom.childAt(element57, [1]);
        var element59 = dom.childAt(element57, [3]);
        var element60 = dom.childAt(element57, [5]);
        var element61 = dom.childAt(fragment, [2]);
        var element62 = dom.childAt(element61, [3]);
        var element63 = dom.childAt(element62, [1]);
        var element64 = dom.childAt(element63, [3]);
        var element65 = dom.childAt(element63, [5]);
        var element66 = dom.childAt(element63, [7]);
        var element67 = dom.childAt(element63, [9]);
        var element68 = dom.childAt(element63, [11]);
        var morphs = new Array(26);
        morphs[0] = dom.createElementMorph(element56);
        morphs[1] = dom.createAttrMorph(element58, 'class');
        morphs[2] = dom.createElementMorph(element58);
        morphs[3] = dom.createAttrMorph(element59, 'class');
        morphs[4] = dom.createElementMorph(element59);
        morphs[5] = dom.createAttrMorph(element60, 'class');
        morphs[6] = dom.createElementMorph(element60);
        morphs[7] = dom.createAttrMorph(element61, 'class');
        morphs[8] = dom.createMorphAt(element61,1,1);
        morphs[9] = dom.createAttrMorph(element64, 'class');
        morphs[10] = dom.createElementMorph(element64);
        morphs[11] = dom.createAttrMorph(element65, 'class');
        morphs[12] = dom.createElementMorph(element65);
        morphs[13] = dom.createAttrMorph(element66, 'class');
        morphs[14] = dom.createElementMorph(element66);
        morphs[15] = dom.createAttrMorph(element67, 'class');
        morphs[16] = dom.createElementMorph(element67);
        morphs[17] = dom.createAttrMorph(element68, 'class');
        morphs[18] = dom.createElementMorph(element68);
        morphs[19] = dom.createMorphAt(dom.childAt(element62, [3, 3]),1,1);
        morphs[20] = dom.createMorphAt(element61,5,5);
        morphs[21] = dom.createMorphAt(element61,7,7);
        morphs[22] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[23] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[24] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[25] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[5,9],[5,49]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[12,104],[12,112]]]],"splitted"],[],["loc",[null,[12,100],[12,124]]]],"selected",""],[],["loc",[null,[12,79],[12,140]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[12,12],[12,46]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[13,101],[13,109]]]],"tiles"],[],["loc",[null,[13,97],[13,118]]]],"selected",""],[],["loc",[null,[13,76],[13,134]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[13,12],[13,43]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[14,100],[14,108]]]],"list"],[],["loc",[null,[14,96],[14,116]]]],"selected",""],[],["loc",[null,[14,75],[14,132]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[14,12],[14,42]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[18,37],[18,45]]]]]]],
        ["block","if",[["get","showTopTiles",["loc",[null,[19,6],[19,18]]]]],[],0,null,["loc",[null,[19,0],[467,9]]]],
        ["attribute","class",["concat",["cursor selectable-item  ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[473,65],[473,72]]]],"selected",""],[],["loc",[null,[473,44],[473,89]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[473,91],[473,127]]]],
        ["attribute","class",["concat",["cursor selectable-item  ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[474,65],[474,76]]]],"selected",""],[],["loc",[null,[474,44],[474,92]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[474,94],[474,134]]]],
        ["attribute","class",["concat",["cursor selectable-item  ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[475,65],[475,78]]]],"selected",""],[],["loc",[null,[475,44],[475,94]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[475,96],[475,138]]]],
        ["attribute","class",["concat",["cursor selectable-item  ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[476,65],[476,76]]]],"selected",""],[],["loc",[null,[476,44],[476,92]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[476,94],[476,134]]]],
        ["attribute","class",["concat",["cursor selectable-item  ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[477,65],[477,76]]]],"selected",""],[],["loc",[null,[477,44],[477,92]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[477,94],[477,134]]]],
        ["block","each",[["get","users",["loc",[null,[483,16],[483,21]]]]],[],1,null,["loc",[null,[483,8],[508,17]]]],
        ["block","if",[["get","showStation",["loc",[null,[513,10],[513,21]]]]],[],2,null,["loc",[null,[513,4],[520,11]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[523,14],[523,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[524,13],[524,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[525,22],[525,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[526,25],[526,33]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[527,22],[527,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[528,16],[528,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[529,22],[529,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[530,20],[530,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[531,22],[531,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[532,20],[532,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[533,20],[533,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[534,28],[534,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[522,6],[542,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[545,67],[545,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[545,84],[545,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[545,103],[545,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[545,126],[545,144]]]]],[],[]]],["loc",[null,[545,0],[545,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[551,8],[551,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[552,10],[552,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[553,13],[553,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[554,17],[554,36]]]]],[],[]]],["loc",[null,[547,0],[554,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[559,17],[559,35]]]]],[],[]]],["loc",[null,[556,0],[559,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[560,49],[560,62]]]]],[],[]]],["loc",[null,[560,0],[560,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});