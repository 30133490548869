define('jason-frontend/templates/sale/compositeproducts', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 10
              },
              "end": {
                "line": 8,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/add-entry.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Neues Paket");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["compositeproducts.create",["subexpr","query-params",[],["referer","sale"],["loc",[null,[6,48],[6,77]]]]],["class","button-rework context primary"],0,null,["loc",[null,[6,10],[8,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 62
              },
              "end": {
                "line": 36,
                "column": 137
              }
            },
            "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","table__title title");
            var el2 = dom.createTextNode("VK brutto");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 6
            },
            "end": {
              "line": 39,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head sticker");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Bezeichnung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  table__cell--right l-5/24");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5]),0,0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[36,68],[36,81]]]]],[],0,null,["loc",[null,[36,62],[36,144]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 20
              },
              "end": {
                "line": 52,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","product.name",["loc",[null,[52,68],[52,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 110
              },
              "end": {
                "line": 54,
                "column": 183
              }
            },
            "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","product.total",["loc",[null,[54,161],[54,174]]]]],[],[]]],["loc",[null,[54,137],[54,176]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 16
                },
                "end": {
                  "line": 63,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 14
              },
              "end": {
                "line": 64,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square exp");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element5,1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
            morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","product",["loc",[null,[58,96],[58,103]]]]],[],[]],"confirmed","delete","text","Wollen Sie dieses Paket wirklich löschen?"],["loc",[null,[58,18],[58,173]]]],
            ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","product",["loc",[null,[59,49],[59,56]]]]],[],[]],"confirmed","delete","textToShow","Löschen","text","Wollen Sie dieses Paket wirklich löschen?"],["loc",[null,[59,24],[59,147]]]],
            ["block","link-to",["compositeproducts.edit",["get","product",["loc",[null,[61,52],[61,59]]]]],["class","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[61,16],[63,28]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 81,
                    "column": 231
                  },
                  "end": {
                    "line": 81,
                    "column": 274
                  }
                },
                "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(", Zeit");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 24
                },
                "end": {
                  "line": 82,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","small block");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(": ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(", 1-fach netto ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(", Faktor: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("%");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element0,0,0);
              morphs[1] = dom.createMorphAt(element0,2,2);
              morphs[2] = dom.createMorphAt(element0,4,4);
              morphs[3] = dom.createMorphAt(element0,6,6);
              morphs[4] = dom.createMorphAt(element0,8,8);
              return morphs;
            },
            statements: [
              ["content","entryProduct.gotNumberPrefix",["loc",[null,[81,52],[81,84]]]],
              ["content","entryProduct.gotNumber",["loc",[null,[81,86],[81,112]]]],
              ["inline","format-currency",[],["classNames","inline","value",["subexpr","@mut",[["get","entryProduct.priceNet",["loc",[null,[81,171],[81,192]]]]],[],[]]],["loc",[null,[81,127],[81,194]]]],
              ["content","entryProduct.gotFactor",["loc",[null,[81,204],[81,230]]]],
              ["block","if",[["subexpr","eq",[["get","entryProduct.unit",["loc",[null,[81,241],[81,258]]]],"Zeit"],[],["loc",[null,[81,237],[81,266]]]]],[],0,null,["loc",[null,[81,231],[81,281]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 80
                  },
                  "end": {
                    "line": 84,
                    "column": 184
                  }
                },
                "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge info-badge-very-small");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["content","tag.name",["loc",[null,[84,164],[84,176]]]]
              ],
              locals: ["tag"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 24
                },
                "end": {
                  "line": 85,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","tags");
              dom.setAttribute(el1,"style","display: block !important;");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["block","each",[["get","entryProduct.tags",["loc",[null,[84,88],[84,105]]]]],[],0,null,["loc",[null,[84,80],[84,193]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 24
                },
                "end": {
                  "line": 90,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","is-important inline-block");
              dom.setAttribute(el2,"style","line-height:14px");
              var el3 = dom.createTextNode("Der betreffende Artikel wurden vom Lieferanten aktualisiert. Bitte überprüfen Sie die Möglichkeit eines alternativen Artikels!");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 18
              },
              "end": {
                "line": 100,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24 l-2/24@desk text-center permanent-caption");
            dom.setAttribute(el2,"data-label"," ");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"height","20");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-9/24 l-8/24@desk permanent-caption");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-7/24 l-3/24@desk text-center permanent-caption");
            dom.setAttribute(el2,"data-label","VPE");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","tax");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24 l-3/24@desk text-center permanent-caption");
            dom.setAttribute(el2,"data-label","Mwst");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" %");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1, 1, 0]);
            var element3 = dom.childAt(element1, [3]);
            var element4 = dom.childAt(element1, [5]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createAttrMorph(element2, 'src');
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
            morphs[3] = dom.createMorphAt(element3,3,3);
            morphs[4] = dom.createMorphAt(element3,4,4);
            morphs[5] = dom.createMorphAt(element3,5,5);
            morphs[6] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
            morphs[8] = dom.createMorphAt(dom.childAt(element1, [7, 1]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","entryProduct.first",["loc",[null,[74,64],[74,82]]]],"first"],[],["loc",[null,[74,43],[74,93]]]]]]],
            ["attribute","src",["concat",["assets/images/icons/",["get","entryProduct.type",["loc",[null,[76,74],[76,91]]]],"-dark.svg"]]],
            ["content","entryProduct.name",["loc",[null,[79,30],[79,51]]]],
            ["block","if",[["get","entryProduct.gotNumber",["loc",[null,[80,30],[80,52]]]]],[],0,null,["loc",[null,[80,24],[82,31]]]],
            ["block","if",[["get","entryProduct.tags",["loc",[null,[83,30],[83,47]]]]],[],1,null,["loc",[null,[83,24],[85,31]]]],
            ["block","if",[["get","entryProduct.deleted",["loc",[null,[86,30],[86,50]]]]],[],2,null,["loc",[null,[86,24],[90,31]]]],
            ["content","entryProduct.unitQuantity",["loc",[null,[93,30],[93,59]]]],
            ["content","entryProduct.unit",["loc",[null,[94,42],[94,63]]]],
            ["content","entryProduct.tax",["loc",[null,[97,30],[97,50]]]]
          ],
          locals: ["entryProduct"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 8
            },
            "end": {
              "line": 105,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table-center table__cell--favs l-4/24 l-2/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-15/24 l-12/24@desk");
          dom.setAttribute(el2,"data-label","Bezeichnung");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24 l-5/24@desk price-value");
          dom.setAttribute(el2,"data-label","VK brutto");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-5/24@desk buttons-3");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button list-action-square icon-button--default  exp");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n               ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 pb30 pl0 no-caption");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--sub table--composite");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [1, 1, 1]);
          var element8 = dom.childAt(element6, [7]);
          var element9 = dom.childAt(element8, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element7, 'class');
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createMorphAt(element7,1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [3, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
          morphs[5] = dom.createMorphAt(element8,1,1);
          morphs[6] = dom.createMorphAt(element9,1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element6, [9, 1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","product.favorite",["loc",[null,[46,148],[46,164]]]],"selected",""],[],["loc",[null,[46,127],[46,180]]]]]]],
          ["element","action",["toggleFavorite",["get","product",["loc",[null,[46,45],[46,52]]]],["subexpr","not",[["get","product.favorite",["loc",[null,[46,58],[46,74]]]]],[],["loc",[null,[46,53],[46,75]]]]],["bubbles",false],["loc",[null,[46,19],[46,91]]]],
          ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[47,18],[47,91]]]],
          ["block","link-to",["compositeproducts.edit",["get","product.id",["loc",[null,[52,56],[52,66]]]]],[],0,null,["loc",[null,[52,20],[52,96]]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[54,116],[54,129]]]]],[],1,null,["loc",[null,[54,110],[54,190]]]],
          ["block","if",[["get","product.editable",["loc",[null,[56,20],[56,36]]]]],[],2,null,["loc",[null,[56,14],[64,21]]]],
          ["inline","button-copy-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","product",["loc",[null,[66,92],[66,99]]]]],[],[]],"confirmed","copy","text","Wollen Sie dieses Paket wirklich kopieren?"],["loc",[null,[66,16],[66,168]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","product",["loc",[null,[67,46],[67,53]]]]],[],[]],"confirmed","copy","textToShow","Kopieren","text","Wollen Sie dieses Paket wirklich kopieren?"],["loc",[null,[67,21],[67,144]]]],
          ["block","each",[["get","product.entries",["loc",[null,[73,26],[73,41]]]]],[],3,null,["loc",[null,[73,18],[100,27]]]]
        ],
        locals: ["product"],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 114,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/sale/compositeproducts.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar sticky with-searchbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Pakete");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","rework-content card site-content product-list add-product add-sale-product add-purchase-product with-context-bar");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter box@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterAll");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon favs");
        dom.setAttribute(el4,"for","filterFavs");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Favoriten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table grid table--large table--with-sub");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(fragment, [2, 1]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element11, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element10, [1, 3]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [3, 1]),1,1);
        morphs[2] = dom.createMorphAt(element12,3,3);
        morphs[3] = dom.createMorphAt(element12,7,7);
        morphs[4] = dom.createMorphAt(dom.childAt(element12, [9]),0,0);
        morphs[5] = dom.createMorphAt(element13,1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element11, [5]),1,1);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","is-jason-network",[],[],["loc",[null,[5,12],[5,30]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[15,30],[15,40]]]]],[],[]],"placeHolder","Produkt suchen ...","search","applyFilter"],["loc",[null,[15,6],[15,96]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[25,70],[25,79]]]]],[],[]]],["loc",[null,[25,6],[25,81]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[27,72],[27,82]]]]],[],[]]],["loc",[null,[27,6],[27,84]]]],
        ["inline","button-favorite",[],["content","","size",18,"showStroke",false,"color","#999999","classNames",""],["loc",[null,[28,68],[28,153]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[32,34],[32,58]]]]],[],[]]],1,null,["loc",[null,[32,6],[39,27]]]],
        ["block","each",[["get","model",["loc",[null,[42,16],[42,21]]]]],[],2,null,["loc",[null,[42,8],[105,17]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[109,29],[109,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[109,6],[109,55]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});