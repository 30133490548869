define('jason-frontend/routes/invoices/open', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'jason-frontend/api'], function (exports, Ember, RouteMixin, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    perPage: 10,
    model: function model(params) {},
    setupController: function setupController(controller, model, params) {
      controller.set('model', model);
      var queryCustomerId = params.queryParams.customerId;

      controller.set("banktransferEnabled", API['default'].hasPermission('c_banktransfer'));

      if (queryCustomerId > 0) {
        this.store.find('customer', queryCustomerId).then(function (customer) {
          controller.set('filterCustomer', customer);
        });
      } else {
        controller.set('filterCustomer', null);
      }

      controller.set('downloadToken', API['default'].getDownloadToken());
      controller.set('mandantId', API['default'].getMandant());

      if (params.queryParams.resetParams) {
        controller.set('page', 1);
        controller.set('filterCustomer', null);
        controller.set('filterCode', '');
        controller.set('filterOrderStatus', null);
        controller.set('pickDate', moment().startOf('month').format("DD. MM. YYYY") + " - " + moment().endOf('month').format("DD. MM. YYYY"));
      }

      this.applyFilter();
    },
    applyFilter: function applyFilter() {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          filterCustomer = controller.get('filterCustomer'),
          customerId = controller.get('customerId'),
          paymentStatus = controller.get('filterPaymentStatus'),
          filterCode = controller.get('filterCode'),
          sortBy = controller.get('sortBy'),
          filterStatus = controller.get('filterStatus'),
          dateRange = controller.get('pickDate'),
          dateFromTo = dateRange.split('-'),
          page = controller.get('page');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

      controller.set('to', to);
      controller.set('from', from);

      if (!paymentStatus) {
        paymentStatus = 'partpaid,notpaid';
      }

      if (isNaN(filterCustomer)) {
        filterCustomer = filterCustomer.id;
      }

      if (!filterStatus) {
        filterStatus = 'to_be_verified,warning1,warning2,sepa,ready_for_money_collection,lawyer,no_warning,money_collection';
      }

      controller.set('showLoading', true);

      this.findPaged('invoice', {
        filterStatus: filterStatus,
        filterPaymentStatus: paymentStatus,
        sortDir: sortDir,
        filterCode: filterCode,
        filterCustomer: filterCustomer,
        filterFrom: from,
        filterTo: to,
        openInvoices: true,
        sortBy: sortBy,
        perPage: 20,
        page: page
      }).then(function (entries) {
        controller.set('model', entries);
        controller.set('totalOpen', entries.meta.total_open);
        controller.set('resultOpen', entries.meta.result_open);
        controller.set('showLoading', false);
      });
    },
    actions: {
      checkForm: function checkForm(form) {
        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          $('#' + form + ' input[name=token]').val(data.responseText);
          $('#' + form).submit();
        });
      },
      forwardInvoice: function forwardInvoice(data) {
        var self = this;
        jason.notifiction.notifiy('Rechnungsunterlagen', 'weitergeleitet');
        $.ajax({
          url: "/api/invoices/forwardComposite",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {});
      },
      sortable: function sortable(attr) {
        if (this.get('controller').get('sortBy') === attr) {
          if (this.get('controller').get('sortDir') === 'asc') {
            this.get('controller').set('sortDir', 'desc');
          } else {
            this.get('controller').set('sortDir', 'asc');
          }
        } else {
          this.get('controller').set('sortBy', attr);
        }
        this.applyFilter();
      },
      saveNextStatusChange: function saveNextStatusChange(data) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + data.id + "/nextStatusChange",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Aktualisiert', '');
        });
      },
      stopWarning: function stopWarning(id) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + id + "/stopWarning",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Mahnprozess', 'gestoppt');
        });
      },
      resumeWarning: function resumeWarning(id) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + id + "/resumeWarning",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Mahnprozess', 'wieder aufgenommen');
        });
      },
      changeInvoiceStatus: function changeInvoiceStatus(data) {

        var self = this;
        $.ajax({
          url: "/api/invoices/" + data.id + "/changeStatus",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Status', 'geändert');
        });
      },
      savePaymentAgreement: function savePaymentAgreement(data) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + data.id + "/paymentAgreement",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Aktualisiert', '');
        });
      },
      saveWarningInfo: function saveWarningInfo(data) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + data.id + "/warningInfo",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Aktualisiert', '');
        });
      },
      saveWarningAddInfo: function saveWarningAddInfo(data) {
        var self = this;
        $.ajax({
          url: "/api/invoices/" + data.id + "/warningAddText",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Aktualisiert', '');
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.store.find('customer', { searchTerm: query.term }).then(deferred.resolve, deferred.reject);
      },
      downloadInvoice: function downloadInvoice(invoice) {
        var deferred = Ember['default'].$.ajax({
          url: "/api/invoices/" + invoice.id + "/preview?style=" + invoice.printTemplateId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        }, function (data) {
          window.open('/api/invoices/show/' + data.responseText);
        });
        return Ember['default'].RSVP.resolve(deferred);
      },
      downloadWarningInvoice: function downloadWarningInvoice(invoice, number) {
        window.open('/api/customers/downloadWarning/' + invoice.id + "/" + number);
      },
      downloadWarningText: function downloadWarningText(invoice, number) {
        window.open('/api/customers/downloadWarning/' + invoice.id + "/" + number);
      },
      setLawyer: function setLawyer(id) {
        var self = this,
            data = {
          newState: 'lawyer',
          invoiceId: id
        };

        $.ajax({
          url: "/api/invoices/updateState",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'Auf Status Rechtsanwalt gesetzt');
        });
      },
      setMoneyCollection: function setMoneyCollection(id) {
        var self = this,
            data = {
          newState: 'money_collection',
          invoiceId: id
        };

        $.ajax({
          url: "/api/invoices/updateState",
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify(data),
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'Auf Status Inkasso gesetzt');
        });
      },
      close: function close(id) {
        var self = this;

        $.ajax({
          url: "/api/invoices/" + id + "/finish",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'uneinbringlich ausgebucht');
        }, function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'uneinbringlich ausgebucht');
        });
      },
      openCancelOverlay: function openCancelOverlay(id) {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-cancel-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        this.get('controller').get('cancelInvoicePanel').send('open', id);
      },
      cancel: function cancel(id, text) {
        var self = this;
        var data = {
          reason: text
        };

        $.ajax({
          url: "/api/invoices/" + id + "/cancel",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'storniert');
        }, function () {
          self.applyFilter();
          jason.notifiction.notifiy('Rechnung', 'storniert');
        });
      }
    }
  });

});