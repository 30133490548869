define('jason-frontend/templates/cash/choose', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 230
                    },
                    "end": {
                      "line": 50,
                      "column": 276
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/choose.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("(gelöscht)");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 51,
                      "column": 28
                    },
                    "end": {
                      "line": 52,
                      "column": 100
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/choose.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small block");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","open-invoice.customerData.company",["loc",[null,[52,56],[52,93]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 26
                  },
                  "end": {
                    "line": 53,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/cash/choose.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                          ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
                morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
                morphs[4] = dom.createMorphAt(fragment,8,8,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["content","open-invoice.customerData.title.name",["loc",[null,[50,110],[50,150]]]],
                ["content","open-invoice.customerData.firstname",["loc",[null,[50,151],[50,190]]]],
                ["content","open-invoice.customerData.lastname",["loc",[null,[50,191],[50,229]]]],
                ["block","if",[["get","open-invoice.customerDeleted",["loc",[null,[50,236],[50,264]]]]],[],0,null,["loc",[null,[50,230],[50,283]]]],
                ["block","if",[["get","open-invoice.customerData.company",["loc",[null,[51,34],[51,67]]]]],[],1,null,["loc",[null,[51,28],[52,107]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 26
                  },
                  "end": {
                    "line": 53,
                    "column": 71
                  }
                },
                "moduleName": "jason-frontend/templates/cash/choose.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","open-invoice.customerData.company",["loc",[null,[53,34],[53,71]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 24
                },
                "end": {
                  "line": 53,
                  "column": 78
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["get","open-invoice.customerData.firstname",["loc",[null,[50,37],[50,72]]]],["get","open-invoice.customerData.lastname",["loc",[null,[50,73],[50,107]]]]],[],["loc",[null,[50,32],[50,108]]]]],[],0,1,["loc",[null,[50,26],[53,78]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 16
              },
              "end": {
                "line": 54,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/cash/choose.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","open-invoice.customerData.id",["loc",[null,[49,52],[49,80]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[49,24],[53,90]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 16
              },
              "end": {
                "line": 56,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/cash/choose.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  Barverkauf\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 68
              },
              "end": {
                "line": 58,
                "column": 109
              }
            },
            "moduleName": "jason-frontend/templates/cash/choose.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(",");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 66,
                    "column": 22
                  },
                  "end": {
                    "line": 66,
                    "column": 182
                  }
                },
                "moduleName": "jason-frontend/templates/cash/choose.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","button__icon");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[66,92],[66,113]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[66,124],[66,143]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[66,152],[66,176]]]]],[],[]]],["loc",[null,[66,70],[66,178]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 16
                },
                "end": {
                  "line": 70,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","patient-selector selected");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createMorphAt(element1,1,1);
              morphs[2] = dom.createMorphAt(element1,3,3);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["button ",["subexpr","value-bool-evaluator",[["get","patient.category",["loc",[null,[65,62],[65,78]]]],"button--has-icon",""],[],["loc",[null,[65,39],[65,102]]]]," button--toggle no-hover  large-confirm"]]],
              ["block","if",[["get","patient.category",["loc",[null,[66,28],[66,44]]]]],[],0,null,["loc",[null,[66,22],[66,189]]]],
              ["content","patient.name",["loc",[null,[67,22],[67,38]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 14
              },
              "end": {
                "line": 71,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/cash/choose.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["subexpr","is-patient-selected",[["get","open-invoice.selectedPatients",["loc",[null,[63,48],[63,77]]]],["get","patient",["loc",[null,[63,78],[63,85]]]]],[],["loc",[null,[63,27],[63,86]]]],["subexpr","or",[["subexpr","not",[["get","patient.dead",["loc",[null,[63,96],[63,108]]]]],[],["loc",[null,[63,91],[63,109]]]],["subexpr","and",[["get","showDead",["loc",[null,[63,115],[63,123]]]],["get","patient.dead",["loc",[null,[63,124],[63,136]]]]],[],["loc",[null,[63,110],[63,137]]]]],[],["loc",[null,[63,87],[63,138]]]]],[],["loc",[null,[63,22],[63,139]]]]],[],0,null,["loc",[null,[63,16],[70,23]]]]
          ],
          locals: ["patient"],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 16
                },
                "end": {
                  "line": 88,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/cash/choose.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp cursor icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Neues Tier");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["addPatient",["get","open-invoice.customerId",["loc",[null,[85,114],[85,137]]]]],[],["loc",[null,[85,92],[85,139]]]],
              ["inline","button-add-entry",[],["color","#fff","showStroke",false,"size","36","content","Neues Tier"],["loc",[null,[86,20],[86,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 14
              },
              "end": {
                "line": 89,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/choose.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","open-invoice.customer",["loc",[null,[84,22],[84,43]]]]],[],0,null,["loc",[null,[84,16],[88,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 8
            },
            "end": {
              "line": 92,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/choose.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center table__cell--name l-12/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n               ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n               ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Uhr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Rechnungsempfänger");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-7/24@desk");
          dom.setAttribute(el2,"data-label","Leistungsempfänger");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right@desk table__cell--name l-12/24 l-24/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Summe brutto");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-5/24@desk buttons-3");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","exp icon-button icon-button--success list-action-square");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("Auswählen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","exp icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element2, [9]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element6, [3]);
          var morphs = new Array(14);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
          morphs[3] = dom.createMorphAt(element5,0,0);
          morphs[4] = dom.createMorphAt(element5,1,1);
          morphs[5] = dom.createMorphAt(element5,3,3);
          morphs[6] = dom.createMorphAt(element5,5,5);
          morphs[7] = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element2, [7, 1]),0,0);
          morphs[9] = dom.createElementMorph(element7);
          morphs[10] = dom.createMorphAt(element7,1,1);
          morphs[11] = dom.createMorphAt(element8,1,1);
          morphs[12] = dom.createMorphAt(dom.childAt(element8, [2]),0,0);
          morphs[13] = dom.createMorphAt(element6,5,5);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","open-invoice.created",["loc",[null,[43,35],[43,55]]]],"DD. MM. YYYY"],[],["loc",[null,[43,21],[43,72]]]],
          ["inline","time-format",[["get","open-invoice.created",["loc",[null,[44,47],[44,67]]]],"HH:mm"],[],["loc",[null,[44,33],[44,77]]]],
          ["block","if",[["get","open-invoice.customer",["loc",[null,[48,22],[48,43]]]]],[],0,1,["loc",[null,[48,16],[56,23]]]],
          ["content","open-invoice.customerData.street",["loc",[null,[58,32],[58,68]]]],
          ["block","if",[["get","open-invoice.customerData.street",["loc",[null,[58,74],[58,106]]]]],[],2,null,["loc",[null,[58,68],[58,116]]]],
          ["content","open-invoice.customerData.postalCode",["loc",[null,[58,117],[58,157]]]],
          ["content","open-invoice.customerData.town",["loc",[null,[58,158],[58,192]]]],
          ["block","each",[["get","open-invoice.patients",["loc",[null,[62,22],[62,43]]]]],[],3,null,["loc",[null,[62,14],[71,25]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","open-invoice.totalGross",["loc",[null,[74,44],[74,67]]]]],[],[]]],["loc",[null,[74,20],[74,69]]]],
          ["element","action",["selectInvoice",["get","open-invoice.id",["loc",[null,[77,42],[77,57]]]]],[],["loc",[null,[77,17],[77,59]]]],
          ["inline","button-select",[],["color","white","showStroke",false],["loc",[null,[78,16],[78,64]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","open-invoice",["loc",[null,[81,81],[81,93]]]]],[],[]],"confirmed","closeInvoice","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[81,16],[81,179]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","open-invoice",["loc",[null,[81,210],[81,222]]]]],[],[]],"confirmed","closeInvoice","textToShow","Löschen","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[81,185],[81,329]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[83,20],[83,28]]]]],[],4,null,["loc",[null,[83,14],[89,21]]]]
        ],
        locals: ["open-invoice"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 99,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/choose.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Ausgangsrechnungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Neue Ausgangsrechnung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid small-dashboard");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col pl0 u-hide@phone l-12/24 gutter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5,"class","card__title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Anzahl offen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col pl0 u-hide@phone l-12/24 gutter");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","card u-text--center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5,"class","card__title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Summe offen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper-rework");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table box no-box@phone grid table--large");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24 table__cell--center");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Datum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-6/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Rechnungsempfänger");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-7/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Leistungsempfänger");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--right l-3/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Summe brutto");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-5/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0, 1, 3, 1]);
        var element10 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createElementMorph(element9);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [1, 1, 1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [3, 1, 1]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4, 1, 1, 3]),1,1);
        morphs[4] = dom.createMorphAt(fragment,6,6,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["createInvoice"],[],["loc",[null,[5,9],[5,36]]]],
        ["content","count",["loc",[null,[16,32],[16,41]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","sum",["loc",[null,[22,56],[22,59]]]]],[],[]],"class","inline"],["loc",[null,[22,32],[22,76]]]],
        ["block","each",[["get","invoices.invoice",["loc",[null,[40,16],[40,32]]]]],[],0,null,["loc",[null,[40,8],[92,17]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[98,41],[98,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[98,74],[98,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[98,134],[98,152]]]]],[],[]]],["loc",[null,[98,0],[98,154]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});