define('jason-frontend/templates/components/notification-center', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/notification-center.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","badge badge-danger");
            var el2 = dom.createTextNode("5+");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 8,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/notification-center.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","badge badge-danger");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","unreadMessagesCount",["loc",[null,[7,39],[7,62]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","gt",[["get","unreadMessagesCount",["loc",[null,[4,14],[4,33]]]],5],[],["loc",[null,[4,10],[4,36]]]]],[],0,1,["loc",[null,[4,4],[8,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"id","readAllButton");
          dom.setAttribute(el1,"class","cursor");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element9);
          morphs[1] = dom.createMorphAt(element9,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["markAllAsRead"],[],["loc",[null,[20,9],[20,35]]]],
          ["inline","button-all-checked-notification",[],["color","#3B6182","content","Alle als gelesen markieren","size",42],["loc",[null,[21,8],[21,104]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 24
                },
                "end": {
                  "line": 37,
                  "column": 177
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-myjason",[],["content","","color","#008EC6","classNames","inline-icon","cssClass","inverted","classNames","inline-icon"],["loc",[null,[37,61],[37,177]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 22
                  },
                  "end": {
                    "line": 41,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/notification-center.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-lab",[],["showStroke",true,"content","","cssClass","invertedZoetis","classNames","invertedZoetis inline-icon"],["loc",[null,[40,24],[40,132]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 41,
                      "column": 22
                    },
                    "end": {
                      "line": 43,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notification-center.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-lab",[],["showStroke",true,"content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[42,24],[42,111]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 43,
                        "column": 22
                      },
                      "end": {
                        "line": 45,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notification-center.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-lab",[],["showStroke",true,"content","","cssClass","inverted","classNames","inline-icon"],["loc",[null,[44,24],[44,111]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 45,
                        "column": 22
                      },
                      "end": {
                        "line": 47,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/notification-center.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-lab",[],["showStroke",true,"content","","fill","#fff","cssClass","invertedLaboklin","classNames","invertedLaboklin inline-icon"],["loc",[null,[46,24],[46,148]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 43,
                      "column": 22
                    },
                    "end": {
                      "line": 47,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/notification-center.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[43,36],[43,46]]]],"Aniveri Laboranalyse verfügbar"],[],["loc",[null,[43,32],[43,80]]]]],[],0,1,["loc",[null,[43,22],[47,22]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 22
                  },
                  "end": {
                    "line": 47,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/components/notification-center.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","item.title",["loc",[null,[41,36],[41,46]]]],"Interner Untersuchungsauftrag verfügbar"],[],["loc",[null,[41,32],[41,89]]]]],[],0,1,["loc",[null,[41,22],[47,22]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 20
                },
                "end": {
                  "line": 48,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","item.cssClass",["loc",[null,[39,32],[39,45]]]],"zoetis"],[],["loc",[null,[39,28],[39,55]]]]],[],0,1,["loc",[null,[39,22],[47,29]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 20
                },
                "end": {
                  "line": 49,
                  "column": 104
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-invoice",[],["showStroke",true,"content",""],["loc",[null,[49,59],[49,104]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 111
                },
                "end": {
                  "line": 49,
                  "column": 196
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["showStroke",true,"content",""],["loc",[null,[49,151],[49,196]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 203
                },
                "end": {
                  "line": 49,
                  "column": 272
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-patient",[],["content",""],["loc",[null,[49,243],[49,272]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 279
                },
                "end": {
                  "line": 49,
                  "column": 363
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-inventory",[],["showStroke",true,"content",""],["loc",[null,[49,316],[49,363]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 20
                },
                "end": {
                  "line": 50,
                  "column": 254
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-etiga-small",[],["color","#404040","showStroke",true,"content",""],["loc",[null,[50,189],[50,254]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 20
                },
                "end": {
                  "line": 63,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child8 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 26
                  },
                  "end": {
                    "line": 66,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/components/notification-center.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small block");
                var el2 = dom.createTextNode("Patient: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["content","item.patient.name",["loc",[null,[65,63],[65,84]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 67,
                    "column": 26
                  },
                  "end": {
                    "line": 69,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/components/notification-center.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small block");
                var el2 = dom.createTextNode("Besitzer: ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element0,1,1);
                morphs[1] = dom.createMorphAt(element0,3,3);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[68,64],[68,91]]]],
                ["content","item.customer.lastname",["loc",[null,[68,92],[68,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 20
                },
                "end": {
                  "line": 70,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/components/notification-center.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.patient",["loc",[null,[64,32],[64,44]]]]],[],0,null,["loc",[null,[64,26],[66,33]]]],
              ["block","if",[["get","item.customer",["loc",[null,[67,32],[67,45]]]]],[],1,null,["loc",[null,[67,26],[69,33]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 12
              },
              "end": {
                "line": 83,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/notification-center.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"class","timeline-item notification-entry cursor");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","timeline-desc mt5");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","mb0 pr60");
            var el4 = dom.createElement("b");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","readButton cursor");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","readAllButton cursor");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","content");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","notTitle");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","small inline-block");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","expand");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el4 = dom.createElement("svg");
            dom.setAttribute(el4,"xmlns","http://www.w3.org/2000/svg");
            dom.setAttribute(el4,"width","32");
            dom.setAttribute(el4,"height","32");
            dom.setAttribute(el4,"viewBox","0 0 32 32");
            dom.setAttribute(el4,"class","toggle-button");
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("path");
            dom.setAttribute(el5,"fill","#3B6182");
            dom.setAttribute(el5,"fill-rule","evenodd");
            dom.setAttribute(el5,"d","M1.12401361,14.9642143 C0.841241497,14.9642143 0.558027211,14.8596429 0.342465986,14.6502857 C-0.0893197279,14.232 -0.0893197279,13.5535714 0.342465986,13.1352857 L6.15620748,7.5 L0.342465986,1.86492857 C-0.0893197279,1.44642857 -0.0893197279,0.768 0.342465986,0.349714286 C0.773809524,-0.0685714286 1.47421769,-0.0685714286 1.90556122,0.349714286 L9.28284014,7.5 L1.90556122,14.6502857 C1.69,14.8596429 1.40678571,14.9642143 1.12401361,14.9642143 L1.12401361,14.9642143 Z");
            dom.setAttribute(el5,"transform","rotate(90 6 17.5)");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.setNamespace(null);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element1, [3]);
            var element5 = dom.childAt(element4, [3]);
            var element6 = dom.childAt(element4, [5]);
            var element7 = dom.childAt(element4, [7]);
            var element8 = dom.childAt(element1, [5]);
            var morphs = new Array(22);
            morphs[0] = dom.createAttrMorph(element1, 'id');
            morphs[1] = dom.createAttrMorph(element2, 'class');
            morphs[2] = dom.createMorphAt(element3,0,0);
            morphs[3] = dom.createMorphAt(element3,2,2);
            morphs[4] = dom.createMorphAt(element3,4,4);
            morphs[5] = dom.createMorphAt(element3,5,5);
            morphs[6] = dom.createMorphAt(element3,6,6);
            morphs[7] = dom.createMorphAt(element3,7,7);
            morphs[8] = dom.createMorphAt(element3,9,9);
            morphs[9] = dom.createMorphAt(dom.childAt(element4, [1, 0]),0,0);
            morphs[10] = dom.createAttrMorph(element5, 'data-id');
            morphs[11] = dom.createElementMorph(element5);
            morphs[12] = dom.createMorphAt(element5,1,1);
            morphs[13] = dom.createAttrMorph(element6, 'data-id');
            morphs[14] = dom.createElementMorph(element6);
            morphs[15] = dom.createMorphAt(element6,1,1);
            morphs[16] = dom.createElementMorph(element7);
            morphs[17] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
            morphs[18] = dom.createMorphAt(element7,3,3);
            morphs[19] = dom.createMorphAt(dom.childAt(element7, [5]),0,0);
            morphs[20] = dom.createAttrMorph(element8, 'class');
            morphs[21] = dom.createUnsafeMorphAt(element8,1,1);
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["message",["get","item.id",["loc",[null,[35,31],[35,38]]]]]]],
            ["attribute","class",["concat",["timeline-icon ",["get","item.category.id",["loc",[null,[36,44],[36,60]]]]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[37,34],[37,50]]]],"jason"],[],["loc",[null,[37,30],[37,59]]]]],[],0,null,["loc",[null,[37,24],[37,184]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[38,30],[38,46]]]],"lab"],[],["loc",[null,[38,26],[38,53]]]]],[],1,null,["loc",[null,[38,20],[48,27]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[49,30],[49,46]]]],"invoice"],[],["loc",[null,[49,26],[49,57]]]]],[],2,null,["loc",[null,[49,20],[49,111]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[49,121],[49,137]]]],"practice"],[],["loc",[null,[49,117],[49,149]]]]],[],3,null,["loc",[null,[49,111],[49,203]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[49,213],[49,229]]]],"calendar"],[],["loc",[null,[49,209],[49,241]]]]],[],4,null,["loc",[null,[49,203],[49,279]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[49,289],[49,305]]]],"ehapo"],[],["loc",[null,[49,285],[49,314]]]]],[],5,null,["loc",[null,[49,279],[49,370]]]],
            ["block","if",[["subexpr","or",[["subexpr","eq",[["get","item.category.id",["loc",[null,[50,34],[50,50]]]],"etigashare"],[],["loc",[null,[50,30],[50,64]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[50,69],[50,85]]]],"app"],[],["loc",[null,[50,65],[50,92]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[50,97],[50,113]]]],"apporder"],[],["loc",[null,[50,93],[50,125]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[50,130],[50,146]]]],"appapp"],[],["loc",[null,[50,126],[50,156]]]],["subexpr","eq",[["get","item.category.id",["loc",[null,[50,161],[50,177]]]],"etiga"],[],["loc",[null,[50,157],[50,186]]]]],[],["loc",[null,[50,26],[50,187]]]]],[],6,null,["loc",[null,[50,20],[50,261]]]],
            ["content","item.category.name",["loc",[null,[53,41],[53,63]]]],
            ["attribute","data-id",["concat",["message",["get","item.id",["loc",[null,[54,94],[54,101]]]]]]],
            ["element","action",["markAsRead",["get","item",["loc",[null,[54,43],[54,47]]]]],[],["loc",[null,[54,21],[54,49]]]],
            ["inline","button-checked",[],["cssClasses","checked-button","size",24],["loc",[null,[55,20],[55,74]]]],
            ["attribute","data-id",["concat",["message",["get","item.id",["loc",[null,[57,103],[57,110]]]]]]],
            ["element","action",["markAsReadForAll",["get","item",["loc",[null,[57,49],[57,53]]]]],[],["loc",[null,[57,21],[57,55]]]],
            ["inline","button-all-checked",[],["classNames","checked-button inline-icon","color","#3B6182","size",24,"content","Für alle (anderen MitarbeiterInnen) als gelesen markieren"],["loc",[null,[58,20],[58,174]]]],
            ["element","action",["openMessage",["get","item.id",["loc",[null,[60,62],[60,69]]]]],[],["loc",[null,[60,39],[60,71]]]],
            ["content","item.title",["loc",[null,[61,42],[61,56]]]],
            ["block","if",[["subexpr","eq",[["get","item.category.id",["loc",[null,[62,30],[62,46]]]],"etiga"],[],["loc",[null,[62,26],[62,55]]]]],[],7,8,["loc",[null,[62,20],[70,27]]]],
            ["inline","time-format",[["get","item.created",["loc",[null,[71,67],[71,79]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[71,53],[71,102]]]],
            ["attribute","class",["concat",["full-text small mt10 pr40 ",["subexpr","css-bool-evaluator",[["get","item.collapsed",["loc",[null,[79,75],[79,89]]]],"","hidden"],[],["loc",[null,[79,54],[79,103]]]]]]],
            ["content","item.message",["loc",[null,[80,18],[80,36]]]]
          ],
          locals: ["item"],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 10
            },
            "end": {
              "line": 84,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","unreadMessages",["loc",[null,[34,20],[34,34]]]]],[],0,null,["loc",[null,[34,12],[83,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 10
            },
            "end": {
              "line": 86,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/notification-center.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            Zurzeit nichts Neues\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 129,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/notification-center.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","triggerNotificationDropdown");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.setAttribute(el2,"src","assets/images/icons/rework/header-notifications.svg");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","w400 notification-dropdown dopenDirectMessageopdown-persist hidden");
        dom.setAttribute(el1,"role","menu");
        dom.setAttribute(el1,"id","notificationPanel");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel mbn");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-menu");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title fw600");
        var el5 = dom.createTextNode(" Benachrichtigungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","cursor");
        dom.setAttribute(el4,"id","toNotificationsButton");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","scroller-bar");
        dom.setAttribute(el4,"style","height: 249px;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","scroller-track");
        dom.setAttribute(el5,"style","height: 249px; margin-bottom: 0px; margin-top: 0px;");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","scroller-handle");
        dom.setAttribute(el6,"style","height: 123.262px; top: 0px;");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","scroller-content");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        dom.setAttribute(el5,"class","timeline-list");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-footer text-center p7 cursor ");
        var el4 = dom.createTextNode("\n      Alle anzeigen\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(document).on('click', '#triggerNotificationDropdown', function(e) {\n      $('#notificationPanel').toggleClass('hidden');\n      $('#notificationPanelCalendar').addClass('hidden');\n      e.stopPropagation();\n    });\n\n    $(document).on('click', function(e) {\n      if (e.target.id != 'notificationPanel' && !$('#notificationPanel').has(e.target).length && !$(\n          '#notificationPanel').hasClass('hidden')) {\n        $('#notificationPanel').addClass('hidden');\n      }\n    });\n\n    $(document).on('click', '#notificationPanel li.notification-entry', function() {\n        if($(this).find('div.full-text').hasClass('hidden')) {\n          $(this).find('div.full-text').removeClass('hidden'); //toggleClass\n          $(this).find('div.timeline-desc div.float-right').addClass('hidden');\n          $(this).find('div.expand').addClass('inverted');\n        } else {\n          $(this).find('div.full-text').addClass('hidden'); //toggleClass\n          $(this).find('div.timeline-desc div.float-right').removeClass('hidden');\n          $(this).find('div.expand').removeClass('inverted');\n        }\n    });\n    $(document).on('click', '#notificationPanel a.readButton', function() {\n      $('#' + $(this).attr('data-id')).addClass('hidden');\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [2, 1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element11, [3]);
        var element13 = dom.childAt(element10, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),3,3);
        morphs[1] = dom.createElementMorph(element12);
        morphs[2] = dom.createMorphAt(element12,1,1);
        morphs[3] = dom.createMorphAt(element11,5,5);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [3, 3, 1]),1,1);
        morphs[5] = dom.createElementMorph(element13);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","gt",[["get","unreadMessagesCount",["loc",[null,[3,12],[3,31]]]],0],[],["loc",[null,[3,8],[3,34]]]]],[],0,null,["loc",[null,[3,2],[9,9]]]],
        ["element","action",["toNotifications"],[],["loc",[null,[16,9],[16,37]]]],
        ["inline","button-quickaction-notification",[],["color","#404040","content","Alle Benachrichtigungen anzeigen","size",42],["loc",[null,[17,8],[17,110]]]],
        ["block","if",[["get","unreadMessages",["loc",[null,[19,8],[19,22]]]]],[],1,null,["loc",[null,[19,2],[23,9]]]],
        ["block","if",[["get","unreadMessages",["loc",[null,[33,16],[33,30]]]]],[],2,3,["loc",[null,[33,10],[86,17]]]],
        ["element","action",["toNotifications"],[],["loc",[null,[91,53],[91,81]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});