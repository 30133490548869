define('jason-frontend/templates/products/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 7,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/products/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/add-entry.svg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("Neues Produkt");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 4
            },
            "end": {
              "line": 59,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","div-horizontal");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-lane grid");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Hersteller");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Kategorie");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Quelle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element23, [1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element23, [3]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element23, [5]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterManufacturer",["loc",[null,[23,18],[23,36]]]]],[],[]],"optionLabelPath","name","placeholder","Hersteller","allowClear",true,"typeaheadSearchingText","Suche Hersteller","typeaheadNoMatchesText","Keine Hersteller gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"cssClass","custom-select2 newStyle tuned","query","queryManufacturer"],["loc",[null,[22,10],[34,12]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[39,20],[39,30]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[40,18],[40,41]]]]],[],[]],"optionLabelPath","name","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[38,10],[45,12]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","distributors",["loc",[null,[50,20],[50,32]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDistributor",["loc",[null,[51,18],[51,35]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[49,10],[56,12]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 10
              },
              "end": {
                "line": 84,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","selectable-item has-icon");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createAttrMorph(element22, 'for');
            morphs[2] = dom.createElementMorph(element22);
            morphs[3] = dom.createMorphAt(element22,0,0);
            return morphs;
          },
          statements: [
            ["inline","input",[],["type","checkbox","id",["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[81,54],[81,68]]]]],[],["loc",[null,[81,39],[81,69]]]],"checked",["subexpr","@mut",[["get","tag.selected",["loc",[null,[81,78],[81,90]]]]],[],[]]],["loc",[null,[81,12],[81,92]]]],
            ["attribute","for",["concat",[["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[82,73],[82,87]]]]],[],["loc",[null,[82,57],[82,89]]]]]]],
            ["element","action",["selectTag",["get","tag",["loc",[null,[83,100],[83,103]]]]],[],["loc",[null,[82,91],[83,105]]]],
            ["content","tag.name",["loc",[null,[83,106],[83,118]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 8
            },
            "end": {
              "line": 85,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","articleTags",["loc",[null,[80,18],[80,29]]]]],[],0,null,["loc",[null,[80,10],[84,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 6
            },
            "end": {
              "line": 98,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/products/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head sticker");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Bezeichnung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Tags");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-4/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Lager");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 14
              },
              "end": {
                "line": 105,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","product__image image__max80 list");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element10, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["get","product.picture.path",["loc",[null,[104,69],[104,89]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 105,
                "column": 14
              },
              "end": {
                "line": 107,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","product__image image__max80 list");
            dom.setAttribute(el1,"alt","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element9, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/",["subexpr","get-product-picture",[["get","product",["loc",[null,[106,103],[106,110]]]]],[],["loc",[null,[106,81],[106,112]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 20
              },
              "end": {
                "line": 110,
                "column": 75
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","product.name",["loc",[null,[110,59],[110,75]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 111,
                "column": 32
              },
              "end": {
                "line": 111,
                "column": 90
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","product.partnerCategory",["loc",[null,[111,63],[111,90]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 111,
                "column": 98
              },
              "end": {
                "line": 111,
                "column": 162
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","product.partnerSubcategory",["loc",[null,[111,132],[111,162]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 53
                },
                "end": {
                  "line": 119,
                  "column": 138
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge mr5 mb5");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
              return morphs;
            },
            statements: [
              ["content","tag.name",["loc",[null,[119,118],[119,130]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 14
              },
              "end": {
                "line": 119,
                "column": 154
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","tags");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["block","each",[["get","product.tags",["loc",[null,[119,61],[119,73]]]]],[],0,null,["loc",[null,[119,53],[119,147]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 154
              },
              "end": {
                "line": 119,
                "column": 189
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","none");
            var el2 = dom.createTextNode("-");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 125,
                "column": 14
              },
              "end": {
                "line": 125,
                "column": 131
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","stock-status",[],["classNames","","stocks",["subexpr","@mut",[["get","product.stockLevels",["loc",[null,[125,110],[125,129]]]]],[],[]]],["loc",[null,[125,74],[125,131]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 125,
                "column": 131
              },
              "end": {
                "line": 125,
                "column": 310
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","mb0 block");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","label stock-label label-muted cursor pl0 pr0");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-text",[],["classNames","minw25","contentAsHTML",true,"content","","text","X"],["loc",[null,[125,222],[125,296]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 132,
                "column": 14
              },
              "end": {
                "line": 134,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Bearbeiten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[133,16],[133,82]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 26
              },
              "end": {
                "line": 148,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line no-border");
            var el2 = dom.createTextNode("\n                             ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n                               ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                             ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createElementMorph(element8);
            morphs[2] = dom.createMorphAt(element8,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","product.favorite",["loc",[null,[144,168],[144,184]]]],"selected",""],[],["loc",[null,[144,147],[144,200]]]]]]],
            ["element","action",["toggleProductFavorite",["get","product",["loc",[null,[144,65],[144,72]]]],["subexpr","not",[["get","product.favorite",["loc",[null,[144,78],[144,94]]]]],[],["loc",[null,[144,73],[144,95]]]]],["bubbles",false],["loc",[null,[144,32],[144,111]]]],
            ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[145,31],[145,104]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 150,
                  "column": 28
                },
                "end": {
                  "line": 156,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line no-border");
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element7, 'class');
              morphs[1] = dom.createElementMorph(element7);
              morphs[2] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","price.favorite",["loc",[null,[152,171],[152,185]]]],"selected",""],[],["loc",[null,[152,150],[152,201]]]]]]],
              ["element","action",["togglePriceFavorite",["get","product",["loc",[null,[152,64],[152,71]]]],["get","price",["loc",[null,[152,72],[152,77]]]],["subexpr","not",[["get","price.favorite",["loc",[null,[152,83],[152,97]]]]],[],["loc",[null,[152,78],[152,98]]]]],["bubbles",false],["loc",[null,[152,33],[152,114]]]],
              ["inline","button-favorite",[],["size","18","color","#999999","showStroke",false,"content",""],["loc",[null,[153,32],[153,105]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 26
              },
              "end": {
                "line": 157,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[150,51],[150,65]]]],["get","filterFavs",["loc",[null,[150,66],[150,76]]]]],[],["loc",[null,[150,34],[150,77]]]]],[],0,null,["loc",[null,[150,28],[156,35]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 161,
                "column": 22
              },
              "end": {
                "line": 166,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line price-value mixed");
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","tax");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-number-german",[["get","product.quantity",["loc",[null,[163,55],[163,71]]]]],[],["loc",[null,[163,32],[163,73]]]],
            ["content","product.unit.name",["loc",[null,[164,44],[164,65]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 168,
                  "column": 24
                },
                "end": {
                  "line": 173,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line price-value mixed");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","tax");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
              return morphs;
            },
            statements: [
              ["inline","format-number-german",[["get","price.quantity",["loc",[null,[170,57],[170,71]]]]],[],["loc",[null,[170,34],[170,73]]]],
              ["content","price.unit.name",["loc",[null,[171,46],[171,65]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 167,
                "column": 22
              },
              "end": {
                "line": 174,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[168,47],[168,61]]]],["get","filterFavs",["loc",[null,[168,62],[168,72]]]]],[],["loc",[null,[168,30],[168,73]]]]],[],0,null,["loc",[null,[168,24],[173,31]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child14 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 181,
                    "column": 60
                  },
                  "end": {
                    "line": 181,
                    "column": 106
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Verabreicht");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 181,
                    "column": 106
                  },
                  "end": {
                    "line": 181,
                    "column": 123
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Angewandt");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 180,
                  "column": 28
                },
                "end": {
                  "line": 182,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge mt9");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[181,66],[181,93]]]]],[],0,1,["loc",[null,[181,60],[181,130]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 182,
                  "column": 28
                },
                "end": {
                  "line": 184,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","none");
              var el2 = dom.createTextNode("-");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 177,
                "column": 22
              },
              "end": {
                "line": 187,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line");
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","product.applied",["loc",[null,[180,34],[180,49]]]]],[],0,1,["loc",[null,[180,28],[184,35]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child15 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 193,
                      "column": 62
                    },
                    "end": {
                      "line": 193,
                      "column": 108
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Verabreicht");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 193,
                      "column": 108
                    },
                    "end": {
                      "line": 193,
                      "column": 125
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Angewandt");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 192,
                    "column": 30
                  },
                  "end": {
                    "line": 194,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge mt9");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[193,68],[193,95]]]]],[],0,1,["loc",[null,[193,62],[193,132]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 194,
                    "column": 30
                  },
                  "end": {
                    "line": 196,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","none");
                var el2 = dom.createTextNode("-");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 190,
                  "column": 26
                },
                "end": {
                  "line": 198,
                  "column": 26
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","price.applied",["loc",[null,[192,36],[192,49]]]]],[],0,1,["loc",[null,[192,30],[196,37]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 188,
                "column": 22
              },
              "end": {
                "line": 200,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","price-line");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[190,49],[190,63]]]],["get","filterFavs",["loc",[null,[190,64],[190,74]]]]],[],["loc",[null,[190,32],[190,75]]]]],[],0,null,["loc",[null,[190,26],[198,33]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      var child16 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 207,
                      "column": 70
                    },
                    "end": {
                      "line": 207,
                      "column": 107
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("20");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 207,
                        "column": 107
                      },
                      "end": {
                        "line": 207,
                        "column": 172
                      }
                    },
                    "moduleName": "jason-frontend/templates/products/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","product.tax.percentage",["loc",[null,[207,146],[207,172]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 207,
                      "column": 107
                    },
                    "end": {
                      "line": 207,
                      "column": 172
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","is-operating-country",["DE"],[],["loc",[null,[207,117],[207,144]]]]],[],0,null,["loc",[null,[207,107],[207,172]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 207,
                    "column": 47
                  },
                  "end": {
                    "line": 207,
                    "column": 179
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[207,76],[207,103]]]]],[],0,1,["loc",[null,[207,70],[207,179]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 207,
                    "column": 179
                  },
                  "end": {
                    "line": 207,
                    "column": 213
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","product.tax.percentage",["loc",[null,[207,187],[207,213]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 204,
                  "column": 24
                },
                "end": {
                  "line": 209,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","tax");
              var el3 = dom.createTextNode("(");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" % Mwst)");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element4, 'class');
              morphs[1] = dom.createMorphAt(element4,1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["price-line price-value ",["subexpr","css-bool-evaluator",[["get","product.jasonPrice",["loc",[null,[205,82],[205,100]]]],"jason-price",""],[],["loc",[null,[205,61],[205,119]]]]]]],
              ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","product.priceExVat",["loc",[null,[206,73],[206,91]]]],["get","product.tax.percentage",["loc",[null,[206,92],[206,114]]]],["get","product.applied",["loc",[null,[206,115],[206,130]]]]],[],["loc",[null,[206,52],[206,131]]]]],["loc",[null,[206,28],[206,133]]]],
              ["block","if",[["get","product.applied",["loc",[null,[207,53],[207,68]]]]],[],0,1,["loc",[null,[207,47],[207,220]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 214,
                        "column": 70
                      },
                      "end": {
                        "line": 214,
                        "column": 107
                      }
                    },
                    "moduleName": "jason-frontend/templates/products/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("20");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 214,
                          "column": 107
                        },
                        "end": {
                          "line": 214,
                          "column": 172
                        }
                      },
                      "moduleName": "jason-frontend/templates/products/index.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","product.tax.percentage",["loc",[null,[214,146],[214,172]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 214,
                        "column": 107
                      },
                      "end": {
                        "line": 214,
                        "column": 172
                      }
                    },
                    "moduleName": "jason-frontend/templates/products/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","is-operating-country",["DE"],[],["loc",[null,[214,117],[214,144]]]]],[],0,null,["loc",[null,[214,107],[214,172]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 214,
                      "column": 49
                    },
                    "end": {
                      "line": 214,
                      "column": 179
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[214,76],[214,103]]]]],[],0,1,["loc",[null,[214,70],[214,179]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 214,
                      "column": 179
                    },
                    "end": {
                      "line": 214,
                      "column": 213
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","product.tax.percentage",["loc",[null,[214,187],[214,213]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 211,
                    "column": 26
                  },
                  "end": {
                    "line": 216,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","tax");
                var el3 = dom.createTextNode("(");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" % Mwst)");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element3, 'class');
                morphs[1] = dom.createMorphAt(element3,1,1);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["price-line price-value ",["subexpr","css-bool-evaluator",[["get","price.jasonPrice",["loc",[null,[212,84],[212,100]]]],"jason-price",""],[],["loc",[null,[212,63],[212,119]]]]]]],
                ["inline","format-currency",[],["value",["subexpr","get-inclusive-price",[["get","price.price",["loc",[null,[213,75],[213,86]]]],["get","product.tax.percentage",["loc",[null,[213,87],[213,109]]]],["get","price.applied",["loc",[null,[213,111],[213,124]]]]],[],["loc",[null,[213,54],[213,125]]]]],["loc",[null,[213,30],[213,127]]]],
                ["block","if",[["get","price.applied",["loc",[null,[214,55],[214,68]]]]],[],0,1,["loc",[null,[214,49],[214,220]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 210,
                  "column": 24
                },
                "end": {
                  "line": 217,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[211,49],[211,63]]]],["get","filterFavs",["loc",[null,[211,64],[211,74]]]]],[],["loc",[null,[211,32],[211,75]]]]],[],0,null,["loc",[null,[211,26],[216,33]]]]
            ],
            locals: ["price"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 203,
                "column": 22
              },
              "end": {
                "line": 218,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[204,47],[204,63]]]],["get","filterFavs",["loc",[null,[204,64],[204,74]]]]],[],["loc",[null,[204,30],[204,75]]]]],[],0,null,["loc",[null,[204,24],[209,31]]]],
            ["block","each",[["get","product.prices",["loc",[null,[210,32],[210,46]]]]],[],1,null,["loc",[null,[210,24],[217,33]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child17 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 223,
                    "column": 26
                  },
                  "end": {
                    "line": 227,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","price-line");
                var el2 = dom.createTextNode("\n                              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","product.quantitySelection",["loc",[null,[225,60],[225,85]]]]],[],[]],"id",["subexpr","@mut",[["get","product.id",["loc",[null,[225,89],[225,99]]]]],[],[]]],["loc",[null,[225,30],[225,101]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 231,
                        "column": 30
                      },
                      "end": {
                        "line": 235,
                        "column": 30
                      }
                    },
                    "moduleName": "jason-frontend/templates/products/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","price-line");
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","price.quantitySelection",["loc",[null,[233,64],[233,87]]]]],[],[]],"id",["subexpr","@mut",[["get","price.id",["loc",[null,[233,91],[233,99]]]]],[],[]]],["loc",[null,[233,34],[233,101]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 230,
                      "column": 28
                    },
                    "end": {
                      "line": 236,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[231,53],[231,67]]]],["get","filterFavs",["loc",[null,[231,68],[231,78]]]]],[],["loc",[null,[231,36],[231,79]]]]],[],0,null,["loc",[null,[231,30],[235,37]]]]
                ],
                locals: ["price"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 229,
                    "column": 26
                  },
                  "end": {
                    "line": 237,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","product.prices",["loc",[null,[230,36],[230,50]]]]],[],0,null,["loc",[null,[230,28],[236,37]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 222,
                  "column": 24
                },
                "end": {
                  "line": 238,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[223,49],[223,65]]]],["get","filterFavs",["loc",[null,[223,66],[223,76]]]]],[],["loc",[null,[223,32],[223,77]]]]],[],0,null,["loc",[null,[223,26],[227,33]]]],
              ["block","if",[["get","inclusiveVpes",["loc",[null,[229,32],[229,45]]]]],[],1,null,["loc",[null,[229,26],[237,33]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 238,
                  "column": 24
                },
                "end": {
                  "line": 242,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line hidden");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","product.quantitySelection",["loc",[null,[240,58],[240,83]]]]],[],[]],"id",["subexpr","@mut",[["get","product.id",["loc",[null,[240,87],[240,97]]]]],[],[]]],["loc",[null,[240,28],[240,99]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 249,
                      "column": 32
                    },
                    "end": {
                      "line": 251,
                      "column": 32
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("Hinzufügen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-incoming",[],["content","Hinzufügen","showStroke",false,"color","#fff","size","32"],["loc",[null,[250,34],[250,114]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 251,
                      "column": 32
                    },
                    "end": {
                      "line": 253,
                      "column": 32
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("Hinzufügen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-add-cart",[],["content","Hinzufügen","showStroke",false,"color","#fff","size","32"],["loc",[null,[252,34],[252,114]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 247,
                    "column": 28
                  },
                  "end": {
                    "line": 255,
                    "column": 28
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square exp");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element1);
                morphs[1] = dom.createMorphAt(element1,1,1);
                return morphs;
              },
              statements: [
                ["element","action",["addProduct",["get","product",["loc",[null,[248,119],[248,126]]]],["get","typeId",["loc",[null,[248,127],[248,133]]]],0,["get","product.name",["loc",[null,[248,136],[248,148]]]]],[],["loc",[null,[248,97],[248,150]]]],
                ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[249,42],[249,46]]]],"inventoryRequests"],[],["loc",[null,[249,38],[249,67]]]]],[],0,1,["loc",[null,[249,32],[253,39]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 245,
                  "column": 24
                },
                "end": {
                  "line": 257,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","price-line");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","if",[["get","product.tax",["loc",[null,[247,34],[247,45]]]]],[],0,null,["loc",[null,[247,28],[255,35]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 264,
                          "column": 36
                        },
                        "end": {
                          "line": 266,
                          "column": 36
                        }
                      },
                      "moduleName": "jason-frontend/templates/products/index.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createTextNode("Hinzufügen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-incoming",[],["content","Hinzufügen","showStroke",false,"color","#fff","size","32"],["loc",[null,[265,38],[265,118]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 266,
                          "column": 36
                        },
                        "end": {
                          "line": 268,
                          "column": 36
                        }
                      },
                      "moduleName": "jason-frontend/templates/products/index.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createTextNode("Hinzufügen");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-add-cart",[],["content","Hinzufügen","showStroke",false,"color","#fff","size","32"],["loc",[null,[267,38],[267,118]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 262,
                        "column": 32
                      },
                      "end": {
                        "line": 270,
                        "column": 32
                      }
                    },
                    "moduleName": "jason-frontend/templates/products/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square exp");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element0);
                    morphs[1] = dom.createMorphAt(element0,1,1);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["addProduct",["get","product",["loc",[null,[263,123],[263,130]]]],["get","typeId",["loc",[null,[263,131],[263,137]]]],["get","price.id",["loc",[null,[263,138],[263,146]]]],["get","product.name",["loc",[null,[263,147],[263,159]]]]],[],["loc",[null,[263,101],[263,161]]]],
                    ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[264,46],[264,50]]]],"inventoryRequests"],[],["loc",[null,[264,42],[264,71]]]]],[],0,1,["loc",[null,[264,36],[268,43]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 260,
                      "column": 28
                    },
                    "end": {
                      "line": 272,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","price-line");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","product.tax",["loc",[null,[262,38],[262,49]]]]],[],0,null,["loc",[null,[262,32],[270,39]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 259,
                    "column": 26
                  },
                  "end": {
                    "line": 273,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/products/index.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","filter-favorite",[["get","price.favorite",["loc",[null,[260,51],[260,65]]]],["get","filterFavs",["loc",[null,[260,66],[260,76]]]]],[],["loc",[null,[260,34],[260,77]]]]],[],0,null,["loc",[null,[260,28],[272,35]]]]
              ],
              locals: ["price"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 258,
                  "column": 24
                },
                "end": {
                  "line": 274,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/products/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","product.prices",["loc",[null,[259,34],[259,48]]]]],[],0,null,["loc",[null,[259,26],[273,35]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 220,
                "column": 20
              },
              "end": {
                "line": 276,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/products/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell text-center l-9/24 l-7/24@tablet l-3/24@desk table__cell--spinner permanent-caption");
            dom.setAttribute(el1,"data-label","Menge");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell l-2/24 l-2/24@desk table__cell--buttons button-1");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(element2,1,1);
            morphs[2] = dom.createMorphAt(element2,2,2);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[222,39],[222,43]]]],"inventoryRequests"],[],["loc",[null,[222,35],[222,64]]]]],[],["loc",[null,[222,30],[222,65]]]]],[],0,1,["loc",[null,[222,24],[242,31]]]],
            ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[245,47],[245,63]]]],["get","filterFavs",["loc",[null,[245,64],[245,74]]]]],[],["loc",[null,[245,30],[245,75]]]]],[],2,null,["loc",[null,[245,24],[257,31]]]],
            ["block","if",[["subexpr","and",[["get","inclusiveVpes",["loc",[null,[258,35],[258,48]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[258,58],[258,62]]]],"inventoryRequests"],[],["loc",[null,[258,54],[258,83]]]]],[],["loc",[null,[258,49],[258,84]]]]],[],["loc",[null,[258,30],[258,85]]]]],[],3,null,["loc",[null,[258,24],[274,31]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 8
            },
            "end": {
              "line": 282,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell u-hide@phone u-hide@tablet  l-6/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24 l-8/24@desk");
          dom.setAttribute(el2,"data-label","Bezeichnung");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-2/24 u-hide@tablet u-hide@phone u-hide@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-14/24  table__cell--center  u-text--left l-6/24@desk");
          dom.setAttribute(el2,"data-label","Tags");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 u-hide@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24 l-4/24@desk text-center table__cell--center");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-2/24@desk buttons-3");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 pb30 no-caption table__cell--products");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--sub");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__row");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"data-label","Favorit");
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"data-label","VPE");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"data-label","Anmerkung");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"data-label","VK brutto");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                  ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [3]);
          var element13 = dom.childAt(element12, [3]);
          var element14 = dom.childAt(element11, [13]);
          var element15 = dom.childAt(element14, [1]);
          var element16 = dom.childAt(element11, [15, 1, 1, 1]);
          var element17 = dom.childAt(element16, [1]);
          var element18 = dom.childAt(element17, [1]);
          var element19 = dom.childAt(element16, [3]);
          var element20 = dom.childAt(element16, [5]);
          var element21 = dom.childAt(element16, [7]);
          var morphs = new Array(22);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
          morphs[2] = dom.createMorphAt(element13,0,0);
          morphs[3] = dom.createMorphAt(element13,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(element12, [5]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element11, [7]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element11, [11]),1,1);
          morphs[7] = dom.createMorphAt(element15,1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
          morphs[9] = dom.createMorphAt(element14,3,3);
          morphs[10] = dom.createAttrMorph(element17, 'class');
          morphs[11] = dom.createMorphAt(element18,1,1);
          morphs[12] = dom.createMorphAt(element18,2,2);
          morphs[13] = dom.createAttrMorph(element19, 'class');
          morphs[14] = dom.createMorphAt(element19,1,1);
          morphs[15] = dom.createMorphAt(element19,2,2);
          morphs[16] = dom.createAttrMorph(element20, 'class');
          morphs[17] = dom.createMorphAt(element20,1,1);
          morphs[18] = dom.createMorphAt(element20,2,2);
          morphs[19] = dom.createAttrMorph(element21, 'class');
          morphs[20] = dom.createMorphAt(element21,1,1);
          morphs[21] = dom.createMorphAt(element16,9,9);
          return morphs;
        },
        statements: [
          ["block","if",[["get","product.picture",["loc",[null,[103,20],[103,35]]]]],[],0,1,["loc",[null,[103,14],[107,21]]]],
          ["block","link-to",["products.edit",["get","product.id",["loc",[null,[110,47],[110,57]]]]],[],2,null,["loc",[null,[110,20],[110,87]]]],
          ["block","if",[["get","product.partnerCategory",["loc",[null,[111,38],[111,61]]]]],[],3,null,["loc",[null,[111,32],[111,97]]]],
          ["block","if",[["get","product.partnerSubcategory",["loc",[null,[111,104],[111,130]]]]],[],4,null,["loc",[null,[111,98],[111,169]]]],
          ["content","product.category.name",["loc",[null,[112,32],[112,57]]]],
          ["block","if",[["get","product.tags",["loc",[null,[119,20],[119,32]]]]],[],5,6,["loc",[null,[119,14],[119,196]]]],
          ["block","if",[["subexpr","and",[["get","product.stockLevels",["loc",[null,[125,25],[125,44]]]],["subexpr","show-ehapo-stock",[["get","product",["loc",[null,[125,63],[125,70]]]]],[],["loc",[null,[125,45],[125,71]]]]],[],["loc",[null,[125,20],[125,72]]]]],[],7,8,["loc",[null,[125,14],[125,317]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","product",["loc",[null,[129,94],[129,101]]]]],[],[]],"confirmed","delete","text","Wollen Sie dieses Produkt wirklich löschen?"],["loc",[null,[129,16],[129,173]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","product",["loc",[null,[130,47],[130,54]]]]],[],[]],"confirmed","delete","textToShow","Löschen","text","Wollen Sie dieses Produkt wirklich löschen?"],["loc",[null,[130,22],[130,147]]]],
          ["block","link-to",["products.edit",["get","product",["loc",[null,[132,41],[132,48]]]]],["class","icon-button icon-button--default list-action-square"],9,null,["loc",[null,[132,14],[134,26]]]],
          ["attribute","class",["concat",["table__cell table__cell--center table__cell--favs l-3/24 l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[140,112],[140,126]]]],"2","4"],[],["loc",[null,[140,91],[140,136]]]],"/24@tablet l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[140,170],[140,184]]]],"2","4"],[],["loc",[null,[140,149],[140,194]]]],"/24@desk permanent-caption"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[142,49],[142,65]]]],["get","filterFavs",["loc",[null,[142,66],[142,76]]]]],[],["loc",[null,[142,32],[142,77]]]]],[],10,null,["loc",[null,[142,26],[148,33]]]],
          ["block","each",[["get","product.prices",["loc",[null,[149,34],[149,48]]]]],[],11,null,["loc",[null,[149,26],[157,35]]]],
          ["attribute","class",["concat",["table__cell table__cell--center l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[160,87],[160,101]]]],"4","5"],[],["loc",[null,[160,66],[160,111]]]],"/24 l-5/24@desk text-center permanent-caption"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[161,45],[161,61]]]],["get","filterFavs",["loc",[null,[161,62],[161,72]]]]],[],["loc",[null,[161,28],[161,73]]]]],[],12,null,["loc",[null,[161,22],[166,29]]]],
          ["block","each",[["get","product.prices",["loc",[null,[167,30],[167,44]]]]],[],13,null,["loc",[null,[167,22],[174,31]]]],
          ["attribute","class",["concat",["table__cell text-center ",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[176,77],[176,91]]]],"u-hide@phone","l-10/24@phone"],[],["loc",[null,[176,56],[176,124]]]]," l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[176,148],[176,162]]]],"4","10"],[],["loc",[null,[176,127],[176,173]]]],"/24@tablet l-3/24@desk permanent-caption"]]],
          ["block","if",[["subexpr","filter-favorite",[["get","product.favorite",["loc",[null,[177,45],[177,61]]]],["get","filterFavs",["loc",[null,[177,62],[177,72]]]]],[],["loc",[null,[177,28],[177,73]]]]],[],14,null,["loc",[null,[177,22],[187,29]]]],
          ["block","each",[["get","product.prices",["loc",[null,[188,30],[188,44]]]]],[],15,null,["loc",[null,[188,22],[200,31]]]],
          ["attribute","class",["concat",["table__cell text-center l-6/24 pr0 l-4/24@tablet l-",["subexpr","css-bool-evaluator",[["get","showCartButton",["loc",[null,[202,104],[202,118]]]],"4","6"],[],["loc",[null,[202,83],[202,128]]]],"/24@desk permanent-caption"]]],
          ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[203,28],[203,41]]]]],[],16,null,["loc",[null,[203,22],[218,29]]]],
          ["block","if",[["get","showCartButton",["loc",[null,[220,26],[220,40]]]]],[],17,null,["loc",[null,[220,20],[276,27]]]]
        ],
        locals: ["product"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 291,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/products/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Meine Produkte");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","rework-content card site-content product-list add-product add-sale-product");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter box@phone");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 l-10/24@desk");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon");
        dom.setAttribute(el5,"for","filterAll");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","selectable-item has-icon favs");
        dom.setAttribute(el5,"for","filterFavs");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          Favoriten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col l-24/24 l-14/24@desk");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Tags");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table grid table--large table--with-sub");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element24 = dom.childAt(fragment, [0]);
        var element25 = dom.childAt(element24, [3]);
        var element26 = dom.childAt(element25, [1]);
        var element27 = dom.childAt(fragment, [2, 1]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(element27, [3]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element24, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element24, [1, 3]),1,1);
        morphs[2] = dom.createMorphAt(element26,1,1);
        morphs[3] = dom.createMorphAt(element26,3,3);
        morphs[4] = dom.createMorphAt(element25,3,3);
        morphs[5] = dom.createMorphAt(element29,3,3);
        morphs[6] = dom.createMorphAt(element29,7,7);
        morphs[7] = dom.createMorphAt(dom.childAt(element29, [9]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element28, [3]),3,3);
        morphs[9] = dom.createMorphAt(element30,1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element30, [3]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element27, [5]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["block","link-to",["products.create",["subexpr","query-params",[],["referer","sale"],["loc",[null,[5,35],[5,64]]]]],["class","button-rework context primary"],0,null,["loc",[null,[5,6],[7,18]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[13,30],[13,40]]]]],[],[]],"placeHolder","Produkt suchen ...","search","applyFilter"],["loc",[null,[13,6],[13,96]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[14,31],[14,42]]]]],[],[]]],["loc",[null,[14,6],[14,44]]]],
        ["block","if",[["get","showFilters",["loc",[null,[16,10],[16,21]]]]],[],1,null,["loc",[null,[16,4],[59,11]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[69,72],[69,81]]]]],[],[]]],["loc",[null,[69,8],[69,83]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[71,74],[71,84]]]]],[],[]]],["loc",[null,[71,8],[71,86]]]],
        ["inline","button-favorite",[],["content","","size",18,"showStroke",false,"color","#999999","classNames",""],["loc",[null,[72,70],[74,103]]]],
        ["block","if",[["get","articleTags",["loc",[null,[79,14],[79,25]]]]],[],2,null,["loc",[null,[79,8],[85,15]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[90,34],[90,58]]]]],[],[]]],3,null,["loc",[null,[90,6],[98,27]]]],
        ["block","each",[["get","model",["loc",[null,[100,16],[100,21]]]]],[],4,null,["loc",[null,[100,8],[282,17]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[287,29],[287,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[287,6],[287,55]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});