define('jason-frontend/templates/cash/cancel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 30,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","div-horizontal");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-lane grid");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Kassa");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","cashRegisterList",["loc",[null,[21,20],[21,36]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCashRegister",["loc",[null,[22,18],[22,38]]]]],[],[]],"optionLabelPath","name","allowClear",false,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[20,10],[27,12]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","search-container card-rework ");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","main-lane");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element8,1,1);
          morphs[1] = dom.createMorphAt(element8,3,3);
          morphs[2] = dom.createMorphAt(element7,3,3);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterSlipId",["loc",[null,[11,30],[11,42]]]]],[],[]],"placeHolder","Belegnummer-Nr suchen ...","search","applyFilter"],["loc",[null,[11,6],[11,105]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[12,31],[12,42]]]]],[],[]]],["loc",[null,[12,6],[12,44]]]],
          ["block","if",[["get","showFilters",["loc",[null,[14,10],[14,21]]]]],[],0,null,["loc",[null,[14,4],[30,11]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 10
            },
            "end": {
              "line": 48,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#all");
          var el3 = dom.createTextNode("Eingang");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#all");
          var el3 = dom.createTextNode("Ausgang");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1]);
          var element6 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createElementMorph(element5);
          morphs[2] = dom.createAttrMorph(element6, 'class');
          morphs[3] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[43,80],[43,92]]]],"in"],[],["loc",[null,[43,76],[43,98]]]],"is-active",""],[],["loc",[null,[43,55],[43,115]]]]]]],
          ["element","action",["setType","in"],[],["loc",[null,[43,130],[43,155]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[46,78],[46,90]]]],"out"],[],["loc",[null,[46,74],[46,97]]]],"is-active",""],[],["loc",[null,[46,53],[46,114]]]]]]],
          ["element","action",["setType","out"],[],["loc",[null,[46,130],[46,156]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 73
            },
            "end": {
              "line": 61,
              "column": 124
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Überweisung");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 124
            },
            "end": {
              "line": 61,
              "column": 137
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Beleg");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 20
                },
                "end": {
                  "line": 76,
                  "column": 97
                }
              },
              "moduleName": "jason-frontend/templates/cash/cancel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("#");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","transaction.transactionId",["loc",[null,[76,68],[76,97]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 18
              },
              "end": {
                "line": 77,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","link-to",["transactions.show",["get","transaction.id",["loc",[null,[76,51],[76,65]]]]],[],0,null,["loc",[null,[76,20],[76,109]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 20
                },
                "end": {
                  "line": 78,
                  "column": 108
                }
              },
              "moduleName": "jason-frontend/templates/cash/cancel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("UE-");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","transaction.banktransferId",["loc",[null,[78,78],[78,108]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 18
              },
              "end": {
                "line": 79,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","link-to",["invoices.showbanktransfer",["get","transaction.id",["loc",[null,[78,59],[78,73]]]]],[],0,null,["loc",[null,[78,20],[78,120]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 21
              },
              "end": {
                "line": 86,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                / ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            return morphs;
          },
          statements: [
            ["content","transaction.transactionType.name",["loc",[null,[84,16],[84,52]]]],
            ["content","transaction.transactionSubType.name",["loc",[null,[85,18],[85,57]]]],
            ["inline","get-cancellation-info",[["get","transaction",["loc",[null,[85,82],[85,93]]]]],[],["loc",[null,[85,58],[85,95]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 14
              },
              "end": {
                "line": 88,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["content","transaction.transactionType.name",["loc",[null,[87,16],[87,52]]]],
            ["inline","get-cancellation-info",[["get","transaction",["loc",[null,[87,77],[87,88]]]]],[],["loc",[null,[87,53],[87,90]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 20
              },
              "end": {
                "line": 91,
                "column": 96
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","transaction.customer",["loc",[null,[91,72],[91,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 14
              },
              "end": {
                "line": 101,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/cancel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/select.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element3);
            return morphs;
          },
          statements: [
            ["element","action",["selectTransaction",["get","transaction",["loc",[null,[98,48],[98,59]]]]],[],["loc",[null,[98,19],[98,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 8
            },
            "end": {
              "line": 104,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-22/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-22/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Beleg");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-24/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Art");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Kund:in");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right@desk l-24/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Betrag");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk buttons-1");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element4, [9, 1]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [11]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","transaction.created",["loc",[null,[71,34],[71,53]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[71,20],[71,76]]]],
          ["block","if",[["get","transaction.cashdeskProcessed",["loc",[null,[75,24],[75,53]]]]],[],0,1,["loc",[null,[75,18],[79,25]]]],
          ["block","if",[["get","transaction.cashdeskProcessed",["loc",[null,[83,27],[83,56]]]]],[],2,3,["loc",[null,[83,21],[88,21]]]],
          ["block","link-to",["customers.edit",["get","transaction.customerId",["loc",[null,[91,48],[91,70]]]]],[],4,null,["loc",[null,[91,20],[91,108]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.total",["loc",[null,[94,45],[94,62]]]]],[],[]]],["loc",[null,[94,21],[94,64]]]],
          ["block","if",[["subexpr","is-cancelation-allowed",[["get","transaction",["loc",[null,[97,44],[97,55]]]]],[],["loc",[null,[97,20],[97,56]]]]],[],5,null,["loc",[null,[97,14],[101,21]]]]
        ],
        locals: ["transaction"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 138,
              "column": 10
            },
            "end": {
              "line": 140,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-next",[],["size","52","color","#ffffff"],["loc",[null,[139,77],[139,118]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 162,
              "column": 6
            },
            "end": {
              "line": 172,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/cancel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createMorphAt(element1,1,1);
          morphs[4] = dom.createElementMorph(element2);
          morphs[5] = dom.createMorphAt(element2,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showSlip"],[],["loc",[null,[163,13],[163,34]]]],
          ["inline","button-view",[],["color","#ffffff","size","52","content","Bon anzeigen","showStroke",false],["loc",[null,[164,12],[164,93]]]],
          ["element","action",["download",["get","transactionDownloadUrl",["loc",[null,[166,33],[166,55]]]]],[],["loc",[null,[166,13],[166,57]]]],
          ["inline","button-download",[],["color","#ffffff","size","52","content","Bon herunterladen"],["loc",[null,[167,12],[167,85]]]],
          ["element","action",["print",["get","transactionGroup",["loc",[null,[169,30],[169,46]]]]],[],["loc",[null,[169,13],[169,48]]]],
          ["inline","button-print",[],["color","#ffffff","size","52","content","Bon Druck"],["loc",[null,[170,12],[170,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 206,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/cancel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Stornierung");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box col l-24/24@tablet");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","js-tabs tabs");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tabs__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","u-mb0 clearfix");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#all");
        var el8 = dom.createTextNode("Überweisung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table box no-box@phone grid table--large");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-4/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Datum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-5/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Art");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-6/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Kund:in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24 table__cell--right ");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Betrag");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("Stornierung folgender Transaktion");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"id","summaryForm");
        dom.setAttribute(el2,"class","u-mb0");
        dom.setAttribute(el2,"method","post");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box col l-12/24@tablet");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box col l-12/24@tablet");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","field");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","textarea textarea--inline clearfix");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","title");
        var el7 = dom.createTextNode("Stornierungsgrund");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","textarea__body");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","action-icons ");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","icon-button icon-button--small");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("Transaktion erfolgreich durchgeführt");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 box");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n\n        $(\"#summaryForm\").validate({\n            errorClass: \"state-error\",\n            validClass: \"state-success\",\n            errorElement: \"em\",\n            rules: {\n                'reason': {\n                    required: true,\n                    minlength: 3\n                }\n            },\n            highlight: function(element, errorClass, validClass) {\n                $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n            },\n            unhighlight: function(element, errorClass, validClass) {\n                $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n            },\n            errorPlacement: function(error, element) {\n                if (element.is(\":radio\") || element.is(\":checkbox\")) {\n                    element.closest('.option-group').after(error);\n                } else {\n                    error.insertAfter(element.parent());\n                }\n            }\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(fragment, [2]);
        var element11 = dom.childAt(element10, [1, 1, 1, 1]);
        var element12 = dom.childAt(element11, [3, 1]);
        var element13 = dom.childAt(element10, [3]);
        var element14 = dom.childAt(element13, [1]);
        var element15 = dom.childAt(fragment, [4]);
        var element16 = dom.childAt(element15, [3]);
        var element17 = dom.childAt(element16, [4, 1, 1, 3]);
        var element18 = dom.childAt(element16, [6]);
        var element19 = dom.childAt(element18, [1]);
        var element20 = dom.childAt(fragment, [6]);
        var morphs = new Array(20);
        morphs[0] = dom.createAttrMorph(element9, 'class');
        morphs[1] = dom.createMorphAt(element9,3,3);
        morphs[2] = dom.createAttrMorph(element10, 'class');
        morphs[3] = dom.createMorphAt(element11,1,1);
        morphs[4] = dom.createAttrMorph(element12, 'class');
        morphs[5] = dom.createElementMorph(element12);
        morphs[6] = dom.createMorphAt(dom.childAt(element14, [1, 3, 0]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element13, [3]),1,1);
        morphs[9] = dom.createAttrMorph(element15, 'class');
        morphs[10] = dom.createElementMorph(element16);
        morphs[11] = dom.createMorphAt(element17,1,1);
        morphs[12] = dom.createMorphAt(element17,3,3);
        morphs[13] = dom.createElementMorph(element19);
        morphs[14] = dom.createMorphAt(element19,1,1);
        morphs[15] = dom.createMorphAt(element18,3,3);
        morphs[16] = dom.createAttrMorph(element20, 'class');
        morphs[17] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element20, [5]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element20, [7]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["block","if",[["subexpr","eq",[["get","styleStepSelect",["loc",[null,[8,10],[8,25]]]],""],[],["loc",[null,[8,6],[8,29]]]]],[],0,null,["loc",[null,[8,0],[32,7]]]],
        ["attribute","class",["concat",["rework-content site-content card ",["get","styleStepSelect",["loc",[null,[35,51],[35,66]]]]," with-context-bar"]]],
        ["block","if",[["subexpr","show-cashdesk-action",[],[],["loc",[null,[41,16],[41,38]]]]],[],1,null,["loc",[null,[41,10],[48,15]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[50,78],[50,90]]]],"banktransfer"],[],["loc",[null,[50,74],[50,106]]]],"is-active",""],[],["loc",[null,[50,53],[50,123]]]]]]],
        ["element","action",["setType","banktransfer"],[],["loc",[null,[50,138],[50,173]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[61,83],[61,95]]]],"banktransfer"],[],["loc",[null,[61,79],[61,111]]]]],[],2,3,["loc",[null,[61,73],[61,144]]]],
        ["block","each",[["get","model",["loc",[null,[68,16],[68,21]]]]],[],4,null,["loc",[null,[68,8],[104,17]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[108,29],[108,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[108,6],[108,55]]]],
        ["attribute","class",["concat",["site-content card clearfix ",["get","styleStepSummary",["loc",[null,[113,45],[113,61]]]]]]],
        ["element","action",["doCancelation"],["on","submit"],["loc",[null,[117,55],[117,93]]]],
        ["inline","icon-bubble",[],["color","#ADCCD4","width","20","class","textarea__icon"],["loc",[null,[128,14],[128,79]]]],
        ["inline","textarea",[],["rows","3","name","reason","value",["subexpr","@mut",[["get","reason",["loc",[null,[129,54],[129,60]]]]],[],[]],"class","input textarea__input","placeholder","Geben Sie den Grund für die Stornierung an"],["loc",[null,[129,14],[129,149]]]],
        ["element","action",["toSelectType"],[],["loc",[null,[135,15],[135,40]]]],
        ["inline","button-prev",[],["color","#ffffff","size","44"],["loc",[null,[136,14],[136,55]]]],
        ["block","if",[["get","submitButtonEnabled",["loc",[null,[138,16],[138,35]]]]],[],5,null,["loc",[null,[138,10],[140,17]]]],
        ["attribute","class",["concat",["site-content card invoice ",["get","styleStepConfirmation",["loc",[null,[149,44],[149,65]]]]]]],
        ["inline","transaction-group-confirm",[],["transactionGroup",["subexpr","@mut",[["get","transactionGroup",["loc",[null,[155,51],[155,67]]]]],[],[]],"showLoading",["subexpr","@mut",[["get","showLoading",["loc",[null,[155,80],[155,91]]]]],[],[]]],["loc",[null,[155,6],[155,93]]]],
        ["inline","pdf-preview",[],["url",["subexpr","@mut",[["get","transactionDownloadUrl",["loc",[null,[158,22],[158,44]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","pdfPreview",["loc",[null,[158,60],[158,70]]]]],[],[]]],["loc",[null,[158,4],[158,72]]]],
        ["block","if",[["get","transactionGroup.cashdeskProcessed",["loc",[null,[162,12],[162,46]]]]],[],6,null,["loc",[null,[162,6],[172,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});