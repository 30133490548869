define('jason-frontend/templates/customers/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 4
            },
            "end": {
              "line": 76,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","div-horizontal");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-lane grid");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Tiername");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/search.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Chip-Nr / Heimtiernummer");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/search.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Adresse, PLZ, Ort");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/search.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Telefon, Mobil, E-Mail");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/search.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [5]);
          var element3 = dom.childAt(element0, [3]);
          var element4 = dom.childAt(element3, [5]);
          var element5 = dom.childAt(element0, [5]);
          var element6 = dom.childAt(element5, [5]);
          var element7 = dom.childAt(element0, [7]);
          var element8 = dom.childAt(element7, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(element1,1,1);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element3,1,1);
          morphs[3] = dom.createElementMorph(element4);
          morphs[4] = dom.createMorphAt(element5,1,1);
          morphs[5] = dom.createElementMorph(element6);
          morphs[6] = dom.createMorphAt(element7,1,1);
          morphs[7] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterPatient",["loc",[null,[44,48],[44,61]]]]],[],[]],"class","rework","placeholder","","name","search-patient","autocomplete","off"],["loc",[null,[44,12],[45,40]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[47,20],[47,46]]]],
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterChip",["loc",[null,[52,46],[52,56]]]]],[],[]],"class","rework","name","filterChip","placeholder","","autocomplete","off"],["loc",[null,[52,10],[53,38]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[55,18],[55,44]]]],
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterAddress",["loc",[null,[60,46],[60,59]]]]],[],[]],"class","","name","filterAddress","placeholder","","autocomplete","off"],["loc",[null,[60,10],[61,38]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[63,18],[63,44]]]],
          ["inline","input",[],["enter","triggerSearch","value",["subexpr","@mut",[["get","filterContact",["loc",[null,[68,46],[68,59]]]]],[],[]],"class","rework","name","filterContact","placeholder","","autocomplete","off"],["loc",[null,[68,10],[69,38]]]],
          ["element","action",["triggerSearch"],[],["loc",[null,[71,18],[71,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 152,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Kunden & Tiere");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dropdown");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","button-rework context dropdown");
        dom.setAttribute(el5,"data-toggle","dropdown");
        dom.setAttribute(el5,"aria-haspopup","true");
        dom.setAttribute(el5,"aria-expanded","false");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/download.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Export");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"class","dropdown-icon");
        dom.setAttribute(el6,"src","assets/images/icons/rework/dropdown.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dropdown-menu");
        dom.setAttribute(el5,"aria-labelledby","dropdownMenuButton");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("form");
        dom.setAttribute(el6,"id","customersVcardExportForm");
        dom.setAttribute(el6,"method","post");
        dom.setAttribute(el6,"action","api/customers/exportVcard");
        dom.setAttribute(el6,"target","_blank");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("input");
        dom.setAttribute(el7,"type","hidden");
        dom.setAttribute(el7,"name","mandant_id");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("input");
        dom.setAttribute(el7,"type","hidden");
        dom.setAttribute(el7,"name","token");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","dropdown-item");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("vCard herunterladen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("form");
        dom.setAttribute(el6,"id","customersExportForm");
        dom.setAttribute(el6,"method","post");
        dom.setAttribute(el6,"action","api/customers/export");
        dom.setAttribute(el6,"target","_blank");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("input");
        dom.setAttribute(el7,"type","hidden");
        dom.setAttribute(el7,"name","mandant_id");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("input");
        dom.setAttribute(el7,"type","hidden");
        dom.setAttribute(el7,"name","token");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","dropdown-item");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("CSV herunterladen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Neuer Kunde");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ml10 cursor u-hide@phone");
        var el5 = dom.createTextNode("Suche zurücksetzen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3,"src","assets/images/icons/rework/nav-back.svg");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $(\"#addCustomerForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'firstname': {\n          required: true\n        },\n        'lastname': {\n          required: true\n        },\n        'email': {\n          email: true\n        },\n        'phone': {\n          pattern: /^\\+?[\\s\\d]+$/\n        }\n      },\n      highlight: function (element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function (element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function (error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1, 3]);
        var element11 = dom.childAt(element10, [1, 3]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [5]);
        var element15 = dom.childAt(element11, [3]);
        var element16 = dom.childAt(element15, [1]);
        var element17 = dom.childAt(element15, [5]);
        var element18 = dom.childAt(element10, [3]);
        var element19 = dom.childAt(element9, [3]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element20, [5]);
        var morphs = new Array(14);
        morphs[0] = dom.createAttrMorph(element9, 'class');
        morphs[1] = dom.createAttrMorph(element13, 'value');
        morphs[2] = dom.createElementMorph(element14);
        morphs[3] = dom.createAttrMorph(element16, 'value');
        morphs[4] = dom.createElementMorph(element17);
        morphs[5] = dom.createElementMorph(element18);
        morphs[6] = dom.createMorphAt(element20,1,1);
        morphs[7] = dom.createMorphAt(element20,3,3);
        morphs[8] = dom.createElementMorph(element21);
        morphs[9] = dom.createMorphAt(element19,3,3);
        morphs[10] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        morphs[11] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[12] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[13] = dom.createMorphAt(fragment,10,10,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[12,60],[12,69]]]]]]],
        ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[14,42],[14,91]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[19,60],[19,69]]]]]]],
        ["element","action",["checkForm","customersExportForm"],[],["loc",[null,[21,42],[21,86]]]],
        ["element","action",["openCreateCustomer",["get","invoiceId",["loc",[null,[27,39],[27,48]]]]],[],["loc",[null,[27,9],[27,50]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[35,30],[35,40]]]]],[],[]],"placeHolder","Kunde suchen","search","applyFilter"],["loc",[null,[35,6],[35,90]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[36,31],[36,42]]]]],[],[]]],["loc",[null,[36,6],[36,44]]]],
        ["element","action",["resetSearch"],[],["loc",[null,[37,38],[37,62]]]],
        ["block","if",[["get","showFilters",["loc",[null,[39,10],[39,21]]]]],[],0,null,["loc",[null,[39,4],[76,11]]]],
        ["inline","customer-searchform",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[81,36],[81,47]]]]],[],[]],"showStickyTableHeader",true,"showSearch",false,"model",["subexpr","@mut",[["get","model",["loc",[null,[81,98],[81,103]]]]],[],[]],"filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[81,115],[81,125]]]]],[],[]],"reference","admission","content",["subexpr","@mut",[["get","content",["loc",[null,[81,156],[81,163]]]]],[],[]],"showCashSaleButton",false,"showStatus",true,"showCrud",true,"applyFilter","applyFilter","openScanEtigaQr","openScanEtigaQr","gotoPatient","gotoPatient","delete","delete","addPatient","addPatient","selectCustomer","selectCustomer"],["loc",[null,[81,2],[84,97]]]],
        ["inline","create-customer-panel",[],["gendersAll",["subexpr","@mut",[["get","gendersAll",["loc",[null,[94,35],[94,45]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[94,64],[94,81]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[94,97],[94,111]]]]],[],[]],"toTreatment","toTreatment","gotoDetails","gotoDetails","gotoPatient","gotoPatient","toWaitgotoPatientingRoom","toWaitingRoom","create","create","refresh","applyFilter","actionReceiver",["subexpr","@mut",[["get","createCustomerPanel",["loc",[null,[101,39],[101,58]]]]],[],[]]],["loc",[null,[94,0],[101,60]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[103,41],[103,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[103,74],[103,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[103,134],[103,152]]]]],[],[]]],["loc",[null,[103,0],[103,154]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[108,8],[108,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[109,10],[109,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[110,13],[110,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[111,17],[111,36]]]]],[],[]]],["loc",[null,[104,0],[111,38]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});