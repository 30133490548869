define('jason-frontend/templates/customers/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context mr10");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Kund:in zusammenführen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1,"id","customersVcardExportForm");
          dom.setAttribute(el1,"method","post");
          dom.setAttribute(el1,"action","api/customers/exportVcard");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","mandant_id");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","token");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","filterId");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","button-rework context");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("vCard herunterladen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context ml10");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Umsatzauswertung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var element25 = dom.childAt(fragment, [3]);
          var element26 = dom.childAt(element25, [1]);
          var element27 = dom.childAt(element25, [5]);
          var element28 = dom.childAt(element25, [7]);
          var element29 = dom.childAt(fragment, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element24);
          morphs[1] = dom.createMorphAt(element24,1,1);
          morphs[2] = dom.createAttrMorph(element26, 'value');
          morphs[3] = dom.createAttrMorph(element27, 'value');
          morphs[4] = dom.createElementMorph(element28);
          morphs[5] = dom.createMorphAt(element28,1,1);
          morphs[6] = dom.createElementMorph(element29);
          morphs[7] = dom.createMorphAt(element29,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openMergeCustomer"],[],["loc",[null,[6,11],[6,41]]]],
          ["inline","button-merge",[],["content","","color","#FFF","size","16"],["loc",[null,[7,10],[7,60]]]],
          ["attribute","value",["concat",[["get","mandantId",["loc",[null,[11,58],[11,67]]]]]]],
          ["attribute","value",["concat",[["get","model.id",["loc",[null,[13,56],[13,64]]]]]]],
          ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[14,48],[14,97]]]],
          ["inline","button-download2",[],["content","vCard herunterladen","color","#FFF","size","16"],["loc",[null,[15,12],[15,85]]]],
          ["element","action",["openRevenue",["get","model.id",["loc",[null,[19,34],[19,42]]]]],[],["loc",[null,[19,11],[19,44]]]],
          ["inline","icon-cash",[],["color","#fff","size","20","showStroke",false],["loc",[null,[20,10],[20,63]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 29,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Ordnerinhalt herunterladen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element22);
            morphs[1] = dom.createMorphAt(element22,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["downloadFolder"],[],["loc",[null,[25,13],[25,40]]]],
            ["inline","button-download2",[],["content","vCard herunterladen","color","#FFF","size","16"],["loc",[null,[26,12],[26,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element23 = dom.childAt(fragment, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element23);
          morphs[2] = dom.createMorphAt(element23,1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","filterName",["loc",[null,[24,19],[24,29]]]]],[],["loc",[null,[24,14],[24,30]]]]],[],0,null,["loc",[null,[24,8],[29,15]]]],
          ["element","action",["openDocumentUpload"],[],["loc",[null,[30,11],[30,42]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[31,10],[31,71]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 6
            },
            "end": {
              "line": 38,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Tier");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element21);
          return morphs;
        },
        statements: [
          ["element","action",["showCreatePatient"],[],["loc",[null,[35,11],[35,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 6
            },
            "end": {
              "line": 43,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","");
          dom.setAttribute(el1,"button-rework","");
          dom.setAttribute(el1,"context","");
          dom.setAttribute(el1,"primary\"","");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAppointment"],[],["loc",[null,[40,11],[40,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 8
              },
              "end": {
                "line": 54,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context primary");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Guthaben ausbezahlen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element18);
            morphs[1] = dom.createMorphAt(element18,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["payoutDeposit",["get","model",["loc",[null,[50,38],[50,43]]]]],[],["loc",[null,[50,13],[50,45]]]],
            ["inline","button-cash",[],["color","#fff","size","32","showStroke",false],["loc",[null,[51,12],[51,67]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 6
            },
            "end": {
              "line": 55,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework");
          dom.setAttribute(el1,"context","");
          dom.setAttribute(el1,"\"","");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldoübersicht drucken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element19);
          morphs[1] = dom.createMorphAt(element19,1,1);
          morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["print",["get","model",["loc",[null,[45,28],[45,33]]]]],[],["loc",[null,[45,11],[45,35]]]],
          ["inline","button-print",[],["color","#fff","size","32","showStroke",false],["loc",[null,[46,10],[46,66]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[49,18],[49,28]]]],0],[],["loc",[null,[49,14],[49,31]]]]],[],0,null,["loc",[null,[49,8],[54,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 6
            },
            "end": {
              "line": 60,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element17);
          morphs[1] = dom.createMorphAt(element17,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showCreateConsent"],[],["loc",[null,[57,11],[57,41]]]],
          ["inline","button-fileupload",[],["color","#fff","size","16","showStroke",false],["loc",[null,[58,10],[58,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 0
            },
            "end": {
              "line": 68,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[66,26],[66,36]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[66,2],[66,92]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[67,27],[67,38]]]]],[],[]]],["loc",[null,[67,2],[67,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 60
            },
            "end": {
              "line": 71,
              "column": 204
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-ical",[],["size",48,"classNames","inline-block vertical-bottom","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[71,91],[71,204]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 204
              },
              "end": {
                "line": 71,
                "column": 362
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-ical",[],["size",48,"classNames","inline-block vertical-bottom","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[71,240],[71,362]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 204
            },
            "end": {
              "line": 71,
              "column": 362
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.etigaCodeRequested",["loc",[null,[71,214],[71,238]]]]],[],0,null,["loc",[null,[71,204],[71,362]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 6
              },
              "end": {
                "line": 77,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","clear title title--big");
            var el2 = dom.createTextNode("Guthaben: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[76,84],[76,94]]]]],[],[]]],["loc",[null,[76,60],[76,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 2
            },
            "end": {
              "line": 79,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","invoice__saldo");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldo: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var element16 = dom.childAt(element15, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element16, 'class');
          morphs[1] = dom.createMorphAt(element16,1,1);
          morphs[2] = dom.createMorphAt(element15,3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["title title--big ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[74,63],[74,73]]]],0],[],["loc",[null,[74,59],[74,76]]]],"is-negative",""],[],["loc",[null,[74,38],[74,95]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[74,128],[74,138]]]]],[],[]]],["loc",[null,[74,104],[74,140]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[75,16],[75,26]]]],0],[],["loc",[null,[75,12],[75,29]]]]],[],0,null,["loc",[null,[75,6],[77,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 87,
              "column": 14
            },
            "end": {
              "line": 98,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-accounting");
          var el3 = dom.createTextNode("Verrechnung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-warning");
          var el3 = dom.createTextNode("Mahnwesen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","js-tabs__label tabs__label title");
          dom.setAttribute(el2,"href","#tab-saldo");
          var el3 = dom.createTextNode("Saldo\n                        Informationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1, 1]);
          var element13 = dom.childAt(fragment, [3, 1]);
          var element14 = dom.childAt(fragment, [5, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element12, 'class');
          morphs[1] = dom.createElementMorph(element12);
          morphs[2] = dom.createAttrMorph(element13, 'class');
          morphs[3] = dom.createElementMorph(element13);
          morphs[4] = dom.createElementMorph(element14);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[89,88],[89,97]]]],"accounting"],[],["loc",[null,[89,84],[89,111]]]],"is-active",""],[],["loc",[null,[89,63],[89,128]]]]]]],
          ["element","action",["selectTab","accounting"],[],["loc",[null,[89,153],[89,188]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[92,88],[92,97]]]],"warning"],[],["loc",[null,[92,84],[92,108]]]],"is-active",""],[],["loc",[null,[92,63],[92,125]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[92,147],[92,179]]]],
          ["element","action",["selectTab","saldo"],[],["loc",[null,[95,82],[95,112]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 0
            },
            "end": {
              "line": 112,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-appointments");
          var el3 = dom.createTextNode("Termine");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element11, 'class');
          morphs[1] = dom.createElementMorph(element11);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[110,84],[110,93]]]],"appointments"],[],["loc",[null,[110,80],[110,109]]]],"is-active",""],[],["loc",[null,[110,59],[110,126]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[110,153],[110,190]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 281,
                "column": 28
              },
              "end": {
                "line": 293,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field-label");
            var el2 = dom.createTextNode("Produktkategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[284,41],[284,53]]]]],["content",["subexpr","@mut",[["get","productCategories",["loc",[null,[285,42],[285,59]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","reduction.productCategory",["loc",[null,[288,44],[288,69]]]]],[],[]],"prompt","Alle"],["loc",[null,[284,34],[290,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 294,
                "column": 28
              },
              "end": {
                "line": 306,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field-label");
            var el2 = dom.createTextNode("Leistungskategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-category");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[297,41],[297,53]]]]],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[298,42],[298,59]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","reduction.serviceCategory",["loc",[null,[301,44],[301,69]]]]],[],[]],"prompt","Alle"],["loc",[null,[297,34],[303,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 317,
                "column": 28
              },
              "end": {
                "line": 321,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createMorphAt(element8,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addReduction",["get","reduction",["loc",[null,[318,55],[318,64]]]]],[],["loc",[null,[318,31],[318,66]]]],
            ["inline","button-add-list",[],["size","32","color","#fff","showStroke",false],["loc",[null,[319,30],[319,89]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 322,
                "column": 28
              },
              "end": {
                "line": 326,
                "column": 28
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element7);
            morphs[1] = dom.createMorphAt(element7,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["removeReduction",["get","reduction",["loc",[null,[323,62],[323,71]]]]],[],["loc",[null,[323,35],[323,73]]]],
            ["inline","button-delete-list",[],["size","32","color","#fff","showStroke",false],["loc",[null,[324,34],[324,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 248,
              "column": 18
            },
            "end": {
              "line": 329,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Patient(en)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-title");
          dom.setAttribute(el3,"class","field select");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Typ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-title");
          dom.setAttribute(el3,"class","field select");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","arrow");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-5/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-4/24 gutter");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field");
          var el3 = dom.createTextNode("\n                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-phone");
          dom.setAttribute(el3,"class","field-label");
          var el4 = dom.createTextNode("Rabatt in %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-2/24");
          var el2 = dom.createTextNode("\n                          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","line-action-buttons");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [5, 1]);
          var element10 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 3]),1,1);
          morphs[2] = dom.createMorphAt(element9,1,1);
          morphs[3] = dom.createMorphAt(element9,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),3,3);
          morphs[5] = dom.createMorphAt(element10,1,1);
          morphs[6] = dom.createMorphAt(element10,2,2);
          return morphs;
        },
        statements: [
          ["inline","view",[["get","Ember.Select",["loc",[null,[253,39],[253,51]]]]],["content",["subexpr","@mut",[["get","model.patients",["loc",[null,[254,40],[254,54]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Alle","selection",["subexpr","@mut",[["get","reduction.patient",["loc",[null,[258,42],[258,59]]]]],[],[]]],["loc",[null,[253,32],[259,34]]]],
          ["inline","view",[["get","Ember.Select",["loc",[null,[268,39],[268,51]]]]],["content",["subexpr","@mut",[["get","customerReductionTypes",["loc",[null,[269,40],[269,62]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Alle","selection",["subexpr","@mut",[["get","reduction.type",["loc",[null,[273,42],[273,56]]]]],[],[]]],["loc",[null,[268,32],[274,34]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[281,38],[281,55]]]],"product"],[],["loc",[null,[281,34],[281,66]]]]],[],0,null,["loc",[null,[281,28],[293,35]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[294,38],[294,55]]]],"service"],[],["loc",[null,[294,34],[294,66]]]]],[],1,null,["loc",[null,[294,28],[306,35]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","reduction.reduction",["loc",[null,[312,42],[312,61]]]]],[],[]],"class","gui-input"],["loc",[null,[312,28],[312,81]]]],
          ["block","if",[["subexpr","eq",[["get","reduction",["loc",[null,[317,38],[317,47]]]],["get","model.reductions.lastObject",["loc",[null,[317,48],[317,75]]]]],[],["loc",[null,[317,34],[317,76]]]]],[],2,null,["loc",[null,[317,28],[321,27]]]],
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","reduction",["loc",[null,[322,43],[322,52]]]],["get","model.reductions.firstObject",["loc",[null,[322,53],[322,81]]]]],[],["loc",[null,[322,39],[322,82]]]]],[],["loc",[null,[322,34],[322,83]]]]],[],3,null,["loc",[null,[322,28],[326,35]]]]
        ],
        locals: ["reduction"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 420,
                "column": 20
              },
              "end": {
                "line": 420,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createAttrMorph(element6, 'style');
            morphs[2] = dom.createAttrMorph(element6, 'data-id');
            morphs[3] = dom.createElementMorph(element6);
            morphs[4] = dom.createMorphAt(element6,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[420,96],[420,108]]]],"selected",""],[],["loc",[null,[420,75],[420,124]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[420,153],[420,162]]]]]]],
            ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[420,177],[420,183]]]]]]],
            ["element","action",["selectSearchTag",["get","tag",["loc",[null,[420,214],[420,217]]]]],[],["loc",[null,[420,187],[420,219]]]],
            ["content","tag.name",["loc",[null,[420,220],[420,232]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 417,
              "column": 16
            },
            "end": {
              "line": 422,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter ml20 mb20");
          dom.setAttribute(el1,"id","tagFilters");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","tags",["loc",[null,[420,28],[420,32]]]]],[],0,null,["loc",[null,[420,20],[420,249]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 456,
                  "column": 34
                },
                "end": {
                  "line": 456,
                  "column": 219
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[456,77],[456,89]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[456,125],[456,144]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[456,153],[456,177]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[456,196],[456,217]]]]],[],[]]],["loc",[null,[456,58],[456,219]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 461,
                    "column": 49
                  },
                  "end": {
                    "line": 463,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[462,32],[462,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 463,
                    "column": 40
                  },
                  "end": {
                    "line": 463,
                    "column": 158
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[463,60],[463,158]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 460,
                  "column": 30
                },
                "end": {
                  "line": 464,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["content","patient.name",["loc",[null,[461,32],[461,48]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[461,55],[461,74]]]]],[],0,null,["loc",[null,[461,49],[463,39]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[463,46],[463,58]]]]],[],1,null,["loc",[null,[463,40],[463,165]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 465,
                    "column": 32
                  },
                  "end": {
                    "line": 465,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","patient.name",["loc",[null,[465,71],[465,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 465,
                    "column": 101
                  },
                  "end": {
                    "line": 467,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                              ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[466,32],[466,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 467,
                    "column": 38
                  },
                  "end": {
                    "line": 467,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[467,58],[467,156]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 464,
                  "column": 30
                },
                "end": {
                  "line": 468,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[465,59],[465,69]]]]],[],0,null,["loc",[null,[465,32],[465,99]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[465,107],[465,126]]]]],[],1,null,["loc",[null,[465,101],[467,37]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[467,44],[467,56]]]]],[],2,null,["loc",[null,[467,38],[467,163]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 475,
                  "column": 28
                },
                "end": {
                  "line": 475,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.digitalRace.name",["loc",[null,[475,55],[475,83]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 475,
                  "column": 83
                },
                "end": {
                  "line": 475,
                  "column": 107
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.race",["loc",[null,[475,91],[475,107]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 481,
                  "column": 28
                },
                "end": {
                  "line": 485,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("wiederherstellen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element4,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undeletePatient",["get","patient.id",["loc",[null,[482,124],[482,134]]]]],[],["loc",[null,[482,97],[482,136]]]],
              ["inline","button-checked",[],["content","Gelöschten Datensatz wiederherstellen","color","#fff","showStroke",false],["loc",[null,[483,32],[483,128]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 491,
                    "column": 30
                  },
                  "end": {
                    "line": 493,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createTextNode("Bearbeiten");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-edit",[],["size","32","color","#fff","showStroke",false],["loc",[null,[492,32],[492,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 485,
                  "column": 28
                },
                "end": {
                  "line": 497,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("QR-Code herunterladen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(fragment, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element2,1,1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
              morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[3] = dom.createElementMorph(element3);
              morphs[4] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm-id",[],["color","#fff","showStroke",false,"itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[487,101],[487,111]]]]],[],[]],"confirmed","deletePatient","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[487,32],[488,113]]]],
              ["inline","text-with-confirm-id",[],["itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[489,68],[489,78]]]]],[],[]],"confirmed","deletePatient","textToShow","Löschen","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[489,38],[489,175]]]],
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[491,57],[491,67]]]]],["classNames","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[491,30],[493,42]]]],
              ["element","action",["selectBarcode",["get","patient",["loc",[null,[494,58],[494,65]]]]],[],["loc",[null,[494,33],[494,67]]]],
              ["inline","button-barcode",[],["color","#fff","showStroke",false,"classNames","mr10"],["loc",[null,[495,32],[495,99]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 450,
                "column": 26
              },
              "end": {
                "line": 500,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Geschlecht");
            var el3 = dom.createTextNode("\n                              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Art");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-12/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","patient-view");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Spezies");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Rasse");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Farbe");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  table__cell--action l-4/24@desk buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 1]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [9]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [11]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element5, [13]),1,1);
            return morphs;
          },
          statements: [
            ["inline","icon-gender",[],["gender",["subexpr","@mut",[["get","patient.gender.id",["loc",[null,[453,57],[453,74]]]]],[],[]],"castrated",["subexpr","@mut",[["get","patient.castrated",["loc",[null,[453,85],[453,102]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.genderDetails",["loc",[null,[453,111],[453,132]]]]],[],[]],"size","32","color","#4A4A4A"],["loc",[null,[453,36],[453,160]]]],
            ["block","if",[["get","patient.category",["loc",[null,[456,40],[456,56]]]]],[],0,null,["loc",[null,[456,34],[456,226]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[460,36],[460,51]]]]],[],1,2,["loc",[null,[460,30],[468,37]]]],
            ["content","patient.category.name",["loc",[null,[472,28],[472,53]]]],
            ["block","if",[["get","patient.digitalRace",["loc",[null,[475,34],[475,53]]]]],[],3,4,["loc",[null,[475,28],[475,114]]]],
            ["content","patient.color",["loc",[null,[478,28],[478,45]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[481,34],[481,49]]]]],[],5,6,["loc",[null,[481,28],[497,35]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 449,
              "column": 24
            },
            "end": {
              "line": 501,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","not",[["get","patient.dead",["loc",[null,[450,41],[450,53]]]]],[],["loc",[null,[450,36],[450,54]]]],["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","quickFilter",["loc",[null,[450,68],[450,79]]]],"all"],[],["loc",[null,[450,64],[450,86]]]],["subexpr","eq",[["get","quickFilter",["loc",[null,[450,91],[450,102]]]],"deleted"],[],["loc",[null,[450,87],[450,113]]]]],[],["loc",[null,[450,60],[450,114]]]],["get","patient.dead",["loc",[null,[450,115],[450,127]]]]],[],["loc",[null,[450,55],[450,128]]]]],[],["loc",[null,[450,32],[450,129]]]]],[],0,null,["loc",[null,[450,26],[500,33]]]]
        ],
        locals: ["patient"],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 529,
              "column": 18
            },
            "end": {
              "line": 540,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","patient-consent");
          dom.setAttribute(el2,"class","field-label title required");
          var el3 = dom.createTextNode("Status aus vorherigem Praxismanagementsystem");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","checkbox-custom checkbox-primary field-checkbox");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient-consent");
          var el4 = dom.createTextNode("DSGVO akzeptiert");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["disabled",true,"type","checkbox","name","patient.consent","id","patient-consent","checked",true],["loc",[null,[534,24],[534,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 552,
              "column": 6
            },
            "end": {
              "line": 556,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[553,11],[553,38]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[554,10],[554,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 557,
              "column": 6
            },
            "end": {
              "line": 561,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--cancel");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[559,44],[559,52]]]]],[],[]],"confirmed","delete","size",52,"showStroke",false,"color","#fff","text","Wollen Sie diese/n Kunde/in wirklich löschen?"],["loc",[null,[559,10],[559,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 562,
              "column": 6
            },
            "end": {
              "line": 566,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[563,13],[563,37]]]],
          ["inline","button-save",[],["color","#ffffff","size","70","content","Speichern"],["loc",[null,[564,12],[564,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 589,
              "column": 0
            },
            "end": {
              "line": 591,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-forward-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardDocumentPanel",["loc",[null,[590,42],[590,62]]]]],[],[]],"confirm","sendDocumentMail"],["loc",[null,[590,2],[590,91]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 635,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Kundenstamm");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2,"class","mb10");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tabs__head");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","u-mb0 clearfix");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-master-data");
        var el7 = dom.createTextNode("Stammdaten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-reminder");
        var el7 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-documents");
        var el7 = dom.createTextNode("Dokumente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"href","#tab-patients");
        var el7 = dom.createTextNode("Tiere");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-correspondence");
        var el7 = dom.createTextNode("Korrespondenz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el6,"href","#tab-consents");
        var el7 = dom.createTextNode("Einverständniserklärungen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","customerEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-accounting");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt0 mb20");
        var el7 = dom.createTextNode("Zahlungsinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-payment-moral");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Zahlungsmoral");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-payment-moral");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-sued");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Gesperrt weil Nichtzahler");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-sued");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rechnungsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-addition");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Briefzusatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-firstname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Vorname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-street");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Straße/Hausnummer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-postalcode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-uid");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("UID");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-different-invoice");
        dom.setAttribute(el8,"class","field-label title required");
        var el9 = dom.createTextNode("Alternative\n                          Rechnungsadresse?");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-different-invoice");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-lastname");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Nachname");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-title");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Titel");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-title");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-town");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rechnungskonditionen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-invoice-conditions");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Konditionen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-reductionToPurchasePrice");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Rabatt zum Einkaufspreis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","checkbox-custom checkbox-primary field-checkbox");
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-reductionToPurchasePrice");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Bankverbindung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-iban");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("IBAN");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-bic");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("BIC");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Rabattinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20");
        var el7 = dom.createTextNode("Anfahrtsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveStreet");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Straße");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-drivePostalcode");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("PLZ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-distanceKm");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Entfernung in km");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-11/24 gutter");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveNotice");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-driveTown");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","user-distanceMinutes");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Entfernung in Minuten");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-warning");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mb20 mt0");
        var el7 = dom.createTextNode("Kundenspezifische Mahnzeiten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning1");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis 1. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning2");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis 2. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field number-input");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-nopayer");
        dom.setAttribute(el8,"class","field-label title");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        var el9 = dom.createTextNode("Tage bis Kunde als Nichtzahler markiert wird (nach dem Verschicken der 2. Mahnung).  (Allgemeine Einstellung: ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" Tage");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","inline-icon");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","is-highlighted");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Tipp:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Um das Mahnwesen für diesen Kunden zu deaktivieren muss in den entsprechenden Feldern ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("0");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" eingetragen werden.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-reminder");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24 gutter");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","patient-category");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","app-internal-person2");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    Standardeinstellung: ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-saldo");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper mt20");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-medias");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-documents");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-patients");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Verstorbene ausblenden");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        var el9 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid table--large");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Name");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Spezies");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Rasse");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Farbe");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-appointments");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-6/24@desk mb30");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","filterUser");
        dom.setAttribute(el7,"class","input-element");
        dom.setAttribute(el7,"style","width: 100%");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Kategorie");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-correspondence");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-consents");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element30 = dom.childAt(fragment, [0, 1, 3]);
        var element31 = dom.childAt(fragment, [4]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element31, [5]);
        var element34 = dom.childAt(element33, [1, 1]);
        var element35 = dom.childAt(element34, [1, 1]);
        var element36 = dom.childAt(element34, [5, 1]);
        var element37 = dom.childAt(element34, [7, 1]);
        var element38 = dom.childAt(element34, [9, 1]);
        var element39 = dom.childAt(element34, [13, 1]);
        var element40 = dom.childAt(element34, [15, 1]);
        var element41 = dom.childAt(element33, [3, 1]);
        var element42 = dom.childAt(element41, [1]);
        var element43 = dom.childAt(element41, [3]);
        var element44 = dom.childAt(element43, [9]);
        var element45 = dom.childAt(element44, [3]);
        var element46 = dom.childAt(element44, [5]);
        var element47 = dom.childAt(element44, [7]);
        var element48 = dom.childAt(element44, [9]);
        var element49 = dom.childAt(element43, [11]);
        var element50 = dom.childAt(element49, [3]);
        var element51 = dom.childAt(element49, [5]);
        var element52 = dom.childAt(element49, [7]);
        var element53 = dom.childAt(element43, [31]);
        var element54 = dom.childAt(element43, [33]);
        var element55 = dom.childAt(element41, [5]);
        var element56 = dom.childAt(element55, [3, 1]);
        var element57 = dom.childAt(element55, [5, 1]);
        var element58 = dom.childAt(element55, [7, 1]);
        var element59 = dom.childAt(element41, [7]);
        var element60 = dom.childAt(element59, [1]);
        var element61 = dom.childAt(element41, [11]);
        var element62 = dom.childAt(element41, [13]);
        var element63 = dom.childAt(element41, [15, 1]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element64, [5]);
        var element67 = dom.childAt(element64, [7]);
        var element68 = dom.childAt(element41, [17]);
        var element69 = dom.childAt(element41, [21]);
        var element70 = dom.childAt(element31, [7]);
        var morphs = new Array(102);
        morphs[0] = dom.createMorphAt(element30,1,1);
        morphs[1] = dom.createMorphAt(element30,2,2);
        morphs[2] = dom.createMorphAt(element30,3,3);
        morphs[3] = dom.createMorphAt(element30,4,4);
        morphs[4] = dom.createMorphAt(element30,5,5);
        morphs[5] = dom.createMorphAt(element30,6,6);
        morphs[6] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[7] = dom.createAttrMorph(element31, 'class');
        morphs[8] = dom.createMorphAt(element32,0,0);
        morphs[9] = dom.createMorphAt(element32,2,2);
        morphs[10] = dom.createMorphAt(element32,4,4);
        morphs[11] = dom.createMorphAt(element31,3,3);
        morphs[12] = dom.createAttrMorph(element35, 'class');
        morphs[13] = dom.createElementMorph(element35);
        morphs[14] = dom.createMorphAt(element34,3,3);
        morphs[15] = dom.createAttrMorph(element36, 'class');
        morphs[16] = dom.createElementMorph(element36);
        morphs[17] = dom.createAttrMorph(element37, 'class');
        morphs[18] = dom.createElementMorph(element37);
        morphs[19] = dom.createAttrMorph(element38, 'class');
        morphs[20] = dom.createElementMorph(element38);
        morphs[21] = dom.createMorphAt(element34,11,11);
        morphs[22] = dom.createElementMorph(element39);
        morphs[23] = dom.createElementMorph(element40);
        morphs[24] = dom.createElementMorph(element41);
        morphs[25] = dom.createAttrMorph(element42, 'class');
        morphs[26] = dom.createMorphAt(element42,1,1);
        morphs[27] = dom.createAttrMorph(element43, 'class');
        morphs[28] = dom.createMorphAt(dom.childAt(element43, [3, 1, 3]),1,1);
        morphs[29] = dom.createMorphAt(dom.childAt(element43, [5, 1, 3]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element44, [1]),3,3);
        morphs[31] = dom.createAttrMorph(element45, 'class');
        morphs[32] = dom.createMorphAt(element45,3,3);
        morphs[33] = dom.createAttrMorph(element46, 'class');
        morphs[34] = dom.createMorphAt(element46,3,3);
        morphs[35] = dom.createAttrMorph(element47, 'class');
        morphs[36] = dom.createMorphAt(element47,3,3);
        morphs[37] = dom.createAttrMorph(element48, 'class');
        morphs[38] = dom.createMorphAt(element48,3,3);
        morphs[39] = dom.createMorphAt(dom.childAt(element49, [1, 3]),1,1);
        morphs[40] = dom.createAttrMorph(element50, 'class');
        morphs[41] = dom.createMorphAt(element50,3,3);
        morphs[42] = dom.createAttrMorph(element51, 'class');
        morphs[43] = dom.createMorphAt(dom.childAt(element51, [3]),1,1);
        morphs[44] = dom.createAttrMorph(element52, 'class');
        morphs[45] = dom.createMorphAt(element52,3,3);
        morphs[46] = dom.createMorphAt(dom.childAt(element43, [15, 1]),3,3);
        morphs[47] = dom.createMorphAt(dom.childAt(element43, [17, 1, 3]),1,1);
        morphs[48] = dom.createMorphAt(dom.childAt(element43, [21, 1]),3,3);
        morphs[49] = dom.createMorphAt(dom.childAt(element43, [23, 1]),3,3);
        morphs[50] = dom.createMorphAt(element43,27,27);
        morphs[51] = dom.createMorphAt(dom.childAt(element53, [1]),3,3);
        morphs[52] = dom.createMorphAt(dom.childAt(element53, [3]),3,3);
        morphs[53] = dom.createMorphAt(dom.childAt(element53, [5]),3,3);
        morphs[54] = dom.createMorphAt(dom.childAt(element54, [1]),3,3);
        morphs[55] = dom.createMorphAt(dom.childAt(element54, [3]),3,3);
        morphs[56] = dom.createMorphAt(dom.childAt(element54, [5]),3,3);
        morphs[57] = dom.createAttrMorph(element55, 'class');
        morphs[58] = dom.createMorphAt(element56,3,3);
        morphs[59] = dom.createMorphAt(dom.childAt(element56, [5, 1]),0,0);
        morphs[60] = dom.createMorphAt(element57,3,3);
        morphs[61] = dom.createMorphAt(dom.childAt(element57, [5, 1]),0,0);
        morphs[62] = dom.createMorphAt(element58,3,3);
        morphs[63] = dom.createMorphAt(dom.childAt(element58, [5, 1]),0,0);
        morphs[64] = dom.createMorphAt(dom.childAt(element55, [9]),0,0);
        morphs[65] = dom.createAttrMorph(element59, 'class');
        morphs[66] = dom.createMorphAt(dom.childAt(element60, [1, 3]),1,1);
        morphs[67] = dom.createMorphAt(dom.childAt(element60, [3]),0,0);
        morphs[68] = dom.createMorphAt(dom.childAt(element41, [9, 1]),1,1);
        morphs[69] = dom.createAttrMorph(element61, 'class');
        morphs[70] = dom.createMorphAt(element61,1,1);
        morphs[71] = dom.createAttrMorph(element62, 'class');
        morphs[72] = dom.createMorphAt(element62,1,1);
        morphs[73] = dom.createMorphAt(element62,3,3);
        morphs[74] = dom.createAttrMorph(element65, 'class');
        morphs[75] = dom.createElementMorph(element65);
        morphs[76] = dom.createAttrMorph(element66, 'class');
        morphs[77] = dom.createElementMorph(element66);
        morphs[78] = dom.createAttrMorph(element67, 'class');
        morphs[79] = dom.createElementMorph(element67);
        morphs[80] = dom.createMorphAt(dom.childAt(element63, [3, 3]),1,1);
        morphs[81] = dom.createAttrMorph(element68, 'class');
        morphs[82] = dom.createMorphAt(dom.childAt(element68, [1, 1]),3,3);
        morphs[83] = dom.createMorphAt(element68,3,3);
        morphs[84] = dom.createMorphAt(dom.childAt(element41, [19]),1,1);
        morphs[85] = dom.createMorphAt(element69,1,1);
        morphs[86] = dom.createMorphAt(element69,3,3);
        morphs[87] = dom.createMorphAt(dom.childAt(element70, [1]),1,1);
        morphs[88] = dom.createMorphAt(element70,3,3);
        morphs[89] = dom.createMorphAt(element70,4,4);
        morphs[90] = dom.createMorphAt(element70,5,5);
        morphs[91] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[92] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[93] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[94] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[95] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[96] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[97] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[98] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[99] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[100] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[101] = dom.createMorphAt(fragment,27,27,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[5,16],[5,25]]]],"masterdata"],[],["loc",[null,[5,12],[5,39]]]]],[],0,null,["loc",[null,[5,6],[22,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[23,16],[23,25]]]],"documents"],[],["loc",[null,[23,12],[23,38]]]]],[],1,null,["loc",[null,[23,6],[33,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[34,16],[34,25]]]],"patients"],[],["loc",[null,[34,12],[34,37]]]]],[],2,null,["loc",[null,[34,6],[38,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[39,16],[39,25]]]],"appointments"],[],["loc",[null,[39,12],[39,41]]]]],[],3,null,["loc",[null,[39,6],[43,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[44,16],[44,25]]]],"saldo"],[],["loc",[null,[44,12],[44,34]]]]],[],4,null,["loc",[null,[44,6],[55,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[56,16],[56,25]]]],"consents"],[],["loc",[null,[56,12],[56,37]]]]],[],5,null,["loc",[null,[56,6],[60,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[65,10],[65,19]]]],"documents"],[],["loc",[null,[65,6],[65,32]]]]],[],6,null,["loc",[null,[65,0],[68,7]]]],
        ["attribute","class",["concat",["site-content status status-",["subexpr","get-payment-moral",[["get","model",["loc",[null,[70,63],[70,68]]]],true],[],["loc",[null,[70,43],[70,75]]]]," card transaction-filter"]]],
        ["content","model.firstname",["loc",[null,[71,21],[71,40]]]],
        ["content","model.lastname",["loc",[null,[71,41],[71,59]]]],
        ["block","if",[["get","model.etigaAppConnected",["loc",[null,[71,66],[71,89]]]]],[],7,8,["loc",[null,[71,60],[71,369]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[72,8],[72,21]]]]],[],9,null,["loc",[null,[72,2],[79,9]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[85,88],[85,97]]]],"masterdata"],[],["loc",[null,[85,84],[85,111]]]],"is-active",""],[],["loc",[null,[85,63],[85,128]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[85,154],[85,189]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[87,20],[87,33]]]]],[],10,null,["loc",[null,[87,14],[98,21]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[100,84],[100,93]]]],"reminder"],[],["loc",[null,[100,80],[100,105]]]],"is-active",""],[],["loc",[null,[100,59],[100,122]]]]]]],
        ["element","action",["selectTab","reminder"],[],["loc",[null,[100,145],[100,178]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[103,86],[103,95]]]],"documents"],[],["loc",[null,[103,82],[103,108]]]],"is-active",""],[],["loc",[null,[103,61],[103,125]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[103,149],[103,183]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[106,88],[106,97]]]],"patients"],[],["loc",[null,[106,84],[106,109]]]],"is-active",""],[],["loc",[null,[106,63],[106,126]]]]]]],
        ["element","action",["selectTab","patients"],[],["loc",[null,[106,149],[106,182]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[108,6],[108,29]]]]],[],11,null,["loc",[null,[108,0],[112,7]]]],
        ["element","action",["selectTab","correspondance"],[],["loc",[null,[114,91],[114,130]]]],
        ["element","action",["selectTab","consents"],[],["loc",[null,[117,85],[117,118]]]],
        ["element","action",["save",["get","referer",["loc",[null,[122,71],[122,78]]]],["get","refererInvoiceId",["loc",[null,[122,79],[122,95]]]]],["on","submit"],["loc",[null,[122,55],[122,109]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[123,66],[123,75]]]],"masterdata"],[],["loc",[null,[123,62],[123,89]]]],"is-active",""],[],["loc",[null,[123,41],[123,106]]]]]]],
        ["inline","customers-edit-form",[],["edit",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[124,56],[124,61]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[124,70],[124,77]]]]],[],[]],"customerSalutations",["subexpr","@mut",[["get","customerSalutations",["loc",[null,[124,98],[124,117]]]]],[],[]],"countries",["subexpr","@mut",[["get","countries",["loc",[null,[124,128],[124,137]]]]],[],[]],"titles",["subexpr","@mut",[["get","titles",["loc",[null,[124,145],[124,151]]]]],[],[]],"setInputDirty","setInputDirty","addEmail","addEmail","removeEmail","removeEmail","addPhone","addPhone","removePhone","removePhone","addFax","addFax","removeFax","removeFax"],["loc",[null,[124,18],[124,313]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[126,66],[126,75]]]],"accounting"],[],["loc",[null,[126,62],[126,89]]]],"is-active",""],[],["loc",[null,[126,41],[126,106]]]]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[132,37],[132,49]]]]],["content",["subexpr","@mut",[["get","paymentMorals",["loc",[null,[133,38],[133,51]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","name","user-payment-moral","selection",["subexpr","@mut",[["get","selectedPaymentMoral",["loc",[null,[138,40],[138,60]]]]],[],[]]],["loc",[null,[132,30],[139,32]]]],
        ["inline","input",[],["type","checkbox","name","user.sued","id","user-sued","checked",["subexpr","@mut",[["get","model.sued",["loc",[null,[148,94],[148,104]]]]],[],[]]],["loc",[null,[148,30],[148,106]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceAddition",["loc",[null,[160,38],[160,59]]]]],[],[]],"class","gui-input","name","user-invoice-addition"],["loc",[null,[160,24],[160,108]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[162,63],[162,85]]]],"","hidden"],[],["loc",[null,[162,42],[162,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceFirstname",["loc",[null,[164,40],[164,62]]]]],[],[]],"class","gui-input","name","user-invoice-firstname"],["loc",[null,[164,26],[164,112]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[166,63],[166,85]]]],"","hidden"],[],["loc",[null,[166,42],[166,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceStreet",["loc",[null,[168,40],[168,59]]]]],[],[]],"class","gui-input","name","user-invoice-street"],["loc",[null,[168,26],[168,106]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[170,63],[170,85]]]],"","hidden"],[],["loc",[null,[170,42],[170,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoicePostalcode",["loc",[null,[172,40],[172,63]]]]],[],[]],"class","gui-input","name","user-invoice-postalcode"],["loc",[null,[172,26],[172,114]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[174,63],[174,85]]]],"","hidden"],[],["loc",[null,[174,42],[174,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceUid",["loc",[null,[176,40],[176,56]]]]],[],[]],"class","gui-input","name","user-invoice-uid"],["loc",[null,[176,26],[176,100]]]],
        ["inline","input",[],["type","checkbox","name","user-different-invoice","id","user-different-invoice","checked",["subexpr","@mut",[["get","model.differentInvoice",["loc",[null,[184,116],[184,138]]]]],[],[]]],["loc",[null,[184,26],[184,140]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[188,63],[188,85]]]],"","hidden"],[],["loc",[null,[188,42],[188,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceLastname",["loc",[null,[190,40],[190,61]]]]],[],[]],"class","gui-input","name","user-invoice-lastname"],["loc",[null,[190,26],[190,110]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[192,61],[192,83]]]],"","hidden"],[],["loc",[null,[192,40],[192,98]]]]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[195,33],[195,45]]]]],["content",["subexpr","@mut",[["get","titles",["loc",[null,[196,41],[196,47]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","name","user-invoice-title","selection",["subexpr","@mut",[["get","model.invoiceTitle",["loc",[null,[201,43],[201,61]]]]],[],[]]],["loc",[null,[195,26],[202,28]]]],
        ["attribute","class",["concat",["field ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[206,63],[206,85]]]],"","hidden"],[],["loc",[null,[206,42],[206,100]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceTown",["loc",[null,[208,40],[208,57]]]]],[],[]],"class","gui-input","name","user-invoice-town"],["loc",[null,[208,26],[208,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.invoiceConditions",["loc",[null,[217,40],[217,63]]]]],[],[]],"class","gui-input","name","user-invoice-conditions"],["loc",[null,[217,26],[217,114]]]],
        ["inline","input",[],["type","checkbox","name","user.reductionToPurchasePrice","id","user-reductionToPurchasePrice","checked",["subexpr","@mut",[["get","model.reductionToPurchasePrice",["loc",[null,[225,130],[225,160]]]]],[],[]]],["loc",[null,[225,26],[225,162]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.iban",["loc",[null,[236,40],[236,50]]]]],[],[]],"class","gui-input","name","user-iban","id","user-iban"],["loc",[null,[236,26],[236,102]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.bic",["loc",[null,[242,40],[242,49]]]]],[],[]],"class","gui-input","name","user-bic","id","user-bic"],["loc",[null,[242,26],[242,99]]]],
        ["block","each",[["get","model.reductions",["loc",[null,[248,26],[248,42]]]]],[],12,null,["loc",[null,[248,18],[329,27]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveStreet",["loc",[null,[335,40],[335,57]]]]],[],[]],"class","gui-input","name","user-driveStreet","id","user-driveStreet"],["loc",[null,[335,26],[335,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.drivePostalcode",["loc",[null,[339,40],[339,61]]]]],[],[]],"class","gui-input","name","user-drive-postalcode","title","Bitte geben Sie eine gültige PLZ an"],["loc",[null,[339,26],[339,154]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.distanceKm",["loc",[null,[343,40],[343,56]]]]],[],[]],"class","gui-input","name","user-drive-distanceKm"],["loc",[null,[343,26],[343,105]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveNotice",["loc",[null,[349,40],[349,57]]]]],[],[]],"class","gui-input","name","user-driveNotice","id","user-driveNotice"],["loc",[null,[349,26],[349,123]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.driveTown",["loc",[null,[353,40],[353,55]]]]],[],[]],"class","gui-input","name","user-driveTown","id","user-driveTown"],["loc",[null,[353,26],[353,117]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.distanceMinutes",["loc",[null,[357,40],[357,61]]]]],[],[]],"class","gui-input","name","user-drive-distanceMinutes"],["loc",[null,[357,26],[357,115]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[361,66],[361,75]]]],"warning"],[],["loc",[null,[361,62],[361,86]]]],"is-active",""],[],["loc",[null,[361,41],[361,103]]]]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning1",["loc",[null,[367,70],[367,92]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-warning1"],["loc",[null,[367,22],[367,179]]]],
        ["content","model.defaultDaysTillWarning1",["loc",[null,[367,251],[367,284]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning2",["loc",[null,[373,70],[373,92]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-warning2"],["loc",[null,[373,22],[373,179]]]],
        ["content","model.defaultDaysTillWarning2",["loc",[null,[373,251],[373,284]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillNopayer",["loc",[null,[379,70],[379,91]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-days-till-nopayer"],["loc",[null,[379,22],[379,177]]]],
        ["content","model.defaultDaysTillNopayer",["loc",[null,[379,297],[379,329]]]],
        ["inline","icon-info",[],["color","#008EC6","size","24"],["loc",[null,[383,44],[383,83]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[386,66],[386,75]]]],"reminder"],[],["loc",[null,[386,62],[386,87]]]],"is-active",""],[],["loc",[null,[386,41],[386,104]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[392,34],[392,49]]]]],[],[]],"value",["subexpr","@mut",[["get","model.reminderSetting",["loc",[null,[393,32],[393,53]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","value","optionValuePath","id","allowClear","true","placeholder","Standardeinstellungen beibehalten","name","customer.reminderSetting"],["loc",[null,[391,24],[400,26]]]],
        ["content","model.defaultReminderSetting",["loc",[null,[404,44],[404,76]]]],
        ["inline","customer-saldo",[],["deposits",["subexpr","@mut",[["get","deposits",["loc",[null,[410,50],[410,58]]]]],[],[]],"balanceSum",["subexpr","@mut",[["get","balanceSum",["loc",[null,[410,70],[410,80]]]]],[],[]],"depositSum",["subexpr","@mut",[["get","depositSum",["loc",[null,[410,92],[410,102]]]]],[],[]],"banktransferEnabled",["subexpr","@mut",[["get","banktransferEnabled",["loc",[null,[410,123],[410,142]]]]],[],[]]],["loc",[null,[410,24],[410,144]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[413,64],[413,73]]]],"medias"],[],["loc",[null,[413,60],[413,83]]]],"is-active",""],[],["loc",[null,[413,39],[413,100]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[414,41],[414,47]]]]],[],[]],"deleteMedia","deleteMedia","editable",true,"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[414,18],[414,158]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[416,64],[416,73]]]],"documents"],[],["loc",[null,[416,60],[416,86]]]],"is-active",""],[],["loc",[null,[416,39],[416,103]]]]]]],
        ["block","if",[["get","showFilters",["loc",[null,[417,22],[417,33]]]]],[],13,null,["loc",[null,[417,16],[422,23]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[428,33],[428,47]]]]],[],[]]],["loc",[null,[423,16],[428,49]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[434,77],[434,88]]]],"all"],[],["loc",[null,[434,73],[434,95]]]],"selected",""],[],["loc",[null,[434,52],[434,112]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[434,114],[434,150]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[435,77],[435,88]]]],"hideDead"],[],["loc",[null,[435,73],[435,100]]]],"selected",""],[],["loc",[null,[435,52],[435,117]]]]]]],
        ["element","action",["toggleQuickFilter","hideDead"],[],["loc",[null,[435,119],[435,160]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[436,77],[436,88]]]],"deleted"],[],["loc",[null,[436,73],[436,99]]]],"selected",""],[],["loc",[null,[436,52],[436,116]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[436,118],[436,158]]]],
        ["block","each",[["get","patientList",["loc",[null,[449,32],[449,43]]]]],[],14,null,["loc",[null,[449,24],[501,33]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[506,64],[506,73]]]],"appointments"],[],["loc",[null,[506,60],[506,89]]]],"is-active",""],[],["loc",[null,[506,39],[506,106]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[511,30],[511,58]]]]],[],[]],"value",["subexpr","@mut",[["get","filterCategory",["loc",[null,[512,28],[512,42]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[510,20],[518,22]]]],
        ["inline","patient-appointments",[],["startTreatment","startTreatment","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[522,203],[522,215]]]]],[],[]]],["loc",[null,[522,16],[522,217]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[525,50],[525,64]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[525,73],[525,80]]]]],[],[]]],["loc",[null,[525,18],[525,82]]]],
        ["block","if",[["get","model.consent",["loc",[null,[529,24],[529,37]]]]],[],15,null,["loc",[null,[529,18],[540,25]]]],
        ["inline","customer-consents",[],["actionReceiver",["subexpr","@mut",[["get","customerConsentsComponent",["loc",[null,[542,53],[542,78]]]]],[],[]]],["loc",[null,[542,18],[542,80]]]],
        ["content","button-prev",["loc",[null,[549,10],[549,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[552,16],[552,25]]]],"medias"],[],["loc",[null,[552,12],[552,35]]]]],[],16,null,["loc",[null,[552,6],[556,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[557,16],[557,25]]]],"masterdata"],[],["loc",[null,[557,12],[557,39]]]]],[],17,null,["loc",[null,[557,6],[561,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[562,20],[562,29]]]],"masterdata"],[],["loc",[null,[562,16],[562,43]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[562,48],[562,57]]]],"warning"],[],["loc",[null,[562,44],[562,68]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[562,73],[562,82]]]],"accounting"],[],["loc",[null,[562,69],[562,96]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[562,101],[562,110]]]],"reminder"],[],["loc",[null,[562,97],[562,122]]]]],[],["loc",[null,[562,12],[562,123]]]]],[],18,null,["loc",[null,[562,6],[566,13]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[570,37],[570,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[570,0],[570,76]]]],
        ["inline","customer-payout-deposit-panel",[],["actionReceiver",["subexpr","@mut",[["get","depositPanel",["loc",[null,[573,17],[573,29]]]]],[],[]],"totalDeposit",["subexpr","@mut",[["get","depositSum",["loc",[null,[574,15],[574,25]]]]],[],[]],"payout","payout","actionReceiver",["subexpr","@mut",[["get","customerPayoutDepositPanel",["loc",[null,[576,17],[576,43]]]]],[],[]]],["loc",[null,[572,0],[576,45]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[580,73],[580,98]]]]],[],[]]],["loc",[null,[580,0],[580,100]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[581,40],[581,60]]]]],[],[]]],["loc",[null,[581,0],[581,62]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[582,40],[582,60]]]]],[],[]]],["loc",[null,[582,0],[582,62]]]],
        ["inline","consent-upload-panel",[],["uploadDone","consentUploadDone","actionReceiver",["subexpr","@mut",[["get","consentUploadPanel",["loc",[null,[583,69],[583,87]]]]],[],[]]],["loc",[null,[583,0],[583,89]]]],
        ["inline","add-appointment-panel",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[585,32],[585,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[585,51],[585,61]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[585,72],[585,81]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[585,88],[585,93]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[585,103],[585,111]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[585,118],[585,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[585,166],[585,185]]]]],[],[]]],["loc",[null,[585,0],[585,187]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[587,36],[587,46]]]]],[],[]],"customer",["subexpr","@mut",[["get","model",["loc",[null,[587,56],[587,61]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[587,78],[587,93]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[587,0],[587,123]]]],
        ["block","if",[["subexpr","not",[["get","newMode",["loc",[null,[589,11],[589,18]]]]],[],["loc",[null,[589,6],[589,19]]]]],[],19,null,["loc",[null,[589,0],[591,7]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[593,41],[593,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[593,74],[593,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[593,134],[593,152]]]]],[],[]]],["loc",[null,[593,0],[593,154]]]],
        ["inline","merge-customer-panel",[],["gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","mergeCustomerPanel",["loc",[null,[594,66],[594,84]]]]],[],[]]],["loc",[null,[594,0],[594,86]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19]
    };
  }()));

});