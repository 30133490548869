define('jason-frontend/templates/cash/logbook', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 16
                },
                "end": {
                  "line": 61,
                  "column": 81
                }
              },
              "moduleName": "jason-frontend/templates/cash/logbook.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","log.foreignText",["loc",[null,[61,62],[61,81]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 14
              },
              "end": {
                "line": 62,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/logbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","link-to",["transactions.show",["get","log.foreignId",["loc",[null,[61,47],[61,60]]]]],[],0,null,["loc",[null,[61,16],[61,93]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 16
                },
                "end": {
                  "line": 64,
                  "column": 88
                }
              },
              "moduleName": "jason-frontend/templates/cash/logbook.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","log.foreignText",["loc",[null,[64,69],[64,88]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 14
              },
              "end": {
                "line": 65,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/cash/logbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","link-to",["transactions.showclosing",["get","log.foreignId",["loc",[null,[64,54],[64,67]]]]],[],0,null,["loc",[null,[64,16],[64,100]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 108
              },
              "end": {
                "line": 77,
                "column": 206
              }
            },
            "moduleName": "jason-frontend/templates/cash/logbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-success");
            var el2 = dom.createTextNode("signiert");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 213
              },
              "end": {
                "line": 77,
                "column": 311
              }
            },
            "moduleName": "jason-frontend/templates/cash/logbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","label label-danger");
            var el2 = dom.createTextNode("ausgefallen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 8
            },
            "end": {
              "line": 80,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/logbook.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24@desk l-6/24@tablet ");
          dom.setAttribute(el2,"data-label","Kassa");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@tablet l-1/24@desk");
          dom.setAttribute(el2,"data-label","Beleg");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet l-2/24@desk");
          dom.setAttribute(el2,"data-label","Benutzer");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet l-2/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Typ");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" / ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Grand Total");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Summenzähler");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Mwst 20%");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Mwst 13%");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Mwst 10%");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet  l-2/24@desk");
          dom.setAttribute(el2,"data-label","Mwst 0%");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n            <div class=\"table__cell table__cell--center l-2/24\" data-label=\"Zahlarten\"></div>\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24@tablet l-2/24@desk");
          dom.setAttribute(el2,"data-label","Signiert");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [9]);
          var element3 = dom.childAt(element0, [23]);
          var morphs = new Array(15);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          morphs[1] = dom.createMorphAt(element1,1,1);
          morphs[2] = dom.createMorphAt(element1,2,2);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]),0,0);
          morphs[5] = dom.createMorphAt(element2,0,0);
          morphs[6] = dom.createMorphAt(element2,2,2);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [11]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element0, [13]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element0, [15]),0,0);
          morphs[10] = dom.createMorphAt(dom.childAt(element0, [17]),0,0);
          morphs[11] = dom.createMorphAt(dom.childAt(element0, [19]),0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element0, [21]),0,0);
          morphs[13] = dom.createMorphAt(element3,0,0);
          morphs[14] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["content","log.cashRegister.name",["loc",[null,[58,83],[58,108]]]],
          ["block","if",[["subexpr","eq",[["get","log.foreignType",["loc",[null,[60,24],[60,39]]]],"tran"],[],["loc",[null,[60,20],[60,47]]]]],[],0,null,["loc",[null,[60,14],[62,21]]]],
          ["block","if",[["subexpr","eq",[["get","log.foreignType",["loc",[null,[63,24],[63,39]]]],"clo"],[],["loc",[null,[63,20],[63,46]]]]],[],1,null,["loc",[null,[63,14],[65,21]]]],
          ["content","log.userFlat",["loc",[null,[67,108],[67,124]]]],
          ["inline","time-format",[["get","log.created",["loc",[null,[68,119],[68,130]]]],"DD. MM. YYYY HH:mm:ss"],[],["loc",[null,[68,105],[68,156]]]],
          ["content","log.action",["loc",[null,[69,104],[69,118]]]],
          ["content","log.subAction",["loc",[null,[69,121],[69,138]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","log.grandTotal",["loc",[null,[70,136],[70,150]]]]],[],[]]],["loc",[null,[70,112],[70,152]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","log.grandTotalRksv",["loc",[null,[71,137],[71,155]]]]],[],[]]],["loc",[null,[71,113],[71,157]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","log.tax20",["loc",[null,[72,133],[72,142]]]]],[],[]]],["loc",[null,[72,109],[72,144]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","log.tax13",["loc",[null,[73,133],[73,142]]]]],[],[]]],["loc",[null,[73,109],[73,144]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","log.tax10",["loc",[null,[74,133],[74,142]]]]],[],[]]],["loc",[null,[74,109],[74,144]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","log.tax0",["loc",[null,[75,132],[75,140]]]]],[],[]]],["loc",[null,[75,108],[75,142]]]],
          ["block","if",[["subexpr","eq",[["get","log.verificationStatus.id",["loc",[null,[77,118],[77,143]]]],"verified"],[],["loc",[null,[77,114],[77,155]]]]],[],2,null,["loc",[null,[77,108],[77,213]]]],
          ["block","if",[["subexpr","eq",[["get","log.verificationStatus.id",["loc",[null,[77,223],[77,248]]]],"failed"],[],["loc",[null,[77,219],[77,258]]]]],[],3,null,["loc",[null,[77,213],[77,318]]]]
        ],
        locals: ["log"],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 140,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/logbook.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Datenerfassungsprotokoll");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","logbookCsvExportForm");
        dom.setAttribute(el4,"class","u-mb0 mr5");
        dom.setAttribute(el4,"method","post");
        dom.setAttribute(el4,"action","/api/logbookEntries/export");
        dom.setAttribute(el4,"target","_blank");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","mandant_id");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","token");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","button-rework context");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Download als .csv");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","logbookExportForm");
        dom.setAttribute(el4,"class","u-mb0");
        dom.setAttribute(el4,"method","post");
        dom.setAttribute(el4,"action","/api/logbookEntries/exportRksv");
        dom.setAttribute(el4,"target","_blank");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","mandant_id");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","token");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","button-rework context");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Download für BMF");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card");
        var el2 = dom.createTextNode("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","u-mb0");
        var el4 = dom.createTextNode("Datenerfassungsprotokoll vom ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper box no-box@phone");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table table--small");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__head");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-3/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Kassa");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell l-1/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Beleg");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Benutzer");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Datum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Typ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Grand Total");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Summenzähler");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Mwst 20%");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Mwst 13%");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Mwst 10%");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Mwst 0%");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n       <div class=\"table__cell table__cell--center l-2/24\"><span class=\"table__title title\">Zahlarten</span></div>\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table__cell table__cell--center l-2/24");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","table__title title");
        var el7 = dom.createTextNode("Signiert");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__body");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createTextNode("Achtung:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" es werden nur die ersten 100. Einträge angezeigt - Laden sie das vollständige Protokoll über die Downloadfunktion in der Hauptnavigation herunter.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function () {\n        $('#pickDateDep').daterangepicker({\n            \"autoApply\": true,\n            \"ranges\": {\n                \"Heute\": [moment(), moment()],\n                \"Gestern\": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],\n                \"Letzten 7 Tage\": [moment().subtract(6, 'days'), moment()],\n                \"Dieses Monat\": [moment().startOf('month'), moment().endOf('month')],\n                \"Letztes Monat\": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],\n            },\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"separator\": \" - \",\n                \"applyLabel\": \"Anwenden\",\n                \"cancelLabel\": \"Abbrechen\",\n                \"fromLabel\": \"Von\",\n                \"toLabel\": \"Bis\",\n                \"customRangeLabel\": \"Benutzerdefiniert\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n            \"linkedCalendars\": false,\n          timePicker: true,\n          timePicker24Hour: true\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 1, 3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [9]);
        var element8 = dom.childAt(element4, [3]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [9]);
        var element11 = dom.childAt(fragment, [2]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element6, 'value');
        morphs[1] = dom.createMorphAt(element5,5,5);
        morphs[2] = dom.createMorphAt(element5,7,7);
        morphs[3] = dom.createElementMorph(element7);
        morphs[4] = dom.createMorphAt(element7,1,1);
        morphs[5] = dom.createAttrMorph(element9, 'value');
        morphs[6] = dom.createMorphAt(element8,5,5);
        morphs[7] = dom.createMorphAt(element8,7,7);
        morphs[8] = dom.createElementMorph(element10);
        morphs[9] = dom.createMorphAt(element10,1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element11, [1, 1]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element11, [4, 1, 3]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[6,56],[6,65]]]]]]],
        ["inline","input",[],["name","to","value",["subexpr","@mut",[["get","to",["loc",[null,[8,32],[8,34]]]]],[],[]],"type","hidden"],["loc",[null,[8,8],[8,50]]]],
        ["inline","input",[],["name","from","value",["subexpr","@mut",[["get","from",["loc",[null,[9,34],[9,38]]]]],[],[]],"type","hidden"],["loc",[null,[9,8],[9,54]]]],
        ["element","action",["checkForm","logbookCsvExportForm"],[],["loc",[null,[10,60],[10,105]]]],
        ["inline","button-download2",[],["content","Herunterladen als CSV","color","#FFF","size","16"],["loc",[null,[11,10],[11,85]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[17,56],[17,65]]]]]]],
        ["inline","input",[],["name","to","value",["subexpr","@mut",[["get","to",["loc",[null,[19,32],[19,34]]]]],[],[]],"type","hidden"],["loc",[null,[19,8],[19,50]]]],
        ["inline","input",[],["name","from","value",["subexpr","@mut",[["get","from",["loc",[null,[20,34],[20,38]]]]],[],[]],"type","hidden"],["loc",[null,[20,8],[20,54]]]],
        ["element","action",["checkForm","logbookExportForm"],[],["loc",[null,[22,60],[22,102]]]],
        ["inline","button-download2",[],["content","Herunterladen als CSV","color","#FFF","size","16"],["loc",[null,[23,10],[23,85]]]],
        ["inline","input",[],["id","pickDateDep","value",["subexpr","@mut",[["get","pickDate",["loc",[null,[33,82],[33,90]]]]],[],[]],"class","js-date-picker-dep input date-picker__input","name","pickDate"],["loc",[null,[33,51],[33,160]]]],
        ["block","each",[["get","model",["loc",[null,[56,16],[56,21]]]]],[],0,null,["loc",[null,[56,8],[80,17]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});